/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:userFeedProfileCtrl
 * @description
 * # userFeedProfileCtrl
 * Controller of the heliApp
 */

angular.module('heliApp').controller(
  'userFeedProfileCtrl',
  [
    '$scope',
    '$state',
    '$rootScope',
    '$stateParams',
    'userSocialProfileService',
    'sharedService',
    '$cookies',
    '$timeout',
    'CONST_HELPER',
    'NgMap',
    'instagramServices',
    '$location',
    'rootScopeService',
    function ($scope, $state, $rootScope, $stateParams, userSocialProfileService, sharedService, $cookies, $timeout, CONST_HELPER, NgMap, instagramServices, $location, rootScopeService) {
      $rootScope.showRightPanel = true;
      $scope.selectedLocation = {};
      $scope.selectedInfoLocation = {};
      $scope.map = {};
      $rootScope.activeBasicInformation = false;
      $rootScope.pageLoading = true;
      $scope.marker = { 'lat': CONST_HELPER.default_location.latitude, 'lng': CONST_HELPER.default_location.longitude };
      $scope.hostName = $location.host();
      $scope.isSubDomain = $rootScope.isSubDomain;
      var getTitleFromUrlSubDomain = function () { var hostNames = $scope.hostName.split('.'); return hostNames[0]; }
      $scope.username = $rootScope.isSubDomain ? getTitleFromUrlSubDomain() : $state.params.myusername;
      var title = $stateParams.myusername === 'ski' ? 'Ski & Ride' : ($rootScope.isSubDomain ? getTitleFromUrlSubDomain() : titleCase($stateParams.myusername));
      const sportsArray = CONST_HELPER.FEATURED_ACTIVITY.length ? CONST_HELPER.FEATURED_ACTIVITY : CONST_HELPER.FEATURED_ACTIVITY_LOCAL;
      sportsArray.forEach((activity) => {
        activity.slug.toLowerCase() === $stateParams.myusername && $state.go('activity-categories', {activityCategory:$stateParams.myusername})
      });
      rootScopeService.updateMetaTitle(title); // set dynamic title and description of the page.
      $scope.packageList = [];
      $scope.instagramPost = [];
      $scope.cannotLoadRadar = true;
      $rootScope.activityFilterPage = false;
      rootScopeService.setValue("activityFilterPage",  $rootScope.activityFilterPage);
      var listShown = CONST_HELPER.LIST.SHOWN;
      var listSize = CONST_HELPER.LIST.SIZE;

      $scope.openInfobox = function (evt, storeId, element) {
        // vm.store = vm.stores[storeId];
        $scope.selectedInfoLocation = element;
        $scope.map.showInfoWindow('myInfoWindow', this);
      };

      $scope.openInfoboxOperator = function (evt, storeId, element) {
        NgMap.getMap({id:'operator_address_profile_map'}).then(function (map) {
          map.showInfoWindow('myInfoBox', 'location-marke-operator');
        });
      };

      $scope.showStore = function (evt, storeId, element) {
        // vm.store = vm.stores[storeId];
        $scope.selectedLocation = element;
        $scope.selectedInfoLocation = element;
        $scope.map.showInfoWindow('myInfoWindow', this);
        var marker = this;
        marker.setAnimation(google.maps.Animation.BOUNCE);
        setTimeout(function () {
          marker.setAnimation(null);
        }, CONST_HELPER.SEARCH_TIMEOUT);
      };

      $scope.initMapAndLocations = function () {

        $scope.showLoader();
        userSocialProfileService.getUserLocationAdventures($scope.userProfile.id).then(function (response) {
          $scope.hideLoader();
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK || response.data.code === CONST_HELPER.API_RESPONSE.NO_CONTENT) {
            $scope.allLocation = response.data.data;
            $scope.selectedLocation = $scope.allLocation && $scope.allLocation[CONST_HELPER.ZERO] ? $scope.allLocation[CONST_HELPER.ZERO] : {};
            $scope.selectedInfoLocation = $scope.allLocation && $scope.allLocation[CONST_HELPER.ZERO] ? $scope.allLocation[CONST_HELPER.ZERO] : {};

            $scope.allLocation.map(function (l) {
              // l.op_location_medias = l.op_location_medias.concat(l.op_location_videos);
              l.lat = l.latitude;
              l.lng = l.longitude;
              return l;
            });

            if ($scope.allLocation.length) {
              NgMap.getMap({id:'package_creation_map'}).then(function (map) {
                $scope.map = map;
                setTimeout(function () {
                  // location-marker-0 id generated
                  // Added twice to readjust marker according to our css
                  $scope.map.showInfoWindow('myInfoWindow', $scope.map.markers['location-marker-0']);
                  $scope.map.showInfoWindow('myInfoWindow', $scope.map.markers['location-marker-0']);
                  $scope.map.markers['location-marker-0'].setAnimation(google.maps.Animation.BOUNCE);
                  setTimeout(function () {
                    $scope.map.markers['location-marker-0'].setAnimation(null);
                  }, CONST_HELPER.SEARCH_TIMEOUT);
                }, CONST_HELPER.SEARCH_TIMEOUT);
              });
            }
          }
        });
      };

      // $r`o`otScope.isUserLoggedIn() // check if user token is distroyed...
      $scope.getUserProfile = function () { //  get user feed profile function
        $scope.showLoader();
        var requestObject = { 'username': $scope.username };

        let referral_id = null;
        let params = $location.search();
        if(params && params.referral_id) {
          referral_id = params.referral_id;
        }

        userSocialProfileService.getUserSocialProfile(requestObject, $scope.user.token).then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $rootScope.userProfile = response.data.data;
            if ($scope.userProfile.role_id === CONST_HELPER.USER_ROLE.OPERATOR) {
              $scope.getOperatorPackageList(CONST_HELPER.ONE); // call package list function
            } else {
              $scope.hideLoader();
            }
            if ($scope.user.token && $cookies.get('followUser') === '1' && $scope.userProfile.is_follow === false && $cookies.get('followUserId') !== $scope.userProfile.id) {
              $scope.followUser('follow');
            }
            // ngMeta title and description

            if ($rootScope.userProfile.role_id === CONST_HELPER.USER_ROLE.OPERATOR) { // if operator ...
              rootScopeService.updateMetaDescription($rootScope.userProfile.tag_description && $rootScope.userProfile.tagline ? $rootScope.userProfile.tagline + '. ' + $rootScope.userProfile.tag_description : $rootScope.userProfile.business_name + ', view their operator profile on Heli.');
              rootScopeService.updateMetaTitle($rootScope.userProfile.business_name); // set dynamic title and description of the page.
              rootScopeService.updateMetaImage($rootScope.userProfile.cover_image);
            } else { // if consumer
              var first_name = $rootScope.userProfile.first_name ? $rootScope.userProfile.first_name : 'Guest';
              var last_name = $rootScope.userProfile.last_name ? $rootScope.userProfile.last_name : 'User';

              if ($scope.userProfile.role_id === CONST_HELPER.USER_ROLE.INFLUENCER) {
                rootScopeService.updateMetaTitle(first_name + ' ' + last_name + ' Ambassador Profile'); // set dynamic title and description of the page.
                rootScopeService.updateMetaDescription($rootScope.userProfile.tagline && $rootScope.userProfile.tag_description ? $rootScope.userProfile.tagline + '. ' + $rootScope.userProfile.tag_description : 'Heli Ambassador Profile');
                rootScopeService.updateMetaImage($rootScope.userProfile.cover_pic);
              } else {
                rootScopeService.updateMetaTitle(first_name + ' ' + last_name + ' Heli Profile.'); // set dynamic title and description of the page.
                rootScopeService.updateMetaDescription($rootScope.userProfile.tagline && $rootScope.userProfile.tag_description ? $rootScope.userProfile.tagline + '. ' + $rootScope.userProfile.tag_description : "View this user's full profile on heli. Join us. Follow your friends. Find new adventures."); // set dynamic title and description of the page.
                rootScopeService.updateMetaImage($rootScope.userProfile.profile_pic ? $rootScope.userProfile.profile_pic : 'https://heli.life/images/dummy-profile.svg');
              }
            }
            $scope.cannotLoadRadar = false;
            $scope.hidePopup('#search-modal');
            // instagram feature here

            // fetch instagram 10 most recent feeds...
            if ($rootScope.userProfile.instagram_access_token) {
              instagramServices.fetchPopular($rootScope.userProfile.instagram_access_token, function (data) {
                $scope.instagramPost = data;
              });
            }
            // instagram feature end here
            $rootScope.pageLoading = false;
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.UNPROCESSABLE) {
            $scope.hideLoader();
            if (checkActivities()) {
              $rootScope.activityFilterPage = true;
              rootScopeService.setValue("activityFilterPage",  $rootScope.activityFilterPage);
              $rootScope.showFooter = true;
              rootScopeService.setValue("showFooter", $rootScope.showFooter);
              rootScopeService.updateMetaTitle(title + ' - Heli'); // set dynamic title and description of the page.
              rootScopeService.updateMetaDescription(`Find your next ${typeAdventureMetaDescription(title)} on Heli. Discover New Trips. Online Booking. No Markups Ever.`);
              rootScopeService.updateMetaImage(`https://s3.amazonaws.com/heli-staging/category_images/${title === 'Ski & Ride' ? 'Ski%2BRide' : title}.jpg`);
            } else {
              $state.go('link-expire');
            }
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.BAD_REQUEST) {
            $scope.hideLoader();
            $state.go('home');
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
            $scope.logout();// unauthorised user
            $state.go('signin');
            return false;
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.NON_AUTHORITATIVE) { // logout if admin de-activate account..
            $scope.logout();// unauthorised user
            $scope.hideLoader();
            $state.go('signin');
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.MOVED_PERMANENTLY) {
            $scope.cannotLoadRadar = true;
            $scope.hideLoader();
            var params = {
              operatorname: response.data.data.params.business_name ? $scope.getSeoFriendlyURL(response.data.data.params.business_name) : undefined,
              packagename: response.data.data.params.package_name ? $scope.getSeoFriendlyURL(response.data.data.params.package_name) : undefined,
              package_id: response.data.data.params.package_id,
              referral_id: referral_id || response.data.data.params.referral_id,
              slot_id: $stateParams.slot_id
            };
            $state.go(response.data.data.state, params);
          }
        }, function (error) {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      if (!$rootScope.isSubDomain || $rootScope.currentState.name != 'new-route-white-label-package') {
        $scope.getUserProfile();
      } else {
        $scope.callApiForOperatorProfile();
      }

      // function of forming the description the meta for the type of adventure
      function typeAdventureMetaDescription (str) {
        switch (str) {
          case 'Ski & Ride':
            return 'ski and ride adventure'
            break;
          case 'Fish':
            return 'fishing trip'
            break;
          case 'Safari':
            return 'safari'
            break;
          default:
            return `${str.toLowerCase()} adventure`
        }
      }

      // function to return string in title case...
      function titleCase (str) {
        str = str.toLowerCase().split(' ');
        for (var i = CONST_HELPER.ZERO; i < str.length; i++) {
          str[i] = str[i].charAt(CONST_HELPER.ZERO).toUpperCase() + str[i].slice(CONST_HELPER.ONE);
        }
        return str.join(' ');
      }

      // function to check if username is activity...
      function checkActivities () {
        if (!$rootScope.featuredActivity.length) {
          $rootScope.featuredActivity = CONST_HELPER.FEATURED_ACTIVITY;
        }

        var selectedActivity = $rootScope.featuredActivity.find(function (activity) {
          return activity.slug.toLowerCase().replace(/ +/g, '') === $stateParams.myusername;
        });

        if (selectedActivity && selectedActivity.id) { return true; }
        return false;
      }

      $scope.openPackageDetails = function (params) {
        $state.go('adventure-detail', { 'operatorname': $scope.username, 'packagename': params.name, 'package_id': params.id, 'package_status': params.status == 1 ? 'active' : 'expired' });
      };

      // view adventure with referral
      $scope.ViewAdventureWithreferral = function (pkgObj) {
        if ($scope.userProfile.role_id === CONST_HELPER.USER_ROLE.INFLUENCER) { // if influencer profile
          var username;
          if (pkgObj.package.short_url.indexOf('.life/') !== CONST_HELPER.NOT_FOUND) {
            username = pkgObj.package.short_url.split('.life/');
          } else {
            username = pkgObj.package.short_url.split('.com/');
          }
          $state.go('myprofile', { 'myusername': username[CONST_HELPER.ONE] });
        } else {
          $state.go('adventure-detail', { 'operatorname': pkgObj.operator_name, 'packagename': pkgObj.name, 'package_id': pkgObj.package_id, 'package_status': pkgObj.status == 1 ? 'active' : 'expired' });
        }
      };

      $scope.getOperatorPackageList = function (page) { //  get active package list function
        $scope.showLoader();
        $scope.loading = true;
        $scope.page = page;
        var requestObject = {
          'user_id': $scope.userProfile.id,
          'page': $scope.page,
          'limit': CONST_HELPER.LIST.SIZE,
          'back': CONST_HELPER.ZERO,
          'privateIncluded': CONST_HELPER.ZERO
        };

        $scope.userProfile.latitude = $scope.userProfile.latitude || 0;
        $scope.userProfile.longitude = $scope.userProfile.longitude || 0;

        userSocialProfileService.operatorPackageList(requestObject).then(function (response) { // call active package list api
          if (response) {
            $scope.loading = false;
            $scope.packageList = $scope.packageList.concat(response.data.response.docs);
            $scope.hideLoader();
            $scope.checkExpiredSlots($scope.packageList);
            // more package Shown on adventure tab
            $scope.hasMorePackageToShow = function () {
              return listShown < (response.data.response.numFound / listSize);
            };
          }
        }, function (error) {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.packageLimit = function () { // set package list limit here
        return listSize * listShown;
      };

      $scope.loadMorePackage = function () { // load more packages
        $scope.getOperatorPackageList($scope.page + CONST_HELPER.ONE);
        listShown = listShown + CONST_HELPER.ONE;
      };

      $scope.viewPackageSlot = function (event, packageDetail) { // view package sloat
        $scope.showPopup('#viewSlot');
        $scope.viewPackageSlotDetail = packageDetail;
        event.stopPropagation();
      };

      $scope.goToUserProfile = function () {
        $rootScope.activeBasicInformation = true;
        $state.go('consumer-profile');
      };

      $scope.followUser = function (action) {
        $rootScope.isUserLoggedIn();
        // if($scope.userProfile.is_owner==='1')
        // return false;
        if (typeof ($scope.user.token) === 'undefined') { // cehck for user token
          $cookies.put('followUser', CONST_HELPER.ONE);
          $cookies.put('followUserId', $scope.userProfile.id);
          $state.go('signin');// redirect to home
          return false;
        }
        $scope.showLoader();
        var data = {
          'follower_id': $scope.userProfile.id,
          'token': $scope.user.token
        };
        if (action === 'follow') {
          sharedService.followUser(data).then(function (res) {
            if (res.data && res.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.userProfile.followers_count = $scope.userProfile.followers_count + CONST_HELPER.ONE;
              $scope.userProfile.is_follow = true;
              $rootScope.$broadcast('addToFollowers');
              // pushMeToFollowersList($scope.userProfile.followers);
            }
            if (res.data && res.data.code === CONST_HELPER.API_RESPONSE.CREATED) {
              $scope.userProfile.is_follow = CONST_HELPER.follow_user.REQUESTED_FOR_FOLLOW; // when user's profile is private
            }
            if ($cookies.get('followUser')) {
              $cookies.remove('followUser');// remove order details from cookie
            }
            $scope.hideLoader();
          }, function (err) {
            $scope.hideLoader(); $scope.showPopup('#serverErrModal');
          });
        } else {
          sharedService.unFollowUser(data).then(function (res) {
            if (res.data && res.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.userProfile.is_follow = false;
              $scope.userProfile.followers_count = $scope.userProfile.followers_count - CONST_HELPER.ONE;
              $rootScope.$broadcast('removeFromFollowers');
            }
            $scope.hideLoader();
          }, function (err) {
            $scope.hideLoader(); $scope.showPopup('#serverErrModal');
          });
        }
      };
      // function to open lodging images...
      $scope.lodgingImages = [];
      $scope.openLodgingGallery = function (lodging) {
        if (lodging) {
          $scope.lodgingImages = lodging.op_lodging_medias;
          $scope.lodgeTitle = lodging.name;
        }
        $timeout(function () {
          $scope.showPopup('#operatorLodgeImgGallary');
        });
      };

      // open image gallery
      $scope.openGallery = function (images) {
        if (typeof (images) === 'undefined') { return false; }
        $scope.operatorImages = [];
        images.map(function (img) {
          var obj = { url: img.image_url, media_type: CONST_HELPER.ZERO };
          $scope.operatorImages.push(obj);
        });
        if ($scope.userProfile.cover_image) {
          var coverImage = { url: $scope.userProfile.cover_image, media_type: CONST_HELPER.ZERO };
          $scope.operatorImages.push(coverImage);
        }
        $timeout(function () {
          $scope.showPopup('#operatorImgGallary');
        });
      };
      // open instagram images/video gallery...
      $scope.openInstaGallery = function (images, index) {
        if (typeof (images) === 'undefined') { return false; }
        $rootScope.selectedImgIndex = index;
        $scope.operatorImages = [];
        images.map(function (img) {
          var obj;
          if (img.type === 'image' || img.type === 'carousel') {
            obj = { url: img.images.standard_resolution.url, media_type: CONST_HELPER.ZERO };
          } else {
            // media_type = 5 for normal video url
            obj = { url: img.videos.low_bandwidth.url, media_type: CONST_HELPER.MEDIA.NORMAL_VIDEO_URL };
          }
          $scope.operatorImages.push(obj);
        });
        $timeout(function () {
          $scope.showPopup('#operatorImgGallary');
        });
      };

      $scope.activeTab = 'adventure';
      $scope.changeTab = function (tab) { // for feed scroll side directive
        $rootScope.tab = tab;
        $scope.activeTab = tab;
        if (tab === 'about') {
          $rootScope.feedLength = CONST_HELPER.ONE;// set dummy feed length for directive content
        }
        if (tab === 'locations') {
          $scope.initMapAndLocations(); // set dummy feed length for directive content
        }
      };
      // show follow - following popup ...
      $scope.noRecordFound = false;
      $scope.isLoadMoreButton = false;
      var listLimit = CONST_HELPER.LIST.LIMIT; var preKey; var fPage = CONST_HELPER.ONE; var fBack = CONST_HELPER.ZERO;
      $scope.showFPopup = function (key, page, isMore) {
        // $scope.isLoadMoreButton = !isMore ? false : true;
        $scope.showPopup('#followers-followings');
        var requestObject = {
          'page': page,
          'limit': listLimit,
          'back': fBack,
          'user_id': $scope.userProfile.id
        };
        var callApi = !!((preKey !== key || isMore));
        $scope.noRecordFound = callApi ? false : $scope.noRecordFound;
        if (key === 'Followers') {
          $scope.title = 'Followers';
          preKey = 'Followers';
          if (callApi) {
            fPage = CONST_HELPER.ONE;
            $scope.showLoader();
            $scope.fList = (isMore === 'loadMore') ? $scope.fList : [];
            userSocialProfileService.getFollowerListing(requestObject, $scope.user.token).then(function (response) { // call opearot listing api
              if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                $scope.fList = $scope.fList.concat(response.data.follower_details);
                $scope.isLoadMoreButton = ((response.data.numFound - $scope.fList.length) > CONST_HELPER.ZERO);
                if ($scope.fList.length === CONST_HELPER.ZERO) { // Show record not found messgae
                  $scope.noRecordFound = true;
                } else {
                  $scope.noRecordFound = false;
                }
              }
              $scope.hideLoader();
            });
          }
        } else {
          $scope.title = 'Following';
          preKey = 'Following';
          if (callApi) {
            fPage = CONST_HELPER.ONE;
            $scope.showLoader();
            $scope.fList = (isMore === 'loadMore') ? $scope.fList : [];
            userSocialProfileService.getFollowingListing(requestObject, $scope.user.token).then(function (response) { // call opearot listing api
              if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                $scope.fList = $scope.fList.concat(response.data.following_details);
                $scope.isLoadMoreButton = ((response.data.numFound - $scope.fList.length) > CONST_HELPER.ZERO);
                if ($scope.fList.length === CONST_HELPER.ZERO) { // Show record not found messgae
                  $scope.noRecordFound = true;
                } else {
                  $scope.noRecordFound = false;
                }
              }
              $scope.hideLoader();
            });
          }
        }
      };

      // LOAD MORE ...
      $scope.textLimit = CONST_HELPER.TEXTLIMIT;
      $scope.loadMore = function (key) {
        fBack = CONST_HELPER.ZERO;
        fPage = Number(fPage) + CONST_HELPER.ONE;
        $scope.showFPopup(key, fPage, 'loadMore');
      };
      // show more and less ...
      $scope.showMore = function (txtLength) {
        if (txtLength) {
          $scope.textLimit = txtLength;
        } else {
          $scope.textLimit = $scope.userProfile.safety_certification.length;
        }
      };

      // PACKAGE DETAIL METHODS

      $scope.callApiForOperatorProfile = function () {
        if ($rootScope.currentState.name == 'new-route-white-label-package') {
          $scope.getOperatorPackageListSubDomain(CONST_HELPER.ONE);
        }
      };

      $scope.getOperatorPackageListSubDomain = function (page) { //  get active package list function
        $scope.showLoader();
        $scope.loading = true;
        $scope.page = page;
        var hostName = $location.host();
        var requestObject = {
          'user_name': hostName.slice(0, hostName.indexOf('.')),
          'domain_url': $location.absUrl(),
          'page': $scope.page,
          'limit': CONST_HELPER.LIST.SIZE,
          'back': CONST_HELPER.ZERO
        };

        userSocialProfileService.operatorPackageListSubDomain(requestObject).then(function (response) {
          if (response) {
            $scope.loading = false;
            $scope.pageDetails = response.data.response.pageDetails[0];
            $rootScope.pageDetails = response.data.response.pageDetails[0];
            $scope.pageDetails.header_tracking ? $('head').append($scope.pageDetails.header_tracking) : null;
            $scope.pageDetails.footer_tracking ? $('body').append($scope.pageDetails.footer_tracking) : null;
            rootScopeService.updateMetaTitle($scope.pageDetails.page_title); // set dynamic title and description of the page.
            rootScopeService.updateMetaDescription($scope.pageDetails.page_desc);
            if (($scope.pageDetails.head_font || $scope.pageDetails.body_font) && WebFont) {
              WebFont.load({ google: { families: [$scope.pageDetails.head_font, $scope.pageDetails.body_font] } });
            }
            $scope.hideLoader();
          }
        }, function (error) {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };
    }
  ]
);
