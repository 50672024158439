import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { UIRouter } from '@uirouter/core';
import * as angular from 'angular';
import { SearchService } from '../../../../../app/scripts/services/searchService';
import { RootScopeService } from '../root-scope/root-scope.service';
import { LoadAdventuresService } from '../load-adventures/load-adventures.service';

@Injectable({
  providedIn: 'root'
})
export class SearchServiceService {

  mergeArray = [];
  onInputFocus: boolean = false;
  searchValue: string;

  constructor(
    @Inject(SearchService) private searchService: SearchService,
    @Inject(RootScopeService) private rootScopeService: RootScopeService,
    @Inject(LoadAdventuresService) private loadAdventuresService: LoadAdventuresService,
    @Inject(UIRouter) private router: UIRouter,
    @Inject("$rootScope") public rootScope: any,
  ) {
    this.router.locationService.onChange((evt: any) => {
      this.rootScopeService.searchValue = this.router.locationService.search().keyword;
      if (this.onInputFocus) {
        this.offFocus();
      }
    })
  }

  onSearch() {
    this.onInputFocus = true;
    this.getAvailableAdventures(this.rootScopeService.searchValue || '');
  }

  onSearchChange(value) {
    this.rootScopeService.searchValue = value;
    this.getAvailableAdventures(this.rootScopeService.searchValue);
  }

  offFocus() {
    this.onInputFocus = false;
  }

  getSeoFriendlyURL(str) {
    return str.replace(/\W+(?!$)/g, '-').toLowerCase();
  }

  getAvailableAdventures(keys?) {
    if (keys.length) {
      this.rootScopeService.searchValue = keys;
    }
    let categoriesData = []; let sportsData = []; let operatorsData = []; let packagesData = [];
    this.searchService.getAvailableAdventures(keys).then(
      (response: any) => {
        if (response && response.data && response.data.result) {
          this.loadAdventuresService.selectedActivity = null;
          sportsData = response.data.result.sports;
          if (sportsData) {
            sportsData = sportsData.map((obj) => {
              obj['category'] = 'sports';
              obj['name'] = obj.sport_name;
              return obj;
            }).slice(0, 3);
          }
          operatorsData = response.data.result.operators;
          if (operatorsData) {
            operatorsData = operatorsData.map((obj) => {
              obj['category'] = 'operators';
              obj['name'] = obj.business_name;
              return obj;
            }).slice(0, 3);
          }
          categoriesData = response.data.result.categories;
          if (categoriesData) {
            categoriesData = categoriesData.map((obj) => {
              obj['category'] = 'activities';
              obj['name'] = obj.category_name;
              return obj;
            }).slice(0, 2);
          }
          if (sportsData && categoriesData) {
            if (sportsData.length === 2) {
              categoriesData = categoriesData.slice(0, 2);
            } else if (sportsData.length === 1) {
              categoriesData = categoriesData.slice(0, 3);
            } else {
              categoriesData = categoriesData.slice(0, 4);
            }
          }
          packagesData = response.data.result.packages;
          if (packagesData) {
            packagesData = packagesData.map((obj) => {
              obj['category'] = 'packages';
              return obj;
            }).slice(0, 3);
          }

          this.mergeArray = [
            sportsData ? { category: "sports", data: sportsData } : undefined,
            categoriesData ? { category: "activities", data: categoriesData } : undefined,
            operatorsData ? { category: "operators", data: operatorsData } : undefined,
            packagesData ? { category: "packages", data: packagesData } : undefined,
          ];

          this.mergeArray = this.mergeArray.filter(Boolean);
        }
      },
      (_error) => { // check for error
      });
  };

  searchAdventures = (item) => {

    // broadcast an event when search from search page.
    // eslint-disable-next-line no-undef
    $('body').removeClass('fix-scroll');
    var data = {
      category: 'User Search',
      action: 'Click',
      gtmLabel: 'Search'
    };
    this.rootScope.$broadcast('addEventToGTM', data);
    this.hidePopup('#search-modal');
    let routeData = {};
    routeData['page'] = 1;
    routeData['keyword'] = item;
    routeData['lang'] = "";
    routeData['lat'] = "";
    routeData['limit'] = 24;
    routeData['location'] = "";
    routeData['max_price'] = "";
    routeData['min_price'] = "";
    routeData['maxdate'] = "";
    routeData['mindate'] = "";
    routeData['sort'] = "";
    routeData['activities'] = "";
    routeData['activity_id'] = "";
    routeData['page'] = 1;
    this.router.stateService.go("filter", routeData)
  };

  hidePopup = (modalId) => { // hide the hide
    $(modalId).modal('hide');
    // document.getElementsByTagName('body')[0].classList.remove('modal-open');
    document.getElementsByTagName('body')[0].className.replace(' modal-open ', ' ');
    $('body').css('padding-right', '0px');
    if (angular.element(document.querySelector('.modal-backdrop')).length) {
      var el = document.getElementsByClassName('modal-backdrop')[0];
      // document.getElementsByClassName("modal-backdrop")[0].remove();
      el.parentNode.removeChild(el);
    }
  };
}

angular.module('heliApp').service("searchSearchService", downgradeInjectable(SearchServiceService));