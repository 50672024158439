import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxPaginationModule } from 'ngx-pagination';

import { ActivityBannerComponent } from '../../components/activity-categories/activity-banner/activity-banner.component';
import { ActivityStoriesBannerComponent } from '../../components/activity-categories/activity-stories-banner/activity-stories-banner.component';
import { AdvantageHeliBannerComponent } from '../../components/activity-categories/advantage-heli-banner/advantage-heli-banner.component';
import { CreateCustomTripComponent } from '../../components/activity-categories/create-custom-trip/create-custom-trip.component';
import { ActivityDatePickerComponent } from '../../components/activity-date-picker/activity-date-picker.component';
import { ActivitiesListComponent } from '../../components/activity-categories/activities-list/activities-list.component';
import { AdventureCardComponent } from '../../components/activity-categories/adventure-card/adventure-card.component';
import { AdvertisementCardComponent } from '../../components/activity-categories/advertisement-card/advertisement-card.component';
import { DestinationCardComponent } from '../../components/activity-categories/destination-card/destination-card.component';
import { SortFilterComponent } from '../../components/sort-filter/sort-filter.component';
import { SidebarFilterComponent } from '../../components/sidebar-filter/sidebar-filter.component';
import { AdditionalFeaturesSliderModule } from '../../components/additional-features-slider/additional-features-slider.module';
import { AdditionalFeaturesSliderComponent } from '../../components/additional-features-slider/additional-features-slider.component';
import { CreditsPromoComponent } from "../../components/credits-promo/credits-promo.component";

import { UIRouterModule } from "@uirouter/angular";
import { ConnectWithExpertComponent } from "../../components/home/connect-with-expert/connect-with-expert.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    GooglePlaceModule,
    UIRouterModule,
    SlickCarouselModule,
    NgxPaginationModule,
    AdditionalFeaturesSliderModule
  ],
  declarations: [
    ActivityBannerComponent,
    ActivityStoriesBannerComponent,
    AdvantageHeliBannerComponent,
    CreateCustomTripComponent,
    ActivityDatePickerComponent,
    ActivitiesListComponent,
    AdventureCardComponent,
    DestinationCardComponent,
    AdvertisementCardComponent,
    SortFilterComponent,
    SidebarFilterComponent,
    CreditsPromoComponent,
    ConnectWithExpertComponent
  ],
  providers: [],
  exports: [
    CommonModule,
    FormsModule,
    NgxDaterangepickerMd,
    GooglePlaceModule,
    ActivityBannerComponent,
    ActivityStoriesBannerComponent,
    AdvantageHeliBannerComponent,
    CreateCustomTripComponent,
    ActivityDatePickerComponent,
    ActivitiesListComponent,
    AdventureCardComponent,
    DestinationCardComponent,
    AdvertisementCardComponent,
    SortFilterComponent,
    SidebarFilterComponent,
    AdditionalFeaturesSliderComponent,
    CreditsPromoComponent,
    ConnectWithExpertComponent
  ],
})
export class ActivityElementsModule {}
