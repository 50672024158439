/* eslint-disable handle-callback-err */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:userOrderListCtrl
 * @description
 * # userOrderListCtrl
 * Controller of the heliApp for listing all orders places
 */
angular.module('heliApp').controller(
  'userOrderListCtrl', ['$scope', 'userOrdersListService', '$rootScope', '$cookies', '$state', '$window', '$stateParams', 'userLoginService', 'userAdventureDetailService', 'STRIPE_KEY', 'CONST_HELPER', '$timeout', 'rootScopeService',
  function ($scope, userOrdersListService, $rootScope, $cookies, $state, $window, $stateParams, userLoginService, userAdventureDetailService, STRIPE_KEY, CONST_HELPER, $timeout, rootScopeService) {
    var token = $scope.user.token; // set user token
    $scope.cancelOrderArea = false;
    $scope.cancelOrderDetail = {};
    $scope.canloadmore = false;
    $rootScope.packageDetail = [];
    $rootScope.CartDetails = [];
    $scope.guestFormsData = [];
    var limit = 4;
    var page = 0;
    $scope.package_limit = 3;
    $rootScope.guestUserRemainingPayment = !!$stateParams.order_id;
    rootScopeService.setValue("guestUserRemainingPayment", $rootScope.guestUserRemainingPayment);
    if ($rootScope.guestUserRemainingPayment) {
      var params = $stateParams.order_id.split('--');
      var booking_order_id = params[0];
      var guest_email = params[1];
    }

    $scope.getOrderList = function (isLoadMore, redirectToForm) { // get order list
      // $rootScope.isUserLoggedIn(); // check id user is logged in
      $scope.showLoader();
      if ($rootScope.guestUserRemainingPayment) {
        var reqObject = {
          'email': guest_email,
          'password': '', // by passing user for white label remaining payment...
          'guest_user': true
        };
        userLoginService.loginUser(reqObject).then(function (response) {
          if (response.data.code === '200') { // success response
            $scope.showLoader();
            $scope.setUserDetails(response.data.data, 'second'); // set user details in the rootScope;
            $scope.user = response.data.data; // set user data into local scope;
            userOrdersListService.getOrderList($scope.user.token).then(function (response) { // send req for orders
              if (response.data.code === '200') { // success response
                var allBookings = response.data.bookings;
                var currentBooking = allBookings.find(function (ab) {
                  return ab.cart.order_id === booking_order_id;
                });
                allBookings.forEach(function (booking) {
                  booking.cart_slot_details.forEach(function (cartSlot) {
                    if (cartSlot.guest_details && cartSlot.guest_details.length > 0) {
                      cartSlot.guest_details.map(function (guest) {
                        guest.isFormsComplete = !$scope.incompleteForm(guest.guest_forms);
                        if (redirectToForm) {
                          var findGuestForm = guest.guest_forms.find(function (form) {
                            return form.id == redirectToForm.id && form.status_id != CONST_HELPER.ONE;
                          });
                          if (findGuestForm) {
                            if (findGuestForm.status_id != CONST_HELPER.ONE) {
                              $window.open(findGuestForm.form_url, '_blank');
                            }
                          }
                        }
                      });
                    }
                  });
                });

                if (currentBooking.cart_slot_details[0] && currentBooking.cart_slot_details[0].payment_detail.payment_status === 2) {
                  var depositPayment = currentBooking.cart_slot_details[0].payment[0];
                  if (
                    // Payment was through Flywire
                    depositPayment.flywire_reference
                    &&
                    (
                      // Payment history empty
                      (depositPayment.flywire_payments_history.length === 0)
                      ||
                      // Deposit payment not in "Delivered" status
                      (depositPayment.flywire_payments_history[0].status !== 'delivered')
                    )
                  ) {
                    $scope.showPopup('#depositPaymentIsNotComplete');
                  } else {
                    $scope.payRemainingAmount(currentBooking.cart_slot_details[0], booking_order_id);
                  }
                } else {
                  $scope.showPopup('#partialPaymentDone');
                }
              }
              $scope.hideLoader();
            }, function (err) {
              $scope.hideLoader();
              $scope.showPopup('#serverErrModal');
            });
          } else {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          }
        }, function (err) {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      } else {
        $rootScope.isUserLoggedIn(); // check id user is logged in
        userOrdersListService.getOrderList(token).then(function (response) { // send req for orders
          if (response.data.code === '200') { // success response
            $scope.allOrders = response.data.bookings; // set orders
            $scope.allOrders.forEach(function (booking) {
              booking.cart_slot_details.forEach(function (cartSlot) {
                if (cartSlot.guest_details && cartSlot.guest_details.length > 0) {
                  cartSlot.guest_details.map(function (guest) {
                    guest.isFormsComplete = !$scope.incompleteForm(guest.guest_forms);
                    if (redirectToForm) {
                      var findGuestForm = guest.guest_forms.find(function (form) {
                        return form.id == redirectToForm.id && form.status_id != CONST_HELPER.ONE;
                      });
                      if (findGuestForm) {
                        if (findGuestForm.status_id != CONST_HELPER.ONE) {
                          $window.open(findGuestForm.form_url, '_blank');
                        }
                      }
                    }
                  });
                }
              });
            });
            if (!isLoadMore) {
              $scope.loadMore();
            }
          } else if (response.data.code === '203') {
            $scope.logout(); // unauthorised user
            $state.go('signin');
          }
          $scope.hideLoader();
        }, function (err) {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }
    };

    $scope.sendForms = function (guest, package_id) {
      if (guest && guest.email != '') {
        $scope.showLoader();
        var formData = {
          'guest_id': guest.id,
          'guest_name': guest.name,
          'guest_email': guest.email,
          'guest_forms': getGuestForms(guest),
          'token': token
        };
        userAdventureDetailService.sendForms(formData).then(function (res) {
          $scope.hideLoader();
          if (res.data.code === CONST_HELPER.API_RESPONSE.OK || res.data.code === CONST_HELPER.API_RESPONSE.NO_CONTENT) {
            $scope.$parent.successMsg = 'Your information has been sent.';
            $scope.showPopup('#saveDetailModal');
          }
        }).catch(function (err) {
          $scope.hideLoader();
        });
      } else {
        $scope.showPopup('#AlertModal');
        $rootScope.errorAlert = 'Please complete guest details.';
      }
    };

    function getGuestForms(guest) {
      var guest_form = [];
      guest.guest_forms.map(function (gf) {
        var formObj = {
          'title': gf.title,
          'form_url': gf.form_url
        };
        guest_form.push(formObj);
      });
      return guest_form;
    }

    $scope.cancelOrder = function (slot) { // cancel the order
      $scope.showLoader();
      var data = {
        params: {
          'payment_id': slot.payment_detail.id,
          'cart_id': slot.cart_id
        },
        token: token
      };
      // var data = {params : {'amount' : slot.total_amount, 'order_number' : slot.order_id, 'cart_slot_id' : slot.id, 'cart_id' : slot.cart_id} , token : token}
      userOrdersListService.cancelOrder(data).then(function (response) {
        if (response.data.code === '200') {
          if (response.data.is_refund === 1) { // successful refund
            slot.is_cancelled = 1;
            slot.payment_detail.refunded_amount = response.data.refunded_amount;
            slot.payment_detail.local_refunded_amount = response.data.local_refunded_amount;
            slot.payment_detail.payment_status = 3;
          } else {
            $scope.showPopup('#error-cancellation'); // exceeded cancellation policy days
          }
        } else {

        }
        $scope.cancelOrderArea = false;
        $scope.hideLoader();
      }, function (err) {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    };

    $scope.startCancelProcess = function (slot, orderId, ord_date) { // start the cancellation process
      $scope.cancelOrderArea = true;
      $scope.cancelOrderDetail = slot; // set the order for cancelling
      $scope.cancelOrderDetail.order_id = orderId; // order number
      $scope.cancelOrderDetail.ord_date = ord_date; // order date
      if ($scope.cancelOrderDetail.payment_detail.payment_status === 2) {
        $scope.cancelOrderDetail.payment_detail.local_total_tax_amount = $scope.cancelOrderDetail.payment_detail.local_tax - $scope.cancelOrderDetail.payment[0].local_proc_fee_amount;
      } else {
        $scope.cancelOrderDetail.payment_detail.local_total_tax_amount = $scope.cancelOrderDetail.payment_detail.local_tax;
      }
      local_currency_code = $scope.cancelOrderDetail.payment_detail.local_currency_code || $scope.cancelOrderDetail.local_currency_code;
      $scope.addons_currency_symbol = $scope.currencySymbol[local_currency_code];
      $window.scrollTo(0, 0);
    };
    // show addons popup...
    var local_currency_code = '';
    $scope.showAddons = function (addons) {
      $scope.addons_currency_symbol = $scope.currencySymbol[local_currency_code];
      $scope.currnetselectedAddons = addons;
    };
    // Show Room Details in the lodging popup
    $scope.showRoomsDetails = function (lodging_details, order_package) {
      $scope.lodging_details = lodging_details;
      $scope.selectedPackage = order_package;
    };
    // update slot-adult_quantity by slot_lodging_details
    $scope.calculateGuestsFromLodging = function (lodging) {
      var quantity = 0;
      if (lodging.package.package_case !== 3 && lodging.package.lodging_status !== 0) {
        lodging.cart_slot_lodging_details.forEach(function (item) {
          quantity += item.quantity;
        });
        lodging.total_guests = quantity;
      }
    };
    // get the cancellation policy of particular package...
    $scope.getCancellationPolicy = function (package_id) {
      $scope.showLoader();
      userOrdersListService.getCancellationPolicy(package_id, token).then(function (res) {
        $scope.cancellationPolicyDetails = res.data.policy;
        $scope.cancellationPolicyDetails.cancellation_description = $scope.removeHtmlTags(res.data.policy.cancellation_description);
        $scope.showPopup('#cancel-policy');
        $scope.hideLoader();
      }, function () {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    };
    $scope.dropOrderCancelling = function () { // stop order cancelling
      $scope.cancelOrderArea = false;
    };
    $scope.loadMore = function () {
      if (page === 1) {
        $scope.orders = [];
      }
      page = page + 1;
      var len = page * limit;
      if ($scope.allOrders.length > len) {
        $scope.orders = $scope.allOrders.slice(0, len);
        $scope.canloadmore = true;
      } else {
        len = $scope.allOrders.length;
        $scope.orders = $scope.allOrders.slice(0, len);
        $scope.canloadmore = false;
      }
      $scope.orders.map(function (order, key) {
        var total_tax = order.cart.taxes.total.price.amount;
        var total_processing_fee = 0;
        var total_discount = 0;
        var total_paid_amount = 0;
        var total_nightly_discount = 0;
        var is_partial = 0;
        order.cart_slot_details.map(function (currentOrder) {
          var total_price = 0; // calculate total price of package.

          // get every package tax and processing_fee
          currentOrder.tax = 0;
          currentOrder.pro_fee = 0;
          if (currentOrder.payment) {
            currentOrder.payment.map(function (paymentObj, key) {
              currentOrder.pro_fee = currentOrder.pro_fee + paymentObj.local_proc_fee_amount;
              total_processing_fee += paymentObj.local_proc_fee_amount;
              currentOrder.tax = currentOrder.tax + paymentObj.local_tax_amount;
            });
          }

          if (currentOrder.payment_detail.is_partial === 1) {
            is_partial = true;
          }

          if (currentOrder.payment_detail && currentOrder.payment_detail.payment_status === 2) { //  if order balance is over due...
            currentOrder.payment.map(function (item) {
              total_paid_amount = total_paid_amount + item.local_amount;
              total_price = total_price + item.local_amount;
            });
            // total_paid_amount = total_paid_amount + (currentOrder.discount_amount * currentOrder.payment_detail.opr_percentage / 100);
            // total_discount = total_discount + currentOrder.discount_amount * currentOrder.payment_detail.opr_percentage / 100;
          } else if (currentOrder.payment_detail && currentOrder.payment_detail.payment_status === 3) { //  if order is cancelled...
            // currentOrder.payment.map(function(item){
            //    total_paid_amount = total_paid_amount + item.local_amount;
            //    total_price = total_price + item.local_amount;
            // })
            if (currentOrder.payment_detail.is_partial === 1 && currentOrder.payment_detail.due_amount > 0) { //  if order is cancelled and final payment is due...
              total_discount = total_discount + currentOrder.discount_amount * currentOrder.payment_detail.opr_percentage / 100;
              total_paid_amount = total_paid_amount + currentOrder.payment[0].local_amount + currentOrder.discount_amount * currentOrder.payment_detail.opr_percentage / 100;
              total_price = total_price + currentOrder.payment[0].local_amount + currentOrder.payment[0].local_tax_amount + currentOrder.payment[0].local_proc_fee_amount;
            } else { //  if order is cancelled and full payment is done...
              total_discount = total_discount + currentOrder.discount_amount;
              total_paid_amount = total_paid_amount + currentOrder.payment_detail.local_total_amount + currentOrder.discount_amount;
              total_price = total_price + currentOrder.payment_detail.local_total_amount + currentOrder.payment_detail.local_tax + currentOrder.payment_detail.local_total_processing_fee;
            }
          } else { // else order is full paid...
            total_discount = total_discount + currentOrder.discount_amount;
            total_paid_amount = total_paid_amount + currentOrder.base_amount;
            total_price = currentOrder.base_amount - currentOrder.discount_amount;
          }
          total_nightly_discount = total_nightly_discount + currentOrder.nightly_discount;
          currentOrder.total_price = total_price;
          local_currency_code = currentOrder.payment_detail.local_currency_code || currentOrder.local_currency_code;
        });
        order.cart.total_tax = total_tax;
        order.cart.is_partial = is_partial;
        order.cart.total_paid_amount = total_paid_amount;
        order.cart.total_processing_fee = total_processing_fee;
        if (!is_partial) {
          order.cart.grandTotal = total_paid_amount + total_tax + total_processing_fee - total_discount - total_nightly_discount - order.cart.credit_amount;
        } else {
          order.cart.grandTotal = total_paid_amount + total_tax + total_processing_fee - total_discount - total_nightly_discount;
        }
        order.cart.discount_amount = total_discount;
        order.cart.nightly_discount = total_nightly_discount;
        order.cart.local_currency_symbol = $scope.currencySymbol[local_currency_code];
      });
    };
    $scope.checkOrderPackageLen = function (order) {
      order.canloadmore = order.cart_slot_details.length > $scope.package_limit;
      order.showMore = !!order.canloadmore;
    };
    $scope.loadMorePackages = function (order) {
      order.showMore = !order.showMore;
      if (!order.showMore) {
        $scope.package_limit = order.cart_slot_details.length;
      } else {
        $scope.package_limit = 3;
      }
    };
    var remainingTax, localremainingTax;
    $scope.payRemainingAmount = function (item, orderId, bookingSource) {
      //return false;
      // Load stripe when user is doing partial payment...

      if (typeof Stripe === 'undefined' && $rootScope.siteInIFrame) {
        $scope.loadScript('https://js.stripe.com/v2/', function () {
          $scope.loadScript('https://js.stripe.com/v3/', function () {
            window.Stripe.setPublishableKey(STRIPE_KEY);
            Stripe(STRIPE_KEY);
            var existingModule = angular.module('heliApp');
            existingModule.requires.push('angular-stripe');
            $rootScope.$broadcast('stripe-loaded');
          });
        });
      }

      bookingSource = bookingSource || '';
      if (!item.package_slots && item.package_slots === null) {
        $scope.showPopup('#order-slot-deleted');
        return;
      }

      // set currency code and currency symbol...
      $rootScope.selectedCurrencyCode = item.local_currency_code;
      $rootScope.selectedCurrencySymbol = $scope.currencySymbol[$rootScope.selectedCurrencyCode];
      $rootScope.usdRate = item.payment_detail.cc_rate;

      remainingTax = item.payment_detail.tax - item.payment[0].tax_amount;
      localremainingTax = item.payment_detail.local_tax - item.payment[0].local_tax_amount;
      var obj = {
        'slot_id': item.id,
        'package_id': item.package.id,
        'operator_id': item.package.user_id,
        'tax_amount': remainingTax,
        'local_tax_amount': localremainingTax,
        'is_partial': 1,
        'processing_fee': calculateRemainingProcessingFee(item.payment_detail.due_amount),
        'local_processing_fee': item.payment_detail.local_total_processing_fee - item.payment[0].local_proc_fee_amount,
        'due_amount': item.payment_detail.due_amount,
        'local_due_amount': item.payment_detail.local_due_amount,
        'slot_amount': item.payment_detail.due_amount,
        'local_slot_amount': item.payment_detail.local_due_amount,
        'is_final_payment': 1,
        'total_tax': item.payment_detail.tax,
        'local_total_tax': item.payment_detail.local_tax,
        // "opr_percentage":0,
        'due_date': item.payment_detail.due_date,
        'payment_detail_id': item.payment_detail.id
      };
      $rootScope.CartDetails.push(obj);

      delete item.package_medias;
      delete item.booking_time;
      delete item.created;
      delete item.is_cancelled;
      delete item.is_genric;
      delete item.is_mail_sent;
      delete item.modified;
      delete item.package_cancellation_policy;
      delete item.referral_id;
      delete item.cart_slot_lodging_details;
      delete item.guests;
      delete item.package.description;
      delete item.package.package_case;
      delete item.package.package_type;
      delete item.package.currency_id;
      delete item.package.location_name;
      delete item.package.lodging_status;
      delete item.package.publish_end_date;
      delete item.package.publish_start_date;
      delete item.slot_usd_child_price;
      delete item.slot_child_price;
      delete item.slot_child_quantity;
      delete item.can_be_cancelled;
      delete item.operator_tax_cloud;
      delete item.currency;
      delete item.addons_details;
      $rootScope.packageDetail.push(item);
      $rootScope.bookingSource = bookingSource;
      $cookies.put('cart_id', item.cart_id);
      $cookies.put('canCheckout', 1);
      $cookies.put('cartDetails', JSON.stringify($rootScope.CartDetails));
      $cookies.put('packageDetail', JSON.stringify($rootScope.packageDetail));
      $cookies.put('cartTaxAmount', localremainingTax);
      $cookies.put('usdRate', $rootScope.usdRate);
      $cookies.put('selectedCurrencyCode', $rootScope.selectedCurrencyCode);
      $cookies.put('bookingSource', bookingSource);
      $rootScope.doNotReleaseSeat = true;
      $state.go('secure-checkout', {
        order_id: orderId
      });
    };

    function calculateRemainingProcessingFee(remainingAmount) {
      var remainingProcessingFee = 0;
      var total;
      total = (remainingAmount + remainingTax + 0.30) / (1 - 0.029);
      remainingProcessingFee = total - (remainingAmount + remainingTax);
      return remainingProcessingFee;
    }
    // add and edit guest
    $scope.showEditGuest = function (item) {
      item.editGuest = !item.editGuest;
      $('.selectpicker_' + item.id).prop('disabled', false);
      $('.selectpicker_' + item.id).selectpicker('refresh');
    };
    $scope.incompleteForm = function (forms) {
      var statusArr = [];
      forms && forms.forEach(function (form) {
        statusArr.push(form.status_id);
      });
      return statusArr.indexOf(4) != CONST_HELPER.MINUS_ONE;
    };
    $scope.EditGuestDetails = function (form, item) {
      var guest = item.guests;
      if (form.$invalid) {
        return;
      }
      $('.selectpicker_' + item.id).prop('disabled', true);
      $('.selectpicker_' + item.id).selectpicker('refresh');
      $scope.showLoader();
      var dataObj = [];
      guest.forEach(function (ins) {
        dataObj.push({
          'guest_id': ins.id,
          'name': ins.name,
          'email': ins.email,
          'notes': ins.notes,
          'is_adult': ins.is_adult,
          'allergies': ins.allergies
        });
      });
      var guest_data = {
        'guest_data': dataObj
      };
      userOrdersListService.updateGuestConsumerDetails(guest_data, $scope.user.token).then(function (res) {
        if (res.data.code === '200') {
          $scope.getOrderList(true);
          item.editGuest = false;
          $scope.hideLoader();
        } else if (res.data.code === '203') {
          $scope.logout();
          $scope.hideLoader();
        }
      });
    };

    $scope.depositPaymentIsNotCompleteOkButtonClick = function () {
      $state.go('home');
    }
  }]);
