/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:ambassadorsListingCtrl
 * @description
 * # ambassadorsListingCtrl
 * Controller of the heliApp
 */

angular.module('heliApp').controller('ambassadorsListingCtrl',
  [
    '$scope',
    '$location',
    '$timeout',
    '$window',
    'ambassadorsListingService',
    '$state',
    'CONST_HELPER',
    'sharedService',
    '$rootScope',
    '$stateParams',
    'searchService',
    function ($scope, $location, $timeout, $window, ambassadorsListingService, $state, CONST_HELPER, sharedService, $rootScope, $stateParams, searchService) {
      $scope.ambassadorList = []; // list of all influencer
      $scope.page = 0;
      $scope.isLoadButton = true; // default load more button will be shown
      $scope.searchText = {};
      $scope.searchText.text = '';
      $scope.currentSearchKey = '';
      $scope.isSearchTextFocused = false;
      var listLimit = CONST_HELPER.page_limit;
      // if($rootScope.previousState && $rootScope.previousState.name=="myprofile"){
      //           $state.params.back= $state.params.page>1 ?1:0;
      //           $location.search($state.params);
      //       }
      // var back = $state.params.back ? $state.params.back : 0 || $state.params.page>1 ?1:0
      var back = $state.params.page > 1 ? 1 : 0;
      // var getListCalled = false

      // get featured adventure and featured ambassadors...
      function getfeaturedData () {
        // $scope.showLoader();
        searchService.getfeaturedAdventures().then(
          function (response) {
            if (response.data && response.data.code === '200') {
              // $scope.hideLoader()
              $rootScope.featuredAdventures = response.data.data.featuredAdventure;
              $rootScope.featuredAmbassadors = response.data.data.featuredAmbassadors;
            }
          }, function () {
            // $scope.hideLoader()
          });
      }
      // call getfeaturedData function
      getfeaturedData();

      $scope.getAmbassadorsList = function (search) { // get list of all ambassadors / influencers
        // getListCalled = true
        // list is for search then counter for page will be reset to 1
        angular.element(document.getElementsByClassName('no-ambassadors-sub')).css('display', 'none');
        $scope.searchText.text = $state.params.search; // display text in search box
        if (search === undefined) {
          // for moving scrollbar to specific location form where load more button is clicked
          // var search_param = ($state.params.search) ? $state.params.search : ''
          if ($state.params.page === 1) {
            $scope.ambassadorList = [];
          }
          // var key_name = 'back-'+(Number($state.params.back) + 1)+'page-'+(Number($state.params.page) + 1)+'search-'+search_param;
          // if(!$rootScope.current_scrollbarId){
          //  $rootScope.current_scrollbarId = {};
          // }
          // $rootScope.current_scrollbarId[key_name] = '#ambassador'+$scope.ambassadorList[$scope.ambassadorList.length - 1].id;
          // to handle the state of each page in case of load more
          // $state.params.back = 1;
          // $state.params.page = Number($state.params.page) + 1;
          // $state.params.search = $state.params.search;
          // $location.search($state.params);
          // $state.go("ambassadors-listing",{back : Number($state.params.back) + 1, page : Number($state.params.page) + 1, search : $state.params.search})
          // return false;
        } else {
          if (search !== $state.params.search && search) {
            // to handle search text functionality
            $scope.ambassadorList = [];
            // $state.params.back = 0;
            // $state.params.page = 1;
            // $state.params.search = search;
            // $location.search($state.params);
            // $rootScope.ambassador_list_state.page = 1;
            // $state.go("ambassadors-listing",{back : 0, page : 1, search : search})
            // return false;
            $state.params.search = search;
            $state.params.page = 1;
            $state.params.back = 0;
            $location.search($state.params);
          } else {
            $scope.ambassadorList = []; // same text is been search
            $state.params.page = 1;
            $location.search($state.params);
          }
        }
        var data = {
          'page': $state.params.page || 1,
          'search': ($state.params.search) ? $state.params.search : '',
          'token': ($scope.user.token) ? $scope.user.token : '', // when user is loggedin
          'limit': listLimit,
          'sort': $state.params.sort ? $state.params.sort : 'popularity',
          'back': back
        };
        // var data = {
        //  "page" : $rootScope.ambassador_list_state.page,
        //  "search" : ($rootScope.ambassador_list_state.search)? $rootScope.ambassador_list_state.search : "",
        //  "token" : ($scope.user.token)?$scope.user.token : "" ,// when user is loggedin
        //  "limit" : 2,
        //  "back" : ($rootScope.ambassador_list_state.back)?$rootScope.ambassador_list_state.back : ""
        // }
        $scope.selectedSort = $state.params.sort;
        $scope.showLoader();
        ambassadorsListingService.getAmbassadorsList(data).then(function (res) {
          switch (res.data.code) {
            case '200' :
              $scope.hideLoader();
              // per page count is greater than count of total influencer
              // var startIndex = ($scope.page - 1) * CONST_HELPER.INFLUENCER_COUNT_PER_PAGE
              // var endIndex = startIndex + CONST_HELPER.INFLUENCER_COUNT_PER_PAGE
              // append the list
              // res.data.data.map(function(e){
              //  $scope.ambassadorList.push(e);
              // })
              $scope.totalambassadors = res.data.count;
              $scope.ambassadorList = $scope.ambassadorList.concat(res.data.data);
              $scope.isLoadBtn = ((res.data.count - $scope.ambassadorList.length) > 0);
              $scope.resCount = res.data.count;
              // $timeout(function(){
              // var search_param = ($state.params.search) ? $state.params.search : ''
              // var key_name = 'back-'+$state.params.back+'page-'+$state.params.page+'search-'+search_param;
              // if($rootScope.current_scrollbarId){
              //  if(angular.element($rootScope.current_scrollbarId[key_name]).length){
              //   angular.element("html, body").animate({ scrollTop: angular.element($rootScope.current_scrollbarId[key_name]).offset().top }, 1000);
              //  }
              // }
              // },100)
              $timeout(function () { // scroll to previous position.
                if ($rootScope.ambassadorOffset > 500 && $rootScope.previousState && ($rootScope.previousState.name === 'myprofile' || $rootScope.previousState.name === 'signin')) {
                  angular.element('html, body').animate({ scrollTop: $rootScope.ambassadorOffset }, 1000);
                }
              }, 10);
              break;
            case '404' :
              $scope.ambassadorList = [];
              $scope.totalambassadors = 0;
              $scope.isLoadBtn = false;
              angular.element(document.getElementsByClassName('no-ambassadors-sub')).css('display', '');
              $scope.hideLoader();
              break;
            default :
              $scope.hideLoader();
              $scope.showPopup('#serverErrModal');
              break;
          }
        });
      };
      $timeout(function () {
        if (!$rootScope.pageChanged) {
          // $scope.getAmbassadorsList();
        }
      }, 500);
      // $scope.getAmbassadorsList();
      // function apiResponseCode (response) {
      //   switch (response.code) {
      //     case '401' :
      //       $scope.hideLoader()
      //       $scope.showPopup('#serverErrModal')
      //       break
      //     case '405' :
      //       $scope.hideLoader()
      //       $scope.showPopup('#serverErrModal')
      //       break
      //     case '400' :
      //       $scope.hideLoader()
      //       $scope.showPopup('#serverErrModal')
      //       break
      //     case '404' :
      //       $scope.ambassadorList = []
      //       $scope.isLoadBtn = false
      //       angular.element(document.getElementsByClassName('no-ambassadors-sub')).css('display', '')
      //       break
      //     default :
      //       $scope.hideLoader()
      //       $scope.showPopup('#serverErrModal')
      //       break
      //   }
      // }

      $scope.followAmbassador = function (userId, index, followUser) { // to follow ambassador
        $scope.showLoader();
        var data = {
          'follower_id': userId,
          'token': $scope.user.token
        };
        if (followUser) { // user wants to follow influencer
          sharedService.followUser(data).then(function (res) {
            switch (res.data.code) {
              case '200' :
                $scope.ambassadorList[index].is_follow = followUser;
                break;
              case '201' : // user has requested to follow private profile user
                $scope.ambassadorList[index].is_follow = CONST_HELPER.follow_user.REQUESTED_FOR_FOLLOW;
                break;
              default :
                $scope.hideLoader();
                $scope.showPopup('#serverErrModal');
                break;
            }
            $scope.hideLoader();
          }, function (_err) {
            $scope.hideLoader(); $scope.showPopup('#serverErrModal');
          });
        } else { // user wants to unfollow influencer
          sharedService.unFollowUser(data).then(function (res) {
            switch (res.data.code) {
              case '200' :
                $scope.ambassadorList[index].is_follow = followUser;
                break;
              default :
                $scope.hideLoader();
                $scope.showPopup('#serverErrModal');
                break;
            }
            $scope.hideLoader();
          }, function (_err) {
            $scope.hideLoader(); $scope.showPopup('#serverErrModal');
          });
        }
      };

      $scope.resetListing = function () { // reset search input box
        if ($state.params.search) {
          back = 0;
          $state.params.page = 1;
          $state.params.search = ''; // display text in search box
          $location.search($state.params); // update the url is sorting is applied
          // $state.go('ambassadors-listing',{back : 0, page : 1, search : ''});
        }
      };

      $scope.loadMore = function () {
        back = 0;
        $state.params.page = Number($state.params?.page ?? 1) + 1;
        // $state.params.search = $state.params.search // display text in search box
        $location.search($state.params); // update the url is sorting is applied
      };

      $rootScope.pageChanged = function (data) {
        if (data === 'pageIncreased') {
          back = 0;
          $scope.getAmbassadorsList();
        } else if (data === 'pageDecreased') {
          $scope.ambassadorList = $scope.ambassadorList.slice(0, $rootScope.currentPage * listLimit);
          $scope.isLoadBtn = (($scope.resCount - $scope.ambassadorList.length) > 0);
          if ($scope.ambassadorList.length === 0) {
            $scope.getAmbassadorsList();
          }
        } else {
          back = 1;
          var query = $state.params.query;
          $scope.getAmbassadorsList(query);
        }
      };

      $scope.searchAmbassador = function (query) {
        // if(query.length >= 2) {
        //  $scope.getAmbassadorsList(query);
        //  $scope.ambassadorList = [];
        // }
        $state.params.back = 0;
        $state.params.search = query; // display text in search box
        $location.search($state.params); // update the url is sorting is applied
      };
      //
      $scope.sortAmbassador = function (sort) {
        $state.params.back = 0;
        $state.params.sort = sort; // display text in search box
        $location.search($state.params); // update the url is sorting is applied
      };

      // save window scrollPostion
      angular.element($window).bind('scroll', function (e) {
        if (this.pageYOffset > 100 && $rootScope.currentState.name === 'ambassadors-listing') { $rootScope.ambassadorOffset = this.pageYOffset; }
      });
    }
  ]);
