import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { LoadAdventuresService } from '../../../services/load-adventures/load-adventures.service';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';

@Component({
  selector: 'app-activities-list',
  templateUrl: '/ng-app/src/app/components/activity-categories/activities-list/activities-list.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/activity-categories/activities-list/activities-list.component.css']
})
export class ActivitiesListComponent implements OnInit {

  adventure: any;

  @Input() destinationAdventureLink: boolean = false;
  @Input() page: string;
  isDestinationPage: boolean;
  isDestinationLocation: boolean;

  constructor(
    @Inject("$scope") private scopeJS: any,
    @Inject(UIRouter) public router: UIRouter,
    public routerGlobals: UIRouterGlobals,
    public loadAdventures: LoadAdventuresService
  ) {
  }

  ngOnInit(): void {
    this.isDestinationPage = this.routerGlobals.params.destinationCategory ? true : false;
    this.isDestinationLocation = this.routerGlobals.params.destinationLocation ? true : false;
  }

  ngOnDestroy(): void {
    this.loadAdventures.noDataFoundAfterFilter = false;
    this.loadAdventures.searchData = [];
  }

  pageChanged(paginationPageNumber) {
    this.loadAdventures.filter.page = paginationPageNumber;
    this.loadAdventures.applyFilters(paginationPageNumber);
    this.loadAdventures.scrollToListTop();
  }

  viewAllSlots(event, adventure) {
    this.adventure = adventure;
    this.scopeJS.showPopup('#slot');
    event.stopPropagation();
  };

  getSeoFriendlyUrl(str) {
    return str?.replace(/\W+(?!$)/g, '-').toLowerCase();
  }

  setLocationLen(locationName) {
    let location = Array.isArray(locationName) ? locationName[0].split(',') : locationName.split(',');
    return location[location.length - 1];
  }
}
