/* eslint-disable handle-callback-err */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:publicFeedsCtrl
 * @description
 * # publicFeedsCtrl
 * Controller of the heliApp for public feeds management
 */
angular.module('heliApp').controller(
  'publicFeedsCtrl', ['$scope', '$rootScope', 'publicFeedsService', '$sce', '$timeout', 'userSocialProfileService', 'sharedService', '$state', 'REQUEST_URL', '$stateParams', '$location', 'MESSAGE_HELPER', 'CONST_HELPER', 'rootScopeService',
  function ($scope, $rootScope, publicFeedsService, $sce, $timeout, userSocialProfileService, sharedService, $state, REQUEST_URL, $stateParams, $location, MESSAGE_HELPER, CONST_HELPER, rootScopeService) {
    $rootScope.showRightPanel = true;
    // user logged in condition will be check, if public feeds page is to be loaded,
    // for individual feed post it should not check
    $scope.individual_feed_post_id = ($stateParams.feedid) ? $stateParams.feedid : false;
    checkIsUserLoogedIn();
    $scope.page = 1;
    $scope.lastPage = 0;
    $scope.back = 0;// browser back scenario
    $scope.limit = 20;// set page limit for feeds
    $scope.stop = false;
    $scope.feeds = []; // public feeds
    var done = true;
    $scope.likeUserList = [];// like user list
    $scope.modalShow = false;
    $scope.feedLoading = false; // loader for feeds paginated
    $rootScope.feedsCount = 0; // reset feed count
    rootScopeService.setValue("feedsCount", $rootScope.feedsCount);
    $scope.username = $state.params.myusername; // get username from stateparms..
    $scope.current_location = REQUEST_URL.split('/api')[0];
    $rootScope.individual_feed_post = false; // to render the view when feed post is viewed from the shared url
    $scope.isEmptyFeedText = false;
    $rootScope.feedLength = 0;
    function checkIsUserLoogedIn () {
      if (!$stateParams.feedid) {
        if ($rootScope.currentState.name === 'feeds') {
          $rootScope.isUserLoggedIn();
        }
        $timeout(function () {
          $scope.getUserProfile();// fetch user profile on load
        });
      }
    }

    $scope.getPublicFeeds = function () { // get public feeds of the user
      if (!$stateParams.feedid) {
        if ($scope.stop) { return false; }
        $scope.loading = true;
        if ($scope.page === 1 && $rootScope.currentState.name === 'user-public-feeds') { // showloader on first page
          $scope.showLoader();
        } else {
          $scope.feedLoading = true;
        }
        if ($scope.page !== $scope.lastPage) { // dont send request for same page repeatedly
          if (done) { // when one cycle completes
            var data = { token: $scope.user.token, page: $scope.page, back: $scope.back, limit: $scope.limit };
            done = false;
            if ($rootScope.currentState.name === 'user-public-feeds' || $rootScope.currentState.name === 'operator-public-feeds') {
              publicFeedsService.getPublicFeeds(data).then(function (response) {
                // var i = 0
                if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // request complete
                  datachanges(response);
                } else if (response.data.code === '203') { // logout if admin de-activate account..
                  $scope.logout();// unauthorised user
                  $scope.hideLoader();
                  $state.go('signin');
                }
              }, function () { done = true; $scope.hideLoader(); $scope.showPopup('#serverErrModal'); });
            } else {
              if ($scope.userProfile.id) {
                getUserProfileFeeds();
              }
            }
          }
        }
      } else {
        $scope.stop = true;
        isShowIndividualPost(); // show individual feed
      }
    };

    function isShowIndividualPost () { // get individual feed
      if ($stateParams.feedid && !$rootScope.individual_feed_post) {
        $rootScope.individual_feed_post = true;
        $scope.showLoader();
        var data = {
          token: $scope.user.token,
          feed_id: $stateParams.feedid
        };
        userSocialProfileService.getFeedDetail(data).then(function (res) {
          switch (res.data.code) {
            case CONST_HELPER.API_RESPONSE.OK :
              datachanges(res);
              $scope.getUserProfile();// fetch user profile who has generated this individual feed
              break;
            case CONST_HELPER.API_RESPONSE.BAD_REQUEST :
              $scope.showPopup('#feedNotExist'); // when feed no longer exists.
              break;
            default :
              $scope.showPopup('#serverErrModal');
              break;
          }
          $scope.hideLoader();
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }
    }

    $scope.redirectToUserProfile = function () { // when individual post is viewed , buts its already deleted
      // then redirect to user's profile
      if ($stateParams.feedid) {
        $scope.hidePopup('#feedNotExist');
        $state.go('link-expire');
      }
    };

    $scope.trustSrc = function (src) {
      return $sce.trustAsResourceUrl(src);
    };

    $scope.goToAdventure = function (feedpackage) {
      if (feedpackage.package_type == 2) {
        $state.go('adventure-detail-private', { operatorname: $scope.getSeoFriendlyURL(feedpackage.operator_name), packagename: $scope.getSeoFriendlyURL(feedpackage.name), package_id: feedpackage.package_id, slot_id: feedpackage.slot_id, 'package_status': feedpackage.status == 1 ? 'active' : 'expired' });
      } else {
        $state.go('adventure-detail', { operatorname: $scope.getSeoFriendlyURL(feedpackage.operator_name), packagename: $scope.getSeoFriendlyURL(feedpackage.name), package_id: feedpackage.package_id, slot_id: feedpackage.slot_id, 'package_status': feedpackage.status == 1 ? 'active' : 'expired' });
      }
    };

    // change to rootscope , likefeed is been used in userFeed.js
    $rootScope.likeFeed = function (feed) { // like or unlike feed
      $rootScope.isUserLoggedIn();
      feed.is_liked = feed.is_liked === 1 ? 0 : 1;
      var data = { feed: { feed_id: feed.id }, token: $scope.user.token };
      if (feed.is_liked === 1) { // like request
        feed.feed_likes = parseInt(feed.feed_likes) + 1;
        publicFeedsService.likeFeed(data).then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            feed.feed_likes = response.data.numFound;
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.NOT_FOUND && response.data.message === 'Feed not found.') {
            $scope.showPopup('#feedNotExist'); // when feed no longer exists.
            feed.is_liked = 0;
            feed.feed_likes = parseInt(feed.feed_likes) - 1;
            removePost(feed);
          } else {
            feed.is_liked = 0;
          }
        }, function () {
          $scope.showPopup('#serverErrModal');
        });
      } else { // unlike request
        feed.feed_likes = parseInt(feed.feed_likes) - 1;
        publicFeedsService.unlikeFeed(data).then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            feed.feed_likes = response.data.numFound;
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.NOT_FOUND && response.data.message === 'Feed not found.') {
            $scope.showPopup('#feedNotExist'); // when feed no longer exists.
            feed.is_liked = 1;
            feed.feed_likes = parseInt(feed.feed_likes) + 1;
            removePost(feed);
          } else {
            feed.is_liked = 1;
          }
        }, function () {
          $scope.showPopup('#serverErrModal');
        });
      }
    };
    $rootScope.$on('removePost', function (e, feed) {
      removePost(feed);
    });
    function removePost (feed) {
      var i = $scope.feeds.indexOf(feed);
      $scope.feeds.splice(i, 1);
    }
    $scope.getLikeUsers = function (click, feed, done) { // get like users listing
      if (click) {
        $scope.modalShow = true;
        $scope.likeUserList = [];// reset list
      }
      if (click) { // if modal is visible
        var page = typeof (page) === 'undefined' ? 1 : page + 1;
        var lastPage = typeof (page) === 'undefined' ? 0 : page - 1;
        if (page !== lastPage) { // dont send request for same page repeatedly
          if (done) { // when one cycle completes
            if (feed && feed.id) {
              $scope.feedID = feed.id;
            }
            if (feed && feed.main_post) {
              $scope.feedID = feed.main_post.id;
            }
            if (typeof ($scope.feedID) !== 'undefined') { // if feed exists
              var data = { token: $scope.user.token, page: page, back: 0, limit: 200, feed_id: $scope.feedID };
              done = false;
              publicFeedsService.getLikeUsers(data).then(function (response) {
                var i = 0;
                if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // request complete
                  done = true;
                  while (i < response.data.data.length) { // push all feeds
                    $scope.likeUserList.push(response.data.data[i]);
                    i += 1;
                  }
                  lastPage = angular.copy(page);// update last page
                  if (page === 1) {
                    $scope.showPopup('#activityModal');
                  }
                }
                $scope.hideLoader();
              }, function () { done = true; $scope.hideLoader(); $scope.showPopup('#serverErrModal'); });
            }
          }
        }
      }
    };

    $('#activityModal').on('hidden.bs.modal', function () { // on modal hode set scope
      $scope.modalShow = false;
    });
    // open image gallery
    $rootScope.selectedImgIndex = 0;
    $scope.openGallery = function (images, imgIndex) {
      $rootScope.selectedImgIndex = imgIndex;
      if (typeof (images) === 'undefined') { return false; }
      var feedImage = [];
      images.map(function (img) {
        var obj = { url: img, media_type: 0 };
        feedImage.push(obj);
      });
      $scope.feedImages = feedImage;
      $timeout(function () {
        $scope.showPopup('#feedImgGallary');
      });
    };

    $scope.userProfile = {};

    $scope.getUserProfile = function () { //  get user feed profile function
      var requestObject;
      if ($stateParams.feedid) { // for individual post view, Following and Followers list will be shown of the person who has generated the feed
        requestObject = {
          'username': $scope.feeds[0].feed_user_details.username
        };
      } else {
        requestObject = {
          'username': $state.params.myusername ? $state.params.myusername : $scope.user.username
        };
      }
      userSocialProfileService.getUserSocialProfile(requestObject, ($scope.user.token) ? $scope.user.token : '').then(function (response) {
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.userProfile = response.data.data;
          if ($rootScope.currentState.name === 'myprofile') {
            getUserProfileFeeds();
          }
        } else if (response.data.code === CONST_HELPER.API_RESPONSE.UNPROCESSABLE) {
          $scope.hideLoader();
          if (checkActivities()) {
            $rootScope.activityFilterPage = true;
            rootScopeService.setValue("activityFilterPage",  $rootScope.activityFilterPage);
            $rootScope.showFooter = true;
            rootScopeService.setValue("showFooter", $rootScope.showFooter);
          } else {
            $state.go('link-expire');
          }
        } else if (response.data.code === CONST_HELPER.API_RESPONSE.BAD_REQUEST) {
          $state.go('home');
        }
      }, function () {
        $scope.showPopup('#serverErrModal');
      });
    };

    function checkActivities () {
      if (!$rootScope.featuredActivity.length) {
        $rootScope.featuredActivity = CONST_HELPER.FEATURED_ACTIVITY.length ? CONST_HELPER.FEATURED_ACTIVITY : CONST_HELPER.FEATURED_ACTIVITY_LOCAL;
      }

      var selectedActivity = $rootScope.featuredActivity.find(function (activity) {
        return activity.sport_name.toLowerCase().replace(/ +/g, '') === $stateParams.myusername;
      });

      if (selectedActivity && selectedActivity.id) { return true; }
      return false;
    }

    // if(!$rootScope.individual_feed_post) // when feeds is been viewed , not individual view
    // $scope.getUserProfile();//fetch user profile on load

    $scope.followUser = function (action, following, index) {
      $rootScope.isUserLoggedIn();
      // if(following.following_user_details.is_owner==='1' || (action==='follow' && following.following_user_details.is_public==='0'))
      //         return false;

      $scope.showLoader();
      var data = {
        'follower_id': following.following_user_details.user_id,
        'token': $scope.user.token
      };
      if (action === 'follow') {
        sharedService.followUser(data).then(function (res) {
          if (res.data && res.data.code === CONST_HELPER.API_RESPONSE.OK) {
            following.following_user_details.is_following = 1;
          }
          $scope.hideLoader();
        }, function (err) {
          $scope.hideLoader(); $scope.showPopup('#serverErrModal');
        });
      } else {
        sharedService.unFollowUser(data).then(function (res) {
          if (res.data && res.data.code === CONST_HELPER.API_RESPONSE.OK) {
            following.following_user_details.is_following = 0;
          }
          $scope.hideLoader();
        }, function (err) {
          $scope.hideLoader(); $scope.showPopup('#serverErrModal');
        });
      }
    };

    function getUserProfileFeeds () {
      var data = { user_id: $scope.userProfile.id, token: $scope.user.token, page: $scope.page, back: $scope.back, limit: $scope.limit };
      done = false;
      userSocialProfileService.getUserFeeds(data).then(function (response) {
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          datachanges(response);
        }
      }, function (error) {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    }

    function datachanges (response) {
      var i = 0;
      done = true;
      if (response.data.feed_details.length === 0) {
        $scope.stop = true;
        $scope.feedLoading = false;
        $scope.loading = false;
        $scope.hideLoader();
      } else {
        while (i < response.data.feed_details.length) { // push all feeds
          if ((response.data.feed_details[i].feed_type === '1' && response.data.feed_details[i].post_type === '2' && response.data.feed_details[i].images.length > 5) || ((response.data.feed_details[i].feed_type === '3' || response.data.feed_details[i].feed_type === '5') && response.data.feed_details[i].main_post.post_type === '2' && response.data.feed_details[i].main_post.images && response.data.feed_details[i].main_post.images.length > 5 && response.data.feed_details[i].main_post.feed_type !== '7') || response.data.feed_details[i].feed_type === '7' || (response.data.feed_details[i].main_post && response.data.feed_details[i].main_post.feed_type === '7')) {
            response.data.feed_details[i].displayImages = [];// check if images are more than 5 in n umber
            var maxlength = 5;
            if (response.data.feed_details[i].feed_type === '7' || (response.data.feed_details[i].main_post && response.data.feed_details[i].main_post.feed_type === '7')) { // operator profile updated
              if (!response.data.feed_details[i].main_post) {
                if (response.data.feed_details[i].post_type === '1') { // cover image updated
                  response.data.feed_details[i].displayImages.push(response.data.feed_details[i].feed_user_details.cover_image);
                } else if (response.data.feed_details[i].post_type === '3') { // cover image and gallery both updated
                  response.data.feed_details[i].displayImages.push(response.data.feed_details[i].feed_user_details.cover_image);
                  maxlength = response.data.feed_details[i].gallery.length;
                } else if (response.data.feed_details[i].post_type === '2') {
                  maxlength = response.data.feed_details[i].gallery.length;
                }
              } else { // for shared or liked operator profile update
                if (response.data.feed_details[i].main_post.post_type === '1') { // cover image updated
                  response.data.feed_details[i].displayImages.push(response.data.feed_details[i].main_post.feed_user_details.cover_image);
                } else if (response.data.feed_details[i].main_post.post_type === '3') { // cover image and gallery both updated
                  response.data.feed_details[i].displayImages.push(response.data.feed_details[i].main_post.feed_user_details.cover_image);
                  maxlength = response.data.feed_details[i].main_post.gallery.length;
                } else if (response.data.feed_details[i].main_post.post_type === '2') {
                  maxlength = response.data.feed_details[i].main_post.gallery.length;
                }
              }
            }
            var j = 0;
            while (j < maxlength) {
              if (response.data.feed_details[i].feed_type === '1') { // user posted
                response.data.feed_details[i].displayImages.push(response.data.feed_details[i].images[j]);
              } else if (response.data.feed_details[i].feed_type === '7' || response.data.feed_details[i].main_post.feed_type === '7') {
                if (!response.data.feed_details[i].main_post) {
                  if (response.data.feed_details[i].post_type === '2') { // gallery images updated
                    response.data.feed_details[i].displayImages.push(response.data.feed_details[i].gallery[j]);
                  } else if (response.data.feed_details[i].post_type === '3') { // both type updated
                    response.data.feed_details[i].displayImages.push(response.data.feed_details[i].gallery[j]);
                  }
                } else {
                  if (response.data.feed_details[i].main_post.post_type === '2') { // gallery images updated
                    response.data.feed_details[i].displayImages.push(response.data.feed_details[i].main_post.gallery[j]);
                  } else if (response.data.feed_details[i].main_post.post_type === '3') { // both type updated
                    response.data.feed_details[i].displayImages.push(response.data.feed_details[i].main_post.gallery[j]);
                  }
                }
              } else {
                response.data.feed_details[i].displayImages.push(response.data.feed_details[i].main_post.images[j]);
              }
              j = j + 1;
            }
          }
          if (response.data.feed_details[i].main_post) { // for liked post
            response.data.feed_details[i].main_post.time = moment.unix(response.data.feed_details[i].main_post.feed_time).fromNow();// display time
          }
          response.data.feed_details[i].time = moment.unix(response.data.feed_details[i].feed_time).fromNow();// display time
          $scope.feeds.push(response.data.feed_details[i]);

          i += 1;
        }
        $scope.hideLoader();
        $scope.lastPage = angular.copy($scope.page);// update last page
        $scope.page = $scope.page + 1;
        $scope.feedLoading = false;
        $scope.loading = false;
      }

      if ($scope.lastPage === 1) {
        var len = $scope.feeds.length;
        $rootScope.feedLength = len;
      }
    }
    $rootScope.$on('newPublicFeeds', function (event) { // reset feed params
      if ($stateParams.feedid) { // moving to feeds screen , empty the url paramter
        // $location.search('feedid', null)
        // $stateParams.feedid = "";
        $state.go('user-public-feeds', { 'feedid': '' });
      }
      $scope.page = 1;
      $scope.lastPage = 0;
      $scope.stop = false;
      $scope.feeds = []; // public feeds
      done = true;
      $scope.getPublicFeeds();
    });

    $rootScope.$on('new-post-created', function (event, newPost) { // unshfit feeds array when new post is created...
      if ((newPost.feed_type === '1' && newPost.post_type === '2' && newPost.images.length > 5)) {
        newPost.displayImages = [];// check if images are more than 5 in n umber
        var j = 0;
        while (j < 5) {
          if (newPost.feed_type === '1') {
            newPost.displayImages.push(newPost.images[j]);
          }
          j = j + 1;
        }
      }
      newPost.time = moment.unix(newPost.feed_time).fromNow();// display time
      $scope.feeds.unshift(newPost);
    });
    var feedData = {}; var index = '';
    $scope.deletePost = function (feed) {
      feedData.feed_id = feed.id;
      index = $scope.feeds.indexOf(feed);
      $scope.showPopup('#deletePostAlert');
    };
    $scope.sendDeletepostReq = function () {
      $scope.showLoader();
      publicFeedsService.deletePost(feedData, $scope.user.token).then(function (res) {
        if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.hideLoader();
          $scope.feeds.splice(index, 1);
        }
      });
    };
    $scope.cancelDeletepostModal = function () {
      feedData = {};
      index = '';
    };
    $scope.gotoProfile = function (user) {
      $scope.hidePopup('#activityModal');
      $state.go('myprofile', { myusername: user.username });
    };

    // initialize feed edit post from myprofile
    $scope.initializeEditFeedModal = function (feed, index) {
      $scope.editHeliFeed = {};
      angular.copy(feed, $scope.editHeliFeed);
      $scope.editHeliFeed.index = index; // index of this feed in feeds array
      $scope.editHeliFeed.profile_pic = ($scope.user.profile_pic) ? $scope.user.profile_pic : '';
      $scope.editHeliFeed.user_name = $scope.user.name;
    };

    // edit the feed
    $scope.editFeed = function () {
      if ($scope.editHeliFeed.post_type === '1' && !$scope.editHeliFeed.text) {
        $scope.isEmptyFeedText = true;
      } else {
        $scope.isEmptyFeedText = false;
      }
      if (!$scope.isEmptyFeedText) {
        var data = {
          'feed_id': $scope.editHeliFeed.id,
          'text': $scope.editHeliFeed.text,
          'token': $scope.user.token
        };
        $scope.showLoader();
        userSocialProfileService.editFeedPost(data).then(function (res) {
          switch (res.data.code) {
            case CONST_HELPER.API_RESPONSE.OK :
              $scope.feeds[$scope.editHeliFeed.index].text = $scope.editHeliFeed.text; // update the main feed text
              $scope.successMsg = MESSAGE_HELPER.feed.EDIT_FEED_SUCCESSFULLY;
              rootScopeService.setValue("successMsg", $scope.successMsg);
              $scope.hidePopup('#editPost');
              $scope.showPopup('#success');
              break;
            default :
              $scope.showPopup('#serverErrModal');
              break;
          }
          $scope.hideLoader();
        }, function (err) {
          $scope.hideLoader(); $scope.showPopup('#serverErrModal');
        });
      }
    };

    angular.element(document).on('shown.bs.modal', function (e) {
      angular.element('[autofocus]', e.target).focus();
    });

    $rootScope.$on('addToFollowers', function (e) {
      pushMeToFollowersList($scope.userProfile.followers);
    });

    $rootScope.$on('removeFromFollowers', function (e) {
      removeMeFromFollowers($scope.userProfile.followers);
    });
    function pushMeToFollowersList (list) {
      if (list.length < 4) {
        var myData = {
          cover_image: '',
          image: $scope.user.profile_pic,
          is_owner: $scope.user.is_owner,
          is_public: '0',
          name: $scope.user.name,
          role_id: $scope.user.role_id,
          status_id: $scope.user.status_id,
          user_id: $scope.user.id,
          username: $scope.user.username
        };
        list.push(myData);
      }
    }

    function removeMeFromFollowers (list) {
      for (var i = 0; i < list.length; i++) {
        if (parseInt(list[i].user_id) === $scope.user.id) {
          list.splice(i, 1);
          break;
        }
      }
    }
  }]);
