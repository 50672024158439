/* eslint-disable no-undef */
/* eslint-disable camelcase */

'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:secureCheckoutCtrl
 * @description
 * # secureCheckoutCtrl manage the user radar
 * Controller of the heliApp
 */

angular.module('heliApp').controller('secureCheckoutCtrl',['$scope', '$cookies', '$timeout', '$location', '$state', 'userAdventureDetailService', 'userCheckoutService', 'scriptLoaderService', 'bookingService', 'taxesService', '$rootScope', '$stateParams', '$filter', 'STRIPE_KEY', 'CONST_HELPER', secureCheckoutCtrl]);

function secureCheckoutCtrl ($scope, $cookies, $timeout, $location, $state, userAdventureDetailService, userCheckoutService, scriptLoaderService, bookingService, taxesService, $rootScope, $stateParams, $filter, STRIPE_KEY, CONST_HELPER) {
  var canCheckout = $cookies.get('canCheckout');
  if (canCheckout !== '1' && (!$rootScope.siteInIFrame || $rootScope.isSubDomain)) {
    $state.go('link-expire');
    return;
  }

  $scope.stripeScriptInitialized = false;

  // Stripe initialization
  if (typeof Stripe === 'undefined') {
    scriptLoaderService
      .load('https://js.stripe.com/v2/', true)
      .then(function() {
        window.Stripe.setPublishableKey(STRIPE_KEY);

        console.log('Initialize Stripe modules');
        var existingModule = angular.module('heliApp');
        existingModule.requires.push('angular-stripe');

        console.log('Sets stripeScriptInitialized flag to true');
        $scope.stripeScriptInitialized = true;
        $rootScope.$broadcast('stripe-loaded');

        scriptLoaderService
          .load('https://js.stripe.com/v3/', true)
          .then(function () {
            console.log('Stripe V3 version initialization');
            // Storing stripe object to global context for accessing it
            // outside of this callback
            window.stripe = Stripe(STRIPE_KEY, { locale: 'en' });
          })
          .catch(function () {
            $scope.showPopup('#serverErrModal');
          });
      })
      .catch(function () {
        $scope.showPopup('#serverErrModal');
      });
  } else {
    $scope.stripeScriptInitialized = true;
  }

  if ($stateParams.iframe && $stateParams.url && $stateParams.url.length) {
    var fullPageDomainName = $location.host();
    var redirectUrlPayment = new URL(decodeURIComponent($stateParams.url));
    redirectUrlPayment.hostname = fullPageDomainName;
    window.location.href = redirectUrlPayment;
  }

  // set specific css for iPhone and iPad in iFrame white-label ...

  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (iOS && $rootScope.siteInIFrame) {
    $('html').css({ 'height': '100% !important', 'overflow': 'auto !important', '-webkit-overflow-scrolling': 'touch !important' });
    $('body').css({ 'height': '100% !important', 'overflow': 'auto !important', '-webkit-overflow-scrolling': 'touch !important' });
  }

  var cart_id = $cookies.get('cart_id') || $rootScope.cart_id;
  var coupon_id = $cookies.get('coupon_id') ? $cookies.get('coupon_id') : '';
  var token = $scope.user.token ? $scope.user.token : '';

  var vm = this;
  var timeout;
  vm.max = 1200;
  vm.value = 1200;
  vm.orientation = 0;
  vm.animation = 'linearEase';
  vm.iterations = 1; // Accelerate animation to fit the 1 second timeout function
  vm.label = '20:00';
  $rootScope.checkoutDone = false;

  vm.start = function () {
    var min = Math.floor(vm.value / 60) > 9 ? Math.floor(vm.value / 60) : '0' + Math.floor(vm.value / 60);
    var sec = vm.value % 60 > 9 ? vm.value % 60 : '0' + vm.value % 60;
    vm.label = min + ':' + sec;
    timeout = $timeout(function () {
      if (!$rootScope.checkoutDone) {
        vm.value--;
        if (vm.value < 1) {
          checkoutTimeout();
        }
        if (vm.value > 0) { vm.start(); }
      }
    }, 1000);
  };

  if (!$stateParams.order_id) { vm.start(); }

  if (!$rootScope.usdRate) {
    $rootScope.usdRate = JSON.parse($cookies.get('usdRate'));
  }

  $scope.userWantToProceed = false;
  $scope.taxPercent = 12.5;
  $scope.couponDiscount = 0;
  $scope.totalStayDiscount = 0;
  $scope.taxAmount = 0;
  $scope.CartTotal = 0;
  $scope.creditsAmount = 0;
  $scope.discountedTotal = 0;
  $scope.couponApplied = '';
  $scope.processingFee = 0;
  var cardPercent = 0.035; // card fee charge
  var bankPercent = 0.01; // ACH fee charge
  var maxBankProcessingFee = 5 * $rootScope.usdRate; // $5*currencyRate

  if (!$rootScope.selectedCurrencyCode) {
    $rootScope.selectedCurrencyCode = $cookies.get('selectedCurrencyCode');
  }
  $rootScope.selectedCurrencySymbol = $scope.currencySymbol[$rootScope.selectedCurrencyCode];
  $rootScope.localCurrencyCode = $rootScope.selectedCurrencySymbol;
  $scope.isManualBooking = $rootScope.currentState.name === 'operator-manual-booking';

  // Change tab type card or bank
  $scope.tabType = 'card';
  $scope.$on('change-cardFee', function (type, response) {
    $scope.tabType = response;
    claculateProcessingFee();
  });

  // #function to get the cart list...
  function getCartList (updateTotalAmount) {
    $scope.showLoader();
    $scope.payingRemaing = !!$stateParams.order_id;
    if (!$rootScope.doNotReleaseSeat) {
      $rootScope.doNotReleaseSeat = !!$stateParams.order_id;
    }
    if (!$stateParams.order_id) {
      var cartItemslistRequestOptions = {};
      if ($rootScope.isSubDomain) {
        cartItemslistRequestOptions.params = {
          returnCommission: true,
          isOnMySiteMode: $rootScope.isSubDomain
        };
      }

      userAdventureDetailService.getCartItemslist(cart_id, token, cartItemslistRequestOptions).then(function (res) {
        if (res.data.message === 'Success') {
          $scope.myCartItems = res.data;
          $rootScope.operatorAdventureCurrencyCode = $scope.myCartItems.cart_slot_details[0].local_currency_code;
          $rootScope.selectedCurrencyCode = $scope.myCartItems.cart_slot_details[0].local_currency_code;
          $scope.taxAmount = res.data.taxes.total.price.amount;
          if (!$rootScope.CartDetails) {
            $rootScope.CartDetails = JSON.parse($cookies.get('cartDetails'));
            if (!$rootScope.CartDetails || ($rootScope.CartDetails.length === 0)) {
              $rootScope.CartDetails = bookingService.createCartDetailsObject({
                cartSlotDetails: $scope.myCartItems.cart_slot_details,
                usdRate: $rootScope.usdRate,
                Ffixed: CONST_HELPER.PROCESS_FEE.Ffixed,
                Fpercent: CONST_HELPER.PROCESS_FEE.Fpercent,
                siteInIFrame: $rootScope.siteInIFrame,
                isSubDomain: $rootScope.isSubDomain,
                isManualBooking: $scope.isManualBooking,
              });
            }
          }
          calculateTotal($scope.myCartItems);
          var couponCode = $cookies.get('couponApplied');
          if (couponCode && couponCode !== 'null') { $scope.couponApplied = couponCode; }

          $scope.proceedOrChange();

          if (updateTotalAmount === 'updateTotalAmount') {
            updateSlotTotalAmount();
          }

          if ($rootScope.siteInIFrame) {
            $('#navigation-scroll').css({ 'padding-right': '0px' });
          }

          var cartItemsListEventData = {
            cartDetails: res.data.cart_details,
            cartSlotDetails: res.data.cart_slot_details,
            itemsOperators: [],
            itemsCurrencies: []
          };

          if (res.data.cart_slot_details.length > 0) {
            res.data.cart_slot_details.forEach(function(item) {
              if (cartItemsListEventData.itemsOperators.indexOf(item.operator_profile.user_id) === -1) {
                cartItemsListEventData.itemsOperators.push(item.operator_profile.user_id);
              }

              if (cartItemsListEventData.itemsCurrencies.indexOf(item.currency.code) === -1) {
                cartItemsListEventData.itemsCurrencies.push(item.currency.code);
              }
            });
          }

          $rootScope.$broadcast('secureCheckout::cartLoaded', cartItemsListEventData);
        }
        $scope.myCartItems.cart_details.credit_amount_usd && applyCredits($scope.myCartItems.cart_details.credit_amount_usd);
      }, function () {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    } else {
      $scope.hideLoader();
      $scope.userWantToProceed = true;

      var packageDetail = {
        cart_slot_details: []
      };

      // Get booking info by order number
      bookingService
        .getCartByOrderNumber(token, $stateParams.order_id)
        .then(function (response) {
          function getDurationInDays(config) {
            var diffInDays = (+config.to - +config.from) / (1000 * 3600 * 24);
            if (diffInDays === 0) {
              // Duration of any adventure couldn't be 0, at least 1 day or night
              diffInDays = 1;
            } else if (!config.isNightly) {
              // For not-nightly slots we should calculate last day too
              diffInDays += 1;
            }

            return diffInDays;
          }

          $scope.hideLoader();
          $scope.userWantToProceed = true;
          $rootScope.CartDetails = bookingService.createCartDetailsObject({
            cartSlotDetails: response.data.data[0].booking_slot_details,
            usdRate: $rootScope.usdRate ? $rootScope.usdRate : 1,
            Ffixed: CONST_HELPER.PROCESS_FEE.Ffixed,
            Fpercent: CONST_HELPER.PROCESS_FEE.Fpercent,
            siteInIFrame: $rootScope.siteInIFrame,
            isSubDomain: $rootScope.isSubDomain,
            isManualBooking: $scope.isManualBooking,
            isFinalPayment: true
          });

          if (!$rootScope.CartDetails[0].due_amount || !$rootScope.CartDetails[0].local_due_amount || !$rootScope.CartDetails[0].due_date) {
            $timeout(function () {
              $rootScope.CartDetails[0].due_date = response.data.data[0].booking_slot_details[0].payment_details[0].due_date;
              $rootScope.CartDetails[0].due_amount = response.data.data[0].booking_slot_details[0].payment_details[0].due_amount;
              $rootScope.CartDetails[0].local_due_amount = response.data.data[0].booking_slot_details[0].payment_details[0].local_due_amount;
            });
          }

          var booking = response.data.data[0];
          $scope.myCartItems = {
            cart_slot_details: booking.booking_slot_details
          };

          for (var i = 0; i < $scope.myCartItems.cart_slot_details.length; ++i) {
            // Start & end dates for slots
            if (parseInt($scope.myCartItems.cart_slot_details[i].nightly_slot_id) > 0) {
              $scope.myCartItems.cart_slot_details[i].package_slots = {
                from_date: $scope.myCartItems.cart_slot_details[i].from_date,
                to_date: $scope.myCartItems.cart_slot_details[i].to_date
              };
            } else {
              $scope.myCartItems.cart_slot_details[i].package_slots = {
                from_date: $scope.myCartItems.cart_slot_details[i].package_slot.from_date,
                to_date: $scope.myCartItems.cart_slot_details[i].package_slot.to_date
              };
            }

            // Currency
            $scope.myCartItems.cart_slot_details[i].currency = $scope.myCartItems.cart_slot_details[i].package.currency;
            $scope.operatorAdventureCurrencyCode = $scope.myCartItems.cart_slot_details[i].package.currency.code;

            // Deposit prices
            if ($scope.myCartItems.cart_slot_details[i].payment_details.length > 0) {
              var depositPayment = $scope.myCartItems.cart_slot_details[i].payment_details[0];
              $scope.myCartItems.cart_slot_details[i].package.paying_partial_amount = depositPayment.local_due_amount;
              $scope.myCartItems.cart_slot_details[i].localCurencyRate = depositPayment.cc_rate;
              $scope.CartTotal += depositPayment.local_due_amount;

              // Calculate guests count
              $scope.myCartItems.cart_slot_details[i].total_guests = 0;
              if (
                $scope.myCartItems.cart_slot_details[i].slot_lodging_details
                &&
                $scope.myCartItems.cart_slot_details[i].slot_lodging_details.length > 0
              ) {
                $scope.myCartItems.cart_slot_details[i].slot_lodging_details.map(function (sl) {
                  $scope.myCartItems.cart_slot_details[i].total_guests += sl.quantity;
                });
              } else {	// for case C & case A + no lodging...
                $scope.myCartItems.cart_slot_details[i].total_guests = $scope.myCartItems.cart_slot_details[i].slot_adult_quantity;
              }

              if ($scope.myCartItems.cart_slot_details[i].nightly_discount && $scope.myCartItems.cart_slot_details[i].nightly_discount > 0) {
                if ($scope.myCartItems.cart_slot_details[i].deposit_type == CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE) {
                  // Discount needs to be decreased by the same % as deposit
                  $scope.nightly_discount = $scope.myCartItems.cart_slot_details[i].nightly_discount * (1 - ($scope.myCartItems.cart_slot_details[i].package.amount_percentage / 100));
                } else {
                  // Discount will be applied to the final balance payment
                  $scope.nightly_discount = $scope.myCartItems.cart_slot_details[i].nightly_discount;
                }
              } else {
                $scope.nightly_discount = 0;
              }

              var isCollectPercentageTaxesOnTheDeposit = $scope.myCartItems.cart_slot_details[i].is_collect_percentage_taxes_on_the_deposit;
              var packagePrice = $scope.myCartItems.cart_slot_details[i].package.paying_partial_amount - $scope.nightly_discount;
              if (!isCollectPercentageTaxesOnTheDeposit) {
                packagePrice = $scope.myCartItems.cart_slot_details[i].base_amount;
              }

              $scope.taxAmount += taxesService.calculateTaxesSummaryPrice({
                taxes: $scope.myCartItems.cart_slot_details[i].booking_slot_details_operator_taxes,
                packagePrice: packagePrice,
                isDeposit: false,
                isCollectPercentageTaxesOnTheDeposit: isCollectPercentageTaxesOnTheDeposit,
                personsCount: parseInt($scope.myCartItems.cart_slot_details[i].total_guests),
                duration: getDurationInDays({
                  from: new Date($scope.myCartItems.cart_slot_details[i].package_slots.from_date),
                  to: new Date($scope.myCartItems.cart_slot_details[i].package_slots.to_date),
                  isNightly: parseInt($scope.myCartItems.cart_slot_details[i].nightly_slot_id) > 0,
                }),
                userCountryId: parseInt($scope.user.country_id)
              });
            }
          }

          claculateProcessingFee($scope.CartTotal);
          $scope.GrandTotal = $scope.CartTotal + $scope.taxAmount + $scope.processingFee;
          $scope.order_id = $stateParams.order_id;

          // Sets to true because only one item can be in cart
          $scope.hasSingleOperator = true;
          // Calculate commission amount
          $scope.operatorTotalCommission = 0;
          $scope.myCartItems.cart_slot_details.map(function (slot) {
            // insert payment_detail_id to slot_data for final payment
            for (var c in $scope.CartDetails) {
              if ($scope.CartDetails[c].slot_id == slot.id) {
                if (slot.payment_details && slot.payment_details.length) {
                  $scope.CartDetails[c].payment_detail_id = slot.payment_details[0].id;
                }
                break;
              }
            }

            var total = 0;
            if (slot.slot_lodging_details && slot.slot_lodging_details.length) {
              slot.total_guests = 0;
              slot.slot_lodging_details.map(function (sl) {
                total += getChargedPrivatePrice(sl, slot);
                slot.total_guests += sl.quantity;
              });
            } else {
              total = total + slot.slot_adult_price;
            }
            var totalPriceAddons = 0;
            slot.addons_details.map(function (addon) {
              total = total + addon.package_addon_adult_price + addon.package_addon_child_price;
              totalPriceAddons += addon.package_addon_adult_price;
            });
            if (slot.is_promo_code === 1) {
              slot.total = slot.is_genric === 0 ? total - slot.discount_amount : total;
            } else {
              slot.total = total;
            }

            var finalTotal = slot.total - slot.discount_amount - slot.nightly_discount; // final total after excluding discount amount...
            if ($rootScope.siteInIFrame || $rootScope.isSubDomain || $scope.isManualBooking) {
              var operatorPercent = slot.operator_commission.on_site_commission;
              $scope.operatorTotalCommission += (finalTotal - totalPriceAddons) > 0 ? ((finalTotal - totalPriceAddons) * operatorPercent / 100) : 0;
            } else {
              var operatorPercent = 0;
              if (slot.package_slots && slot.package_slot) {
                operatorPercent = slot.package_slot.commission;
              } else {
                operatorPercent = slot.operator_commission.amount;
              }
              $scope.operatorTotalCommission += (finalTotal - totalPriceAddons) > 0 ? ((finalTotal - totalPriceAddons) * operatorPercent / 100) : 0;
            }
          });
          $scope.finalOperatorAmount = $scope.GrandTotal - $scope.operatorTotalCommission - $scope.processingFee;
          $scope.finalConnectOperatorAmount = ($scope.finalOperatorAmount && $scope.finalOperatorAmount > 0) ? $scope.finalOperatorAmount : 0;

          // data for flywire remaining payment
          var cartItemsListEventData = {
            cartDetails: $rootScope.CartDetails[0],
            cartSlotDetails: response.data.data[0].booking_slot_details,
            itemsOperators: [response.data.data[0].booking_slot_details[0].operator_profile.id],
            itemsCurrencies: [response.data.data[0].booking_slot_details[0].package_currency_code]
          };

          $rootScope.$broadcast('secureCheckout::cartLoaded', cartItemsListEventData);

          $timeout(function () {
            $rootScope.$broadcast('card-saved');// get cards list
          });
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
    }
  };

  /**
   * Apply credits to the cart
   *
   * @param {number} credits - Credits to be applied
   * @returns {Promise}
   */
  async function applyCredits(credits) {
    try {
      if ($scope.myCartItems.cart_details.local_currency_code != "USD") {
        const convertedCurrency = await userAdventureDetailService.convertCurrency(credits, "USD", $scope.myCartItems.cart_details.local_currency_code);
        credits = convertedCurrency.data.usd_rate;
      }
      $scope.creditsAmount = $scope.myCartItems.cart_details.credit_amount_usd;
      if ($scope.myCartItems.cart_slot_details[0].is_deposit) return;
      $scope.GrandTotal -= credits;
      $scope.CartTotal -= credits;
    } catch (err) {
      console.log(err)
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    }
  }

  getCartList(); // call getCartList() initially...

  if ($rootScope.isSubDomain) {
    $rootScope.getOperatorOnMySiteDetail();
  }

  // calculate Total ammount...
  function calculateTotal (cart) {
    // We take tax amount from server
    //$scope.taxAmount = 0;
    $scope.GrandTotal = 0;
    $scope.discountedTotal = 0;
    $scope.CartTotal = 0;
    $scope.processingFee = 0;
    // if white label booking , then consider only first package slot...
    if ($scope.siteInIFrame && cart.cart_slot_details.length > 1) {
      cart.cart_slot_details = cart.cart_slot_details.splice(0, 1);
    }
    cart.cart_slot_details.map(function (item, key) {
      var total = 0;
      // get the package currency rate of package currency vs local currency...
      item.localCurencyRate = getLocalCurrnencyRate(item);

      if (item.slot_lodging_details && item.slot_lodging_details.length) {
        // total=total+item.slot_adult_price+item.slot_child_price;
        item.total_guests = 0;
        item.slot_lodging_details.map(function (sl) {
          total += getChargedPrivatePrice(sl, item);
          item.total_guests += sl.quantity;
        });
      } else {
        total = total + item.slot_adult_price;
      }
      item.addons_details.map(function (addon) {
        total = total + addon.package_addon_adult_price + addon.package_addon_child_price;
      });
      if (item.is_promo_code === 1) {
        item.total = item.is_genric === 0 ? total - item.discount_amount : total;
      } else {
        item.total = total;
      }
      item.base_amount = item.total;
      // check if user is paying partially...
      if (
        $rootScope.CartDetails
        &&
        item.package.is_partial === 1
        &&
        $rootScope.CartDetails
        &&
        $rootScope.CartDetails[key]
        &&
        $rootScope.CartDetails[key].is_final_payment === CONST_HELPER.payment_const.partial_payment
      ) {
        switch (String(item.package.deposit_type)) {
          case CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE: // IF DEPOSIT TYPE IS PERCENTAGE
            if (item.is_promo_code === 1 && item.is_genric === 0) {
              item.package.paying_partial_amount = (item.total) * item.package.amount_percentage / 100;
            } else {
              item.package.paying_partial_amount = (item.total) * item.package.amount_percentage / 100;
            }
            break;
          case CONST_HELPER.DEPOSIT_TYPE.AMOUNT: // IF DEPOSIT TYPE IS AMOUNT
            /**
             * When an operator creates a package with an Amount deposit instead of a percentage, then it should work according to the following:
             * - By Seat Packages: The flat rate should be charged per guest
             * - By Accommodation: The flat rate should be charged per room
             * - Flat Rate Package: The flat rate should be charged once for the entire package
             *
             * It charges the flat deposit amount according to the above rules for multiday packages, single day, and nightly or daily rates.
             */
            item.package.paying_partial_amount = item.package.amount_percentage;

            switch (item.package.package_case) {
              case CONST_HELPER.PACKAGE_CASE.PER_SEAT:
                // Multiply by the number of guests
                var guestsCount = 0;
                if (
                  (item.package.lodging_status == 1)
                  &&
                  (item.slot_lodging_details.length > 0)
                ) {
                  guestsCount = item.total_guests;
                } else {
                  guestsCount = item.slot_adult_quantity;
                }
                item.package.paying_partial_amount *= guestsCount;

                break;

              case CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION:
                // Multiply by the number of units
                var unitsCount = 0;
                for (var i = 0; i < item.slot_lodging_details.length; ++i) {
                  unitsCount += item.slot_lodging_details[i].quantity;
                }
                item.package.paying_partial_amount *= unitsCount;

                break;
            }

            item.package.deposit_amount = angular.copy(item.package.amount_percentage);
            item.package.local_deposit_amount = item.package.deposit_amount * item.localCurencyRate;
            if (item.is_promo_code === 1 && item.is_genric === 0) {
              item.package.amount_percentage = (item.package.local_deposit_amount * 100) / item.base_amount;
            } else {
              item.package.amount_percentage = (item.package.local_deposit_amount * 100) / item.total;
            }

            break;
        }
      }
      if (item.is_promo_code === 1) { // if coupon applied
        $scope.discountedTotal = $rootScope.CartDetails[key].is_final_payment === CONST_HELPER.payment_const.partial_payment ? $scope.discountedTotal + (item.total) * item.package.amount_percentage / 100 : $scope.discountedTotal + total - item.discount_amount;
        item.previousTotal = item.is_genric === 0 ? $rootScope.CartDetails[key].is_final_payment === CONST_HELPER.payment_const.partial_payment ? total * item.package.amount_percentage / 100 : total : 0;
        $scope.couponDiscount = $rootScope.CartDetails[key].is_final_payment === CONST_HELPER.payment_const.partial_payment ? $scope.couponDiscount + CONST_HELPER.ZERO : $scope.couponDiscount + item.discount_amount; // excluding promocode discount when user pay initial deposit
        $scope.CartTotal += $rootScope.CartDetails[key].is_final_payment === CONST_HELPER.payment_const.partial_payment ? item.package.paying_partial_amount : total;
      } else { // else not applied
        $scope.CartTotal += $rootScope.CartDetails[key].is_final_payment === CONST_HELPER.payment_const.partial_payment ? item.package.paying_partial_amount : total;
        $scope.discountedTotal = $rootScope.CartDetails[key].is_final_payment === CONST_HELPER.payment_const.partial_payment ? $scope.discountedTotal + (item.total - item.discount_amount) * item.package.amount_percentage / 100 : $scope.discountedTotal + total;
      }
      // if nightly discount is applied
      if (item.nightly_discount) {
        $scope.totalStayDiscount = $scope.totalStayDiscount + item.nightly_discount;
      }
    });
    if (cart.cart_details) {
      $scope.GrandTotal = cart.cart_details.total_amount;
    }
    // We take tax amount from server
    //$scope.taxAmount = getTaxAmount(cart);
    $scope.processingFee = $cookies.get('cartProcessingAmount');
    claculateProcessingFee();
    checkSingleOperator(cart);
  }

  //  function to get the tax amount in case cookies get removed...
  function getTaxAmount (cart) {
    cart.cart_slot_details.map(function (item, key) {
      var taxPercent = is_apply_domestic_tax(item.operator_tax_cloud);
      // We take tax amount from server
      //vm.taxAmount = ($scope.CartTotal - $scope.couponDiscount - $scope.totalStayDiscount) * taxPercent / CONST_HELPER.HUNDRED;
    });
    return vm.taxAmount;
  }

  // get domestic or international price.
  function is_apply_domestic_tax (operatorTax) {
    if (!operatorTax) { return 0; }
    if (!$rootScope.userLocalCountry && $cookies.get('user_country_id')) {
      $rootScope.userLocalCountry = JSON.parse($cookies.get('user_country_id'))
    }
    if (operatorTax.country_code == $rootScope.userLocalCountry) {
      return operatorTax.domestic_percent;		// apply domestictax
    } else {
      return operatorTax.international_percent; // apply internation tax.
    }
  }
  // get the private price whether user is charged extra or not.
  function getChargedPrivatePrice (sl, item) {
    if (sl.nightly_slot) {
      sl.slot_lodging = sl.nightly_slot;
    }
    // calcualte each day price for nightly booking...
    if (sl.nightly_slot && sl.nightly_slot.calendar) {
      sl.slot_lodging.calendar = JSON.parse(sl.slot_lodging.calendar);
      var total = 0;

      item.package_slots.from_date = moment($filter('changeDateObject')(item.package_slots.from_date)).format('YYYY-MM-DD');
      item.package_slots.to_date = moment($filter('changeDateObject')(item.package_slots.to_date)).format('YYYY-MM-DD');

      if (sl.booking_lodging_rooms && sl.booking_lodging_rooms.length) {
        sl.booking_lodging_rooms.map(function (br) {
          sl.slot_lodging.calendar.map(function (eachDates) {
            var slot_eachDate = moment(eachDates.date);
            if (
              (
                slot_eachDate.isSameOrAfter(item.package_slots.from_date) &&
                slot_eachDate.isSameOrBefore(item.package_slots.to_date) &&
                item.package.daily_rate_activity == 1
              )
              ||
              (
                slot_eachDate.isSameOrAfter(item.package_slots.from_date) &&
                slot_eachDate.isBefore(item.package_slots.to_date) &&
                item.package.daily_rate_activity != 1
              )
            ){
              var roomObj = JSON.parse(br.rooms_guest);
              if (roomObj.type === 'private' && roomObj.guest <= sl.slot_lodging.lodging.min_private_guests) {
                total += roomObj.guest * eachDates.private_price; 	// total price including private price...
              } else {
                total += (roomObj.guest || roomObj) * eachDates.price; 	// total price not including private price...
              }
            }
          });
        });
        return total * item.localCurencyRate;
      } else { // case B
        var total = CONST_HELPER.ZERO;
        sl.slot_lodging.calendar.map(function (eachDates) {
          var slot_eachDate = moment(eachDates.date);
          // if (slot_eachDate.isSameOrAfter(item.package_slots.from_date) && slot_eachDate.isSameOrBefore(item.package_slots.to_date)) {
          if (
            (
              slot_eachDate.isSameOrAfter(item.package_slots.from_date) &&
              slot_eachDate.isBefore(item.package_slots.to_date) &&
              item.package.daily_rate_activity != 1
            )
            ||
            (
              slot_eachDate.isSameOrAfter(item.package_slots.from_date) &&
              slot_eachDate.isSameOrBefore(item.package_slots.to_date) &&
              item.package.daily_rate_activity == 1
            )
            ||
            (
              slot_eachDate.isSameOrAfter(item.package_slots.from_date) &&
              slot_eachDate.isSameOrBefore(item.package_slots.to_date) &&
              moment(item.package_slots.from_date).isSame(item.package_slots.to_date)
            )
          ) {
            total += (sl.quantity * eachDates.price);
          }
        });
        return total * item.localCurencyRate;
      }
    } else {
      if (sl.is_private && sl.booking_lodging_rooms.length) {
        var privatePrice = 0;
        sl.booking_lodging_rooms.map(function (br) {
          var roomObj = JSON.parse(br.rooms_guest);
          if (roomObj.type === 'private' && roomObj.guest <= sl.slot_lodging.lodging.min_private_guests) {
            privatePrice += roomObj.guest * sl.slot_lodging.private_price_local; 	// total price including private price...
          } else {
            privatePrice += (roomObj.guest * sl.slot_lodging.price_local); 	// total price not including private price...
          }
        });
        return privatePrice;
      } else {
        return (sl.quantity * sl.slot_lodging.price_local); 	// total price not including private price...
      }
    }
  }

  // function to return the package currency rate of package currency vs local currency...
  function getLocalCurrnencyRate (slot) {
    if (slot.slot_lodging_details.length) {
      var slotLodging = slot.slot_lodging_details[0].nightly_slot ? slot.slot_lodging_details[0].nightly_slot : slot.slot_lodging_details[0].slot_lodging;
      return slotLodging.price_local / slotLodging.price;
    } else if (slot.nightly_slot) {
      return slot.nightly_slot.price_local / slot.nightly_slot.price;
    } else {
      return slot.package_slots.adult_price_local / slot.package_slots.adult_price;
    }
  }

  //  function to check if cart has only one operator package...
  function checkSingleOperator (cart) {
    $scope.hasSingleOperator = 1;
    var previousSlotOperatorId = cart.cart_slot_details[0].operator_profile.user_id; // set initial slot operator id.
    var hasSingleOperatorSlots = cart.cart_slot_details.filter(function (sl) { return sl.operator_profile.user_id !== previousSlotOperatorId; });
    $scope.hasSingleOperator = hasSingleOperatorSlots.length ? 0 : 1;

    var operatorsHavingOtherBank = cart.cart_slot_details.filter(function (sl) { return !((sl.operator_profile.bank_country_code === 'US' && sl.operator_profile.stripe_account_status === 'verified') || !sl.operator_profile.bank_country_code); });
    $scope.OperatorHasUsBank = !operatorsHavingOtherBank.length;

    $scope.finalOperatorAmount = 0;
    if ($scope.hasSingleOperator === 1) { // if all slot of single operator then calculate final payable amount to operator
      $scope.operatorTotalCommission = 0;
      var operatorBankAccountCurrencyCode = '';
      cart.cart_slot_details.map(function (sl) {
        if (sl.package.paying_partial_amount) {
          operatorBankAccountCurrencyCode = sl.package_currency_code || sl.operator_profile.default_account_currency_code; // get the operator bank account currency code...
          return;
        } // if user is paying partialy then do not take heli commission from deposit
        var finalTotal = sl.total - sl.discount_amount - sl.nightly_discount; // final total after excluding discount amount...
        var totalPriceAddons = 0;
        sl.addons_details.map(function (addon) {
          totalPriceAddons = totalPriceAddons + addon.package_addon_adult_price;
        });
        if ($rootScope.siteInIFrame || $rootScope.isSubDomain || $scope.isManualBooking) {
          var operatorPercent = sl.operator_commission.on_site_commission;
          $scope.operatorTotalCommission += (finalTotal - totalPriceAddons) > 0 ? ((finalTotal - totalPriceAddons) * operatorPercent / 100) : 0;
        } else {
          var operatorPercent = 0;
          if (sl.package_slots && sl.package_slot) {
            if (typeof sl.package_slots.commission === 'undefined') {
              operatorPercent = sl.package_slot.commission;
            } else {
              operatorPercent = sl.package_slots.commission;
            }
          } else {
            operatorPercent = sl.operator_commission.amount;
          }
          $scope.operatorTotalCommission += (finalTotal - totalPriceAddons) > 0 ? ((finalTotal - totalPriceAddons) * operatorPercent / 100) : 0;
        }

        operatorBankAccountCurrencyCode = sl.package_currency_code || sl.operator_profile.default_account_currency_code; // get the operator bank account currency code...
      });
      $scope.finalOperatorAmount = $scope.GrandTotal - $scope.operatorTotalCommission - $scope.processingFee;
      // get operator Bank Account Currency Code
      // $scope.getSlotUsdRate(operatorBankAccountCurrencyCode, $rootScope.localCurrencyCode, function (currencyRate) {
      //   $scope.finalConnectOperatorAmount = $scope.finalOperatorAmount && currencyRate ? $scope.finalOperatorAmount / currencyRate : 0;
      // });
      $scope.finalConnectOperatorAmount = ($scope.finalOperatorAmount && $scope.finalOperatorAmount > 0) ? $scope.finalOperatorAmount : 0;
    }
  }

  $rootScope.changeBankFee =  function () {
    claculateProcessingFee()
  };

  // calculate the processing charge....
  function claculateProcessingFee (cartAmount) {
    var charge = 0;
    var fixed = 0;
    var processingTotal = 0;
    var cartTotal = cartAmount || $scope.CartTotal -  ($scope.myCartItems.cart.items[0]?.is_deposit ? 0 : $scope.myCartItems.cart.items[0].booking.credit_amount) - $scope.couponDiscount - $scope.totalStayDiscount;
    // var cart = $rootScope.myCartItems
    if ($scope.tabType === 'bank') {
      if(($scope.isFlywirePaymentAvailable || $cookies.get('isFlywirePaymentAvailable')) && $rootScope.selectCountryTransfer && $rootScope.selectCountryTransfer.flywire == 1 || $rootScope.paymentSystemsFlywireIsAvailable && !$rootScope.paymentSystemsPlaidIsAvailable){
        bankPercent = 0;
      }else{
        bankPercent = 0.01;
      }
      charge = bankPercent;
      processingTotal = cartTotal > 0 ? (cartTotal + parseFloat($scope.taxAmount, 10)) * charge : 0;
      $scope.processingFee = Math.min(processingTotal.toFixed(2));
    } else {
      charge = cardPercent;
      $scope.processingFee = (cartTotal + parseFloat($scope.taxAmount, 10)) * charge;
    }
    $scope.GrandTotal = $scope.CartTotal - $scope.couponDiscount - $scope.totalStayDiscount + parseFloat($scope.taxAmount, 10) + $scope.processingFee;

    $rootScope.CartDetails.map(function (slot) {
      if ($scope.tabType === 'bank') {
        charge = bankPercent;
      } else {
        charge = cardPercent;
      }
      returnProcessingFee(slot, charge, fixed);
    });
  }
  function returnProcessingFee (slot, charge, fixed) {
    if (slot.local_slot_amount > 0) { // check for full discount...
      var processingTotal = $scope.tabType === 'bank' ? (slot.local_slot_amount + parseFloat(slot.local_tax_amount, 10) + fixed) * charge : (slot.local_slot_amount + parseFloat(slot.local_tax_amount, 10) + fixed) * charge;
    } else {
      // eslint-disable-next-line no-redeclare
      var processingTotal = 0;
    }
    slot.local_processing_fee = $scope.tabType === 'bank' ? Math.min(processingTotal.toFixed(2), maxBankProcessingFee) : Number(processingTotal).toFixed(2);
    slot.processing_fee = Number((slot.local_processing_fee / $rootScope.usdRate).toFixed(4));

    var slotObj = $scope.myCartItems.cart_slot_details.filter(function (item) {
      return item.id === slot.slot_id;
    });
    if (slotObj.length) {
      slotObj = slotObj[0];
      var slot_total = slotObj.base_amount - slotObj.discount_amount - slotObj.nightly_discount + parseFloat(slot.local_total_tax, 10);
      if (slot_total > 0) {
        processingTotal = (slot_total) * charge;
      } else {
        processingTotal = 0;
      }
      slot.local_total_processing_fee = $scope.tabType === 'bank' ? Math.min(processingTotal.toFixed(2), maxBankProcessingFee) : Number((processingTotal).toFixed(2));
      slot.total_processing_fee = Number((slot.local_total_processing_fee / $rootScope.usdRate).toFixed(2));
    }
  }

  $scope.removeCoupon = function () {
    $scope.showLoader();
    $scope.discountedTotal = 0;
    $scope.myCartItems.cart_slot_details.map(function (item, key) {
      $scope.discountedTotal = $rootScope.CartDetails[key].is_final_payment === CONST_HELPER.payment_const.partial_payment ? $scope.discountedTotal + (item.total) * item.package.amount_percentage / 100 : $scope.discountedTotal + item.total;
    });
    // $scope.discountedTotal=$scope.discountedTotal+$scope.couponDiscount;
    // We take tax amount from server
    //$scope.taxAmount = $scope.discountedTotal * $scope.taxPercent / 100;
    claculateProcessingFee();
    var data = {
      'promo_code_id': coupon_id,
      'cart_id': cart_id,
      'base_amount': $scope.CartTotal,
      'total_amount': $scope.discountedTotal + $scope.taxAmount + $scope.processingFee
    };
    userAdventureDetailService.removeCoupon(data, token).then(function (res) {
      if (res.data.message === 'Success') {
        $scope.couponDiscount = 0;
        $scope.couponApplied = false;
        coupon_id = '';
        getCartList('updateTotalAmount');
        $cookies.put('couponApplied', '');
        $cookies.put('coupon_id', '');
      }
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };
  function updateSlotTotalAmount () {
    var data = {
      cart_id: cart_id,
      total_amount: $scope.GrandTotal,
      slot_detail: getSlotDetails($scope.myCartItems.cart_slot_details)
    };
    userAdventureDetailService.updateTotalAmount(data, token);
  }
  function getSlotDetails (slot) {
    var arr = [];
    slot.map(function (slotdata) {
      var obj = {
        id: slotdata.id,
        total_amount: slotdata.total + (slotdata.total * $scope.taxPercent / 100)
      };
      arr.push(obj);
    });
    return arr;
  }
  // show addons popup...
  $scope.showAddons = function (addons) {
    $scope.currnetselectedAddons = addons;
  };

  $scope.proceedOrChange = function () {
    if ($scope.userWantToProceed) {
      $scope.userWantToProceed = false;
    } else {
      $rootScope.$broadcast('card-saved');// get cards list
      // Enhance Ecommerce data
      // google analytics code for set checkout option...
      var GCdata = {
        items: $scope.myCartItems.cart_slot_details,
        event_type: 'ProceedToPayment',
        checkout_step: 2
      };
      $rootScope.$broadcast('addEventToEC', GCdata); // add event to google Analytics.
    }
  };
  $scope.payClicked = function () {
    $scope.disableButton = true;
  };

  var popupOpen = false; var seatRealeased = false;
  function checkoutTimeout () {
    vm.value = 0;
    vm.label = '00:00';
    $cookies.put('couponApplied', '');
    $scope.showPopup('#session-timeout');
    popupOpen = true;
    releaseSeats();
  }
  vm.backToCart = function () {
    if (popupOpen) {
      $scope.hidePopup('#session-timeout');
    }
    $state.go('mycart');
  };
  function releaseSeats () {
    $timeout.cancel(timeout);
    $scope.showLoader();
    $rootScope.releasingSeats = true;
    var cartData = {
      'promo_code_id': coupon_id,
      'cart_id': cart_id
    };
    userAdventureDetailService.releaseSeats(cartData, token).then(function (res) {
      if (res.data && res.data.message === 'Success') {
        $scope.hideLoader();
        seatRealeased = true;
        $rootScope.releasingSeats = false;
        $cookies.put('canCheckout', 0);
      }
    }, function () {

    });
  }
  $rootScope.$on('$stateChangeSuccess', function (to, from) {
    if ($rootScope.currentState.name !== 'link-expire' && $rootScope.previousState.name === 'secure-checkout' && $rootScope.currentState.name !== 'order-successful') {
      if (!seatRealeased && !$rootScope.doNotReleaseSeat) {
        releaseSeats();
      }
    }
    if ($rootScope.currentState.name !== 'secure-checkout') {
      if ($cookies.get('canCheckout')) {
        $cookies.remove('canCheckout');// remove order details from cookie
      }
    }
  });
  if (!$rootScope.isSubDomain) {
    window.onbeforeunload = function (e) {
      if ($rootScope.currentState.name === 'secure-checkout') {
        if (e) {
          e.returnValue = 'want to close?';
        }
        // For Safari
        // return 'Any string';
      }
    };
  }
  window.onunload = function () {
    $cookies.put('cartDetails', JSON.stringify($rootScope.CartDetails));
    $cookies.put('packageDetail', JSON.stringify($rootScope.packageDetail));
    $cookies.put('cartTaxAmount', $scope.taxAmount);
    $cookies.put('cartProcessingAmount', $scope.processingFee);
    $cookies.put('usdRate', $rootScope.usdRate);
    $cookies.put('selectedCurrencyCode', $rootScope.selectedCurrencyCode);
  };
}
