import { Component, Input, OnInit, Inject } from '@angular/core';
import { LoadAdventuresService } from '../../../services/load-adventures/load-adventures.service';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';

@Component({
  selector: 'app-adventure-card',
  templateUrl: '/ng-app/src/app/components/activity-categories/adventure-card/adventure-card.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/activity-categories/adventure-card/adventure-card.component.css']
})
export class AdventureCardComponent implements OnInit {

  // adventure: any;
  @Input() destinationPage: boolean = false;
  @Input() destinationAdventureLink: boolean = false;
  @Input() adventure: any;

  currentPage: number = 1;

  constructor(
    @Inject("$scope") private scopeJS: any,
    @Inject(UIRouter) public router: UIRouter,
    public routerGlobals: UIRouterGlobals,
    public loadAdventures: LoadAdventuresService
  ) {
  }

  ngOnInit(): void {
  }

  viewAllSlots(event, adventure) {
    this.adventure = adventure;
    this.scopeJS.showPopup('#slot');
    event.stopPropagation();
  };

  getSeoFriendlyUrl(str) {
    return str?.replace(/\W+(?!$)/g, '-').toLowerCase();
  }

  setLocationLen(locationName) {
    let location = Array.isArray(locationName) ? locationName[0].split(',') : locationName.split(',');
    return location[location.length - 1];
  }
}
