/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:datepicker
 * @description
 * # datepicker
 */
angular.module('heliApp')
  .directive('datepicker', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('click', function () {
          var $input = $(element).find('.date-input');// attach datepicker on the inputs
          $input.datepicker({
            format: 'yyyy-mm-dd', // date  format
            todayHighlight: true, // highlight today
            autoclose: true,
            orientation: 'auto bottom'// open at bottom
          });
          $input.focus();// open the datepicker
        });
      }
    };
  })
  .directive('checksports', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('click', function () {
          if (attrs.checked === false) {
            var $input = $(element).parents('.sports-class').find('.sports-categ');
          } else {
          }
        });
      }
    };
  })
  .directive('singleDatePicker', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('click', function (e) {
          console.log('click', e.currentTarget.value);

        // $input.focus();//open the datepicker
        });
      }
    };
  });
