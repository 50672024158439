import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-banner',
  templateUrl: '/ng-app/src/app/components/activity-categories/activity-banner/activity-banner.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/activity-categories/activity-banner/activity-banner.component.css']
})
export class ActivityBannerComponent implements OnInit {

  @Input() activityData: any = {};

  constructor() { }

  ngOnInit(): void { }

}
