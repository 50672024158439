/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorSignInCtrl
 * @description
 * # operatorSignInCtrl
 * Controller of the heliApp
 */

angular.module('heliApp').controller('operatorSignInCtrl',
  [
    '$scope',
    '$state',
    '$location',
    'operatorService',
    '$stateParams',
    'userLoginService',
    '$cookies',
    function ($scope, $state, $location, operatorService, $stateParams, userLoginService, $cookies) {
      $scope.user = {}; // for operator detail
      $scope.userPassword = {}; // for set password detail
      $scope.validation = {}; // for form validation
      $scope.confirmPasswordErr = false; // set error status
      $scope.showSignInForm = false; // show set operator signin form
      $scope.showSetPassForm = false; // hide set password form
      $scope.passwordErr = false;// set error status
      $scope.emailErr = false;// set error status
      $scope.registerErr = false;// set error status
      $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;

      var token = $stateParams.signup_token; // set token in variable

      $scope.getValidTokenCheck = function () { // check token for operator expire or not
        $scope.showLoader();// show loader
        var requestObject = { 'url_token': token };// set data field
        operatorService.checkValidToken(requestObject).then(function (response) { // call api for token check
          if (response.data.code === '200') { // if success
            $scope.showSignInForm = true; // show sign in page
            $scope.hideLoader();// hide loader
          } else {
            $scope.hideLoader();// hide loader
            if ($location.path() !== '/home') {
              $state.go('link-expire'); // show link expire page
            }
          }
        });
      };
      if ($location.path() === '/operator-signin') {
        $scope.getValidTokenCheck();
      }

      $scope.userLogin = function () { // signin operator function
        if (!$scope.userLoginForm.$valid) { // check form is valid or not
          $scope.emailErr = false; // set error status
          var invalidFields = $scope.userLoginForm.$error.required || $scope.userLoginForm.$error.pattern || $scope.userLoginForm.$error.email;
          for (var i = 0; i < invalidFields.length; i++) { // add flag for invalid field's and show error
            $scope.validation[invalidFields[i].$name] = true; // if field is required set them required true
          }
          return false;
        }

        var requestObject = { // set requestObject field
          'email': $scope.user.email,
          'password': $scope.user.defaultPassword
        };
        $scope.showLoader();// show loader
        operatorService.loginOperator(requestObject).then(function (response) { // call api for operator login
          if (response.data) {
            if (response.data.code !== '200') {
              $scope.registerErr = true;// set error status
              if (response.data.message.email) {
                $scope.emailErrMessage = response.data.message.email.email;// set email error message
                $scope.emailErr = true;// set error status
              }

              if (response.data.message.password) {
                $scope.passErrMessage = response.data.message.password.password; // set pasword error message
                $scope.passwordErr = true; // set error status
              }
              $scope.hideLoader();// hide loader
            } else if (response.data.data.status_id === 4) { // check status id for operator
              if ($scope.$parent.user.token) {
                $scope.logoutotherUser();// logout all other users
              }
              $scope.registerErr = false;// set error status

              $scope.setUserDetails(response.data.data);// set user details in the rootScope;
              $scope.user = response.data.data;// set user data into local scope;
              $scope.showSignInForm = false; // hide signin operator form
              $scope.showSetPassForm = true; // show set password form
              $scope.hideLoader();// hide loader
            }
          }
        }, function () { // check for error
          $scope.hideLoader();// hide loader
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.changePassword = function (form) {
        if (form.password.$pristine) { // check for required validaiton of the password
          $scope.passwordErr = true;
        } else {
          $scope.passwordErr = false;
        }
        if (form.confirmPassword.$pristine) { // check for required validaiton of the confirm password
          $scope.confirmPasswordErr = true;
        } else {
          $scope.confirmPasswordErr = false;
        }
        if (!$scope.confirmPasswordErr && !$scope.passwordErr && form.$valid) { // check if form is valid or not
          var requestObject = {// set requestObject field
            'password': $scope.userPassword.password,
            'confirm_password': $scope.userPassword.confirmPassword,
            'token': $scope.user.token
          };
          $scope.showLoader();// show loader
          operatorService.setOperatorPassword(requestObject).then(function (response) { // post to the server the form data
            if (response && response.data) {
              if (response.data.code !== '200') { // some error exits
                $scope.registerErr = true;// set error status
                // $scope.registerErrMessage = Object.values(response.data.message[Object.keys(response.data.message)[0]])[0];// set message
                for (var key in response.data.message[Object.keys(response.data.message)]) {
                  $scope.registerErrMessage = response.data.message[Object.keys(response.data.message)][key];
                }
                var errType = Object.keys(response.data.message)[0];
                if (errType === 'password') { // its password error
                  $scope.passwordErr = true;// set error status
                }
                $scope.hideLoader();// hide loader
              } else { // if  registeration is successful
                $scope.hideLoader();// hide loader
                $scope.registerErr = false;// set error status
                $scope.setUserDetails(response.data.data, 'second');// set user details in the rootScope;
                $scope.user = response.data.data;// set user data into local scope;
                $state.go('operator-get-started'); // redirect to home
              }
              $scope.hideLoader();// hide loader
            } else {
              $scope.hideLoader();// hide loader
              $scope.showPopup('#serverErrModal');
            }
          }, function () { // check for error
            $scope.hideLoader();// hide loader
            $scope.showPopup('#serverErrModal');
          });
        }
      };

      $scope.logoutotherUser = function () {
        userLoginService.logOutUser($scope.$parent.user.token).then(function (response) {
          var cookies = $cookies.getAll();
          //   var couponData = {
          //     promo_code_id: $cookies.get('coupon_id'),
          //     cart_id: $cookies.get('cart_id'),
          //     previousPrice: 1234 // static cart total.
          //   }
          angular.forEach(cookies, function (v, k) {
            $cookies.remove(k);
          });// clear local storage
        });
      };
    }
  ]
);
