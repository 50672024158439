import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adventure-menu-card',
  templateUrl: '/ng-app/src/app/components/header/navbar/adventure-menu-card/adventure-menu-card.component.html',
  styleUrls: ['../../../../../../../ng-app/src/app/components/header/navbar/adventure-menu-card/adventure-menu-card.component.css']
})
export class AdventureMenuCardComponent implements OnInit {

  @Input() adventure: any = {};

  constructor() { }

  ngOnInit(): void {
  }

}
