import { Component, Input, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../app/scripts/environment';

@Component({
  selector: 'app-activity-stories-banner',
  templateUrl: '/ng-app/src/app/components/activity-categories/activity-stories-banner/activity-stories-banner.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/activity-categories/activity-stories-banner/activity-stories-banner.component.css']
})
export class ActivityStoriesBannerComponent implements OnInit {

  @Input() selectedDestinationStories: any;
  @Input() selectedActivity: any = {};
  @Input() categoryId?: any;
  @Input() destinationCategory: boolean = false;

  categoryStoriesData:any = [];
  requestNameActivity: string;

  constructor(
    @Inject(HttpClient) private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    if (this.selectedDestinationStories) {
      this.setDestinationStoriesData(this.selectedDestinationStories)
    } else {
      this.getCategoryStories();
    }
  }

  setDestinationStoriesData(destinationStories) {
    destinationStories.forEach(story => {
      this.categoryStoriesData.push({
        story_img: story.img_src,
        sport_categories: story.tags,
        title: story.title,
        date: story.date,
        description: story.part_description,
        href: story.href
      })
    });
  }

  getStoryDate(data) {
    let date;
    date = new Date(data.substring(0, data.indexOf(' ')));
    let day = date.getDate();
    let month = date.toLocaleString('default', { month: 'long' });
    let year = date.getFullYear();
    return `${month} ${day}, ${year}`
  }

  getCategoryStories() {
    this.httpClient.get(`${environment.REQUEST_URL}package-sports/get-package-sport-stories/${this.categoryId}`).subscribe((response:any = []) => {
      response.data.forEach(element => {
        element['date'] = this.getStoryDate(element['date']);
      });
      this.setDestinationStoriesData(response.data);
    }, err => {
      console.log(err.message);
    })
  }
}
