/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorListingCtrl
 * @description
 * # operatorListingCtrl
 * Controller of the heliApp
 */

angular.module('heliApp').controller(
  'operatorListingCtrl',
  [
    '$scope',
    '$state',
    '$rootScope',
    'sharedService',
    'userSocialProfileService',
    '$timeout',
    '$stateParams',
    '$location',
    '$window',
    'CONST_HELPER',
    function ($scope, $state, $rootScope, sharedService, userSocialProfileService, $timeout, $stateParams, $location, $window, CONST_HELPER) {
      $scope.searchText = {};
      $scope.searchText.text = '';
      $scope.operatorList = [];
      $scope.noRecordFound = false;
      $scope.isSearchTextFocused = false;
      // $scope.isLoadMoreButton = true; //default load more button will be shown
      var listLimit = CONST_HELPER.page_limit;
      // if($rootScope.previousState && $rootScope.previousState.name==="myprofile"){
      //       $state.params.back= $state.params.page>1 ?1:0;
      //       $location.search($state.params);
      //   }
      // var back = $state.params.back ? $state.params.back : 0 || $state.params.page>1 ?1:0
      var back = $state.params.page > 1 ? 1 : 0;
      var getListCalled = false;
      $scope.getoperatorList = function (query) { // get operator list for displaing operator
        $scope.showLoader();
        getListCalled = true;
        $scope.searchText.text = $state.params.search; // display text in search box
        if (query === undefined) {
        //   var search_param = ($state.params.search) ? $state.params.search : ''
          if ($state.params.page === 1) {
            $scope.operatorList = [];
          }
          // var key_name = 'back-'+(Number($state.params.back) + 1)+'page-'+(Number($state.params.page) + 1)+'search-'+search_param;
          // if(!$rootScope.operator_scrollbarId){
          //   $rootScope.operator_scrollbarId = {};
          // }
          // $rootScope.operator_scrollbarId[key_name] = '#operator'+$scope.operatorList[$scope.operatorList.length - 1].id;
          // to handle the state of each page in case of load more
          // $state.go("operators-listing",{back : Number($state.params.back) + 1, page : Number($state.params.page) + 1, search : $state.params.search})
          // return false;
        } else {
          if (query !== $state.params.search && query) { // to handle search text functionality
            $scope.operatorList = [];
            $state.params.search = query;
            $state.params.page = 1;
            $state.params.back = 0;
            $location.search($state.params); // update the url is sorting is applied
            // $state.go("operators-listing",{back : 0, page : 1, search : query})
            // return false;
          } else {
            $state.params.page = 1;
            $location.search($state.params);
          }
        }

        var requestObject = {

          'page': $state.params.page,
          'search': ($state.params.search) ? $state.params.search : '',
          'back': back,
          'limit': listLimit
          // "search":  tempSearchValue ?  tempSearchValue : query || '',
        };
        userSocialProfileService.getOperatorListing(requestObject, $scope.user.token).then(function (response) { // call opearot listing api
          if (response.data.code === '200') {
            $scope.noRecordFound = false;
            $scope.operatorList = $scope.operatorList.concat(response.data.data);
            $scope.totalOperators = response.data.count;
            // more operator Shown on operator list Page
            $scope.isLoadMoreButton = ((response.data.count - $scope.operatorList.length) > 0);
            $scope.resCount = response.data.count;
            // $timeout(function(){
            // var search_param = ($state.params.search) ? $state.params.search : ''
            // var key_name = 'back-'+$state.params.back+'page-'+$state.params.page+'search-'+search_param;
            // if($rootScope.operator_scrollbarId){
            //  if(angular.element($rootScope.operator_scrollbarId[key_name]).length){
            //    angular.element("html, body").animate({ scrollTop: angular.element($rootScope.operator_scrollbarId[key_name]).offset().top }, 1000);
            //  }
            // }
            // },100)

            $timeout(function () { // scroll to previous position.
              if ($rootScope.operatorOffset > 500 && $rootScope.previousState && ($rootScope.previousState.name === 'myprofile' || $rootScope.previousState.name === 'signin')) {
                angular.element('html, body').animate({ scrollTop: $rootScope.operatorOffset }, 1000);
              }
            }, 10);
            $scope.hideLoader();
          } else if (response.data.code === '404') {
            $scope.isLoadMoreButton = false;
            $scope.operatorList = [];
            $scope.totalOperators = 0;
            $scope.noRecordFound = true;
            $scope.hideLoader();
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };
      $timeout(function () {
        if (!getListCalled) {
          // $scope.getoperatorList();
        }
      }, 500);
      // $scope.getoperatorList();
      $scope.searchOperator = function (query) {
        // if(query.length >= 2) {
        //  $scope.getoperatorList(query);
        //  $scope.operatorList = [];
        // }
        if (query.length >= 2) {
          $scope.operatorList = [];
          $state.params.back = 0;
          $state.params.search = query; // display text in search box
          $location.search($state.params); // update the url is sorting is applied
        }
      };

      $scope.followOperator = function (userId, index, followUser) { // to follow operator
        $scope.showLoader();
        var data = {
          'follower_id': userId,
          'token': $scope.user.token
        };
        if (followUser) { // user wants to follow influencer
          sharedService.followUser(data).then(function (res) {
            switch (res.data.code) {
              case '200' :
                $scope.operatorList[index].is_follow = followUser;
                break;
              default :
                $scope.hideLoader();
                $scope.showPopup('#serverErrModal');
                break;
            }
            $scope.hideLoader();
          }, function () {
            $scope.hideLoader(); $scope.showPopup('#serverErrModal');
          });
        } else { // user wants to unfollow influencer
          sharedService.unFollowUser(data).then(function (res) {
            switch (res.data.code) {
              case '200' :
                $scope.operatorList[index].is_follow = followUser;
                break;
              default :
                $scope.hideLoader();
                $scope.showPopup('#serverErrModal');
                break;
            }
            $scope.hideLoader();
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }
      };

      $scope.resetListing = function () { // reset search input box
        if ($state.params.search) {
          back = 0;
          $state.params.page = 1;
          $state.params.search = ''; // display text in search box
          $location.search($state.params); // update the url is sorting is applied
          // $state.go('operators-listing',{back : 0, page : 1, search : ''});
        }
      };

      $scope.loadMore = function () {
        back = 0;
        $state.params.page = Number($state.params.page) + 1;
        // eslint-disable-next-line no-self-assign
        $state.params.search = $state.params.search; // display text in search box
        $location.search($state.params); // update the url is sorting is applied
      };

      $rootScope.pageChanged = function (data) {
        if (data === 'pageIncreased') {
          back = 0;
          $scope.getoperatorList();
        } else if (data === 'pageDecreased') {
          $scope.operatorList = $scope.operatorList.slice(0, $rootScope.currentPage * listLimit);
          $scope.isLoadMoreButton = (($scope.resCount - $scope.operatorList.length) > 0);
          if ($scope.operatorList.length === 0) {
            $scope.getoperatorList();
          }
        } else {
          back = 1;
          var query = $state.params.search;
          $scope.searchText.text = query;
          $scope.getoperatorList(query);
        }
      };
      // save window scrollPostion
      angular.element($window).bind('scroll', function (e) {
        if (this.pageYOffset > 100 && $rootScope.currentState && $rootScope.currentState.name === 'operators-listing') { $rootScope.operatorOffset = this.pageYOffset; }
      });
    }
  ]
);
