/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:OperatorPackageListCtrl
 * @description
 * # OperatorPackageListCtrl
 * Controller of the heliApp
 */
angular.module('heliApp').controller('OperatorPackageListCtrl', ['$scope', '$rootScope', '$state', 'operatorPackageListService', '$window', '$timeout', '$cookies', '$filter', 'CONST_HELPER', 'operatorCalendarService', 'userAdventureDetailService', 'agentService', function ($scope, $rootScope, $state, operatorPackageListService, $window, $timeout, $cookies, $filter, CONST_HELPER, operatorCalendarService, userAdventureDetailService, agentService) {
  var maxDate, minDate, packageId; // define variable
  $scope.checkedPackage = {}; // use for checkbox value for bulk package seletion
  $scope.bulkPackageList = []; // use for save bulk package seletion data
  $scope.packageDates = {}; // using for publish start and end date
  $scope.validation = {};
  $scope.package_slots = []; // using for package slot
  $scope.endDateCheckErr = false;
  $scope.publishEndDateErr = false;
  $scope.publishStartDateErr = false;
  $scope.maxCapacityErr = false;
  $scope.availableSeatErr = false;
  $scope.addSlot = {}; // add sloat from popup
  // Add public private filter to operator dashboard
  $scope.package = {
    packageType: CONST_HELPER.ZERO
  };
  $scope.packageFilter = ['All Adventures', 'Public Adventures', 'Private Adventures'];
  $scope.locationSource = '';
  $scope.locationSourceId = CONST_HELPER.ZERO;
  $scope.tempId = $scope.getTempId();
  $scope.locationFilter = [];
  $scope.calendar = {};
  var disableDates = [];
  var userdata = $cookies.get('user');
  if (userdata) {
    $scope.user = JSON.parse(userdata);
  }
  if ($scope.user) {
    var params = {
      status: '',
      page: 1,
      back: CONST_HELPER.ZERO,
      limit: 10000,
      token: $scope.user.token
    };
  }
  $scope.packageDropdown = '1';
  var operatorParams = $state.params.operator_id;
  if (operatorParams) {
    $scope.agentAllOperators.forEach(function (operator) {
      if (operator.id == operatorParams) {
        $scope.agentOperator = operator;
      }
    });
  } else {
    $scope.agentOperator = { name: 'All Operators', id: '' };
  }

  $timeout(function () { // for custom plugin
    $('.selectpicker-packages').selectpicker();
    $('.selectpicker').selectpicker();
  }, 10);

  $scope.getPackages = function (sts) { /* get all the packages list */
    $scope.bulkPackageList = []; // set bulk package list empty
    $scope.homeRedirection();// check for authorised access
    if ((!$scope.user) || ($scope.user && !$scope.user.token)) { // check for user logged in status
      $state.go('signin');
      return false;
    }
    $scope.showLoader(); // show loader
    $scope.packageDropdown = sts.toString();
    params.status = sts;
    $scope.packageList = [];
    $scope.canloadmore = false;
    params.page = 1;
    params.type = $scope.package.packageType;
    params.location_id = $scope.locationSourceId;
    params.operator_id = $scope.agentOperator.operator_profile ? $scope.agentOperator.operator_profile.user_id : '';
    if ($rootScope.isAgent) {
      agentService.getAgentOperatorPackages(params).then(function (response) { // fetch from the service
        if (response) {
          if (response.data.code === '401') { // unauthorised user
            $scope.logout();// unauthorised user
            $state.go('signin');
            $scope.hideLoader();
            return false;
          } else if (response.data.code === '404') { // no adventure found reset calendar event
            $scope.packageList = [];// add to scope
            $scope.hideLoader();
          } else {
            $scope.packageList = response.data.response.docs;// add to scope
            $scope.resultFound = response.data.response.numFound;
            if ($scope.resultFound > CONST_HELPER.ZERO) { // if some result are found
              $scope.canloadmore = couldLoadMore();
              $scope.checkExpiredSlots($scope.packageList); // check for expired slots.
              getPackageList(); // format publish start date and end date
            }
            $scope.hideLoader();
            $timeout(angular.noop());
          }
        }
      }, function () {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });// check for error
    } else {
      operatorPackageListService.getOperatorPackages(params).then(function (response) { // fetch from the service
        if (response) {
          if (response.data.code === '401') { // unauthorised user
            $scope.logout();// unauthorised user
            $state.go('signin');
            $scope.hideLoader();
            return false;
          } else {
            $scope.packageList = response.data.response.docs;// add to scope
            $scope.resultFound = response.data.response.numFound;
            if ($scope.resultFound > CONST_HELPER.ZERO) { // if some result are found
              $scope.canloadmore = couldLoadMore();
              $scope.checkExpiredSlots($scope.packageList); // check for expired slots.
              getPackageList(); // format publish start date and end date
            }
            $scope.hideLoader();
            $timeout(angular.noop());
          }
        }
      }, function () {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });// check for error
    }
    if (document.getElementsByClassName('modal-backdrop').length !== CONST_HELPER.ZERO) {
      document.getElementsByClassName('modal-backdrop')[CONST_HELPER.ZERO].style.visibility = 'hidden';// hide modal backdrop
    }
  };
  // location filters array

  $scope.getLocationNames = function () {
    var data = {
      token: $scope.user.token
    };
    operatorCalendarService.getLocationNames(data).then(function (response) {
      if (response.data) {
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK || response.data.code === CONST_HELPER.API_RESPONSE.NO_CONTENT) {
          $scope.locationFilter = response.data.data;
          $scope.locationFilter.push({ id: $scope.tempId });
          $scope.locationFilter.unshift({ id: CONST_HELPER.ZERO, name: 'All locations' });
          $scope.locationSource = $scope.locationFilter[CONST_HELPER.ZERO];
        }
      }
    }, function () {

    });
  };
  $scope.addLocation = function () {
    $rootScope.redirectToAddLocation = true;
    $state.go('operator-edit-profile');
  };
  $scope.getLocationNames();
  $scope.setLocationSource = function (location, select) {
    $scope.locationSource = location;
    $scope.locationSourceId = location.id;
    if ($scope.locationSourceId === $scope.tempId) {
      $scope.locationSource = $scope.locationFilter[CONST_HELPER.ZERO];
      select.selected = $scope.locationFilter[CONST_HELPER.ZERO]; // update select value.
    } else {
      // getEventsAfterLocationFilter();
      $scope.getPackages(1); // call get package list function
    }
  };
  $scope.getCalendar = function (array, bookingWindow) {
    var dateObj = { totalLodgings: array.length };
    var endDate = null;
    if (bookingWindow) {
      endDate = moment().add(bookingWindow, 'months').format('YYYY-MM-DD');
    }
    array.forEach(function (val, index) {
      if (val.calendar && val.calendar.length > CONST_HELPER.ZERO) {
        if (typeof val.calendar === 'string') { val.calendar = JSON.parse(val.calendar); }
        val.calendar.forEach(function (date) {
          var _tmpDate = date.date;
          if (date.date.split('-').length > CONST_HELPER.ONE) {
            date.date = +date.date.split('-')[CONST_HELPER.ZERO] + '/' + (+date.date.split('-')[CONST_HELPER.ONE] - CONST_HELPER.ONE) + '/' + (+date.date.split('-')[CONST_HELPER.TWO]);
          }
          if (dateObj[date.date]) {
            if ($scope.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
              dateObj[date.date].available_room = parseInt(dateObj[date.date].available_room) + parseInt(date.available_room);
              dateObj[date.date].available_seat = parseInt(dateObj[date.date].available_seat) + parseInt(date.available_seat);
            } else {
              dateObj[date.date].available_room = dateObj[date.date].available_room > date.available_room ? dateObj[date.date].available_room : date.available_room;
              dateObj[date.date].available_seat = dateObj[date.date].available_seat > date.available_seat ? dateObj[date.date].available_seat : date.available_seat;
            }
            dateObj[date.date].is_block = parseInt(dateObj[date.date].is_block ? 1 : 0) + parseInt(date.is_block);
            if (date.price <= dateObj[date.date].price) {
              dateObj[date.date].price = date.price;
            }
          } else {
            dateObj[date.date] = { price: date.price, available_seat: date.available_seat, is_block: (moment(moment(_tmpDate).format('YYYY-MM-DD')).isAfter(endDate) ? !date.forceActive : date.is_block), available_room: date.available_room };
          }
        });
      }
    });
    return dateObj;
  };
  Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + CONST_HELPER.ONE; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
      (mm > CONST_HELPER.NINE ? '' : 'CONST_HELPER.ZERO') + mm,
      (dd > CONST_HELPER.NINE ? '' : 'CONST_HELPER.ZERO') + dd
    ].join('');
  };
  $scope.openAllDatesCalendar = function (selectedAdventureId) {
    var adventurePackage = {
      package_id: selectedAdventureId,
      userToken: $scope.user.token
    };
    getAdventureDetails(adventurePackage);
  };
  function getAdventureDetails (adventurePackage) {
    $scope.showLoader();
    userAdventureDetailService.getAdventureDetails(adventurePackage).then(function (res) {
      $scope.adventureDetails = res.data.package;
      $scope.adventureDetails.nightly_slots.nightly_slot_lodgings.forEach(function (lodging) {
        lodging.calendar = JSON.parse(lodging.calendar) || lodging.calendar;
        if (lodging.calendar && lodging.calendar.length > CONST_HELPER.ZERO) {
          lodging.calendar = lodging.calendar.filter(function (event) {
            var date = moment(event.date).format('YYYY-MM-DD');
            return moment(date).isSameOrAfter(moment().format('YYYY-MM-DD'));
          });
        }
      });
      var bookingWindow = $scope.adventureDetails.nightly_slots.booking_window;
      $scope.calendar = $scope.getCalendar($scope.adventureDetails.nightly_slots.nightly_slot_lodgings, bookingWindow);
      $timeout(function () {
        if ($('.k-animation-container') && $('.k-animation-container').length > CONST_HELPER.ZERO) {
          $('.k-animation-container').remove();
        }
        $scope.initializeKendoSingleDatePicker();
        var $datepicker = $('#datepicker').data('kendoDatePicker');
        $datepicker.open();
      }, CONST_HELPER.ZERO);
      // $timeout(function () {
      //   var $datepicker = $('#datepicker').data('kendoDatePicker');
      //   $datepicker.open();
      // }, 400);

      $scope.hideLoader();
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  }
  // $('#show-all-dates').on('hidden.bs.modal', function () {
  //   $scope.destroySingleDateRangePicker();
  // });
  $scope.addEventHandlerForCells = function () {
    $(' div.k-calendar-view > table > tbody > tr > td > a').click(function () {
      $('.k-animation-container').hide();
      $('body').removeClass('modal-open');
      var text = '';
      var tempDate = $(this)[CONST_HELPER.ZERO].dataset.value.split('/');
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      text = tempDate[CONST_HELPER.TWO] + ' ' + months[tempDate[CONST_HELPER.ONE]] + ' ' + tempDate[CONST_HELPER.ZERO];

      $('#datepicker').val(text);
      // $scope.nightly_selected_dates.start = new Date(text);
      // $scope.nightly_selected_dates.end = new Date(text);
      $timeout(function () {
        $(' div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
          $(this).children().remove();
        });
        $scope.destroySingleDateRangePicker();
      }, CONST_HELPER.ZERO);
    });
  };
  function setAvailableSeatsInSingleCalendar () {
    $('div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
      var cellDate = $(this).attr('data-value');

      if ($scope.calendar.hasOwnProperty(cellDate)) {
        var splitDate = cellDate.split('/');
        var cellDateObj = new Date(splitDate[CONST_HELPER.ZERO], splitDate[CONST_HELPER.ONE], splitDate[CONST_HELPER.TWO]);
        cellDateObj.setHours(CONST_HELPER.ZERO, CONST_HELPER.ZERO, CONST_HELPER.ZERO);
        cellDateObj.setMonth(cellDateObj.getMonth() + CONST_HELPER.ONE);
        var diffInDays = Math.floor((cellDateObj - new Date().setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24)); // get no of days
        if ($scope.calendar[cellDate].available_seat || $scope.calendar[cellDate].available_room) {
          var price = $filter('currency')($scope.calendar[cellDate].price, $scope.currencySymbol[$scope.adventureDetails.currency.code], CONST_HELPER.ZERO);
          if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
            $(this).append('<span>' + $scope.calendar[cellDate].available_room + ' Units<br>' + price + '</span>');
          } else {
            $(this).append('<span>' + $scope.calendar[cellDate].available_seat + ' Seats<br>' + price + '</span>');
          }
        } else { $(this).append('<span>Sold Out</span>'); }
        var splitDate = cellDate.split('/');
        var tempdate = new Date(splitDate[CONST_HELPER.ZERO], splitDate[CONST_HELPER.ONE], splitDate[CONST_HELPER.TWO]).yyyymmdd();
        if ($scope.calendar[cellDate].is_block || diffInDays < $scope.adventureDetails.nightly_slots.advance_notice_days || !($scope.calendar[cellDate].available_seat || $scope.calendar[cellDate].available_room)) {
          disableDates.push(tempdate);
        } else {
          if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION && $scope.calendar[cellDate].available_room < $scope.adult) {
            disableDates.push(tempdate);
          } else if ($scope.calendar[cellDate].available_seat < $scope.adult) {
            disableDates.push(tempdate);
          } else {
            $(this).parent().removeClass('k-state-disabled');
          }
        }
      }
    });
  }
  $scope.initializeKendoSingleDatePicker = function () {
    var start = new Date((+$scope.adventureDetails.nightly_slots.from_date.split('-')[CONST_HELPER.ZERO]), (+$scope.adventureDetails.nightly_slots.from_date.split('-')[CONST_HELPER.ONE] - CONST_HELPER.ONE), CONST_HELPER.ONE);
    var end = new Date((+$scope.adventureDetails.nightly_slots.to_date.split('-')[CONST_HELPER.ZERO]), (+$scope.adventureDetails.nightly_slots.to_date.split('-')[CONST_HELPER.ONE] + CONST_HELPER.ONE), CONST_HELPER.ZERO);
    function onOpen () {
      $('body').addClass('modal-open');
      $(' div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
        $(this).children().remove();
      });
      $('.k-widget.k-calendar').addClass('k-calendar-range k-calendar-single');
      $scope.addEventHandlerForCells();
      $('a.k-link.k-nav-prev').click(function (e) {
        $timeout(function () {
          $('div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
            $(this).children().remove();
          });
          setAvailableSeatsInSingleCalendar();
          $scope.addEventHandlerForCells();
        }, 800);
      });
      $('a.k-link.k-nav-next').click(function (e) {
        $timeout(function () {
          $('div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
            $(this).children().remove();
          });
          setAvailableSeatsInSingleCalendar();
          $scope.addEventHandlerForCells();
        }, 800);
      });
      setAvailableSeatsInSingleCalendar();
      $timeout(function () {
        $('.k-animation-container').addClass('k-operator-calendar');
        $('.k-header').append('<i class="icon-white-close menu-icon"></i>');
        if (!$rootScope.isAgent) { $('.k-header').append('<a class="k-edit-date">Edit Dates</a>'); }
        $('div.k-header > i.icon-white-close.menu-icon').click(function () {
          $('.k-animation-container').hide();
          $('body').removeClass('modal-open');

          $scope.destroySingleDateRangePicker();
        });
        $('div.k-header > a.k-edit-date').click(function () {
          $('.k-animation-container').hide();
          $('body').removeClass('modal-open');

          $scope.destroySingleDateRangePicker();
          $state.go('operatorCreateAdventure', { package_id: $scope.adventureDetails.id, package_case: $scope.adventureDetails.package_case, package_type: $scope.adventureDetails.package_type, 'edit_slot': 1 });
        });
      }, 200);
      $('.k-calendar-view').find('.k-today').removeClass('k-today');

      // $('.k-animation-container').addClass('k-operator-calendar');
    }

    function onClose (e) {
      // $(' div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
      //   $(this).children().remove();
      // });
      // e.preventDefault();
      $('#demo > div.package-booking-bar > div.package-side-advent > div.package-check-links > a').empty();
      $('#demo > div.package-booking-bar > div.package-side-advent > div.package-check-links > a').html('<input ng-if="$scope.adventureDetails.single_day_activity == 1" id="datepicker" />');
    }

    function onChange () {

    }

    $('#datepicker').kendoDatePicker({
      change: onChange,
      close: onClose,
      open: onOpen,
      format: 'ddd dd MMM',
      range: [start, end],
      // min: start,
      depth: 'month',
      start: 'month',
      // max: end,
      dateInput: true,
      disableDates: function (date) {
        return true;
      }
    });
    $('#datepicker').val('Check In      Check Out');
    $('#datepicker').attr('readonly', 'readonly');

    $('#datepicker').click(function () {
      $('.k-animation-container').show();
    });
  };
  $scope.destroySingleDateRangePicker = function () {
    $('#datepicker').data('kendoDatePicker').destroy();
    $('#demo > div.package-booking-bar > div.package-side-advent > div.package-check-links > a').empty();
    $('#demo > div.package-booking-bar > div.package-side-advent > div.package-check-links > a').html('<input ng-if="$scope.adventureDetails.single_day_activity == 1" id="datepicker" />');
  };
  $scope.loadMore = function () {
    $scope.showLoader(); // show loader
    params.page = params.page + CONST_HELPER.ONE;
    params.back = CONST_HELPER.ZERO;
    params.type = $scope.package.packageType;
    params.location_id = $scope.locationSourceId;
    params.operator_id = $scope.agentOperator.operator_profile ? $scope.agentOperator.operator_profile.user_id : '';
    if ($rootScope.isAgent) {
      agentService.getAgentOperatorPackages(params).then(function (response) { // fetch from the service
        if (response) {
          if (response.data.code === '401') { // unauthorised user
            $scope.logout();// unauthorised user
            $state.go('signin');
            $scope.hideLoader();
            return false;
          } else {
            for (var i = CONST_HELPER.ZERO; i < response.data.response.docs.length; i++) {
              $scope.packageList.push(response.data.response.docs[i]);// add to scope
            }
            $scope.canloadmore = couldLoadMore();
            $scope.checkExpiredSlots($scope.packageList); // check for expired slots.
            getPackageList(); // format publish start date and end date
          }
          $scope.hideLoader(); // hide loader
        }
      }, function () { $scope.showPopup('#serverErrModal'); });
    } else {
      operatorPackageListService.getOperatorPackages(params).then(function (response) { // fetch from the service
        if (response) {
          if (response.data.code === '401') { // unauthorised user
            $scope.logout();// unauthorised user
            $state.go('signin');
            $scope.hideLoader();
            return false;
          } else {
            for (var i = CONST_HELPER.ZERO; i < response.data.response.docs.length; i++) {
              $scope.packageList.push(response.data.response.docs[i]);// add to scope
            }
            $scope.canloadmore = couldLoadMore();
            $scope.checkExpiredSlots($scope.packageList); // check for expired slots.
            getPackageList(); // format publish start date and end date
          }
          $scope.hideLoader(); // hide loader
        }
      }, function () { $scope.showPopup('#serverErrModal'); });
    }
  };

  // agent operator change function
  $scope.changeOperatorData = function (operator) {
    $state.go('agent-adventures', { operator_id: operator.id });
  };

  function getPackageList () { // format publish start date and end date
    angular.forEach($scope.packageList, function (value, key) {
      var availableSeats = CONST_HELPER.ZERO;
      var maxCapacity = CONST_HELPER.ZERO;
      if (typeof value.publish_start_date !== 'undefined') {
        value.publishStartDate = value.publish_start_date !== CONST_HELPER.ZERO ? new Date(value.publish_start_date * 1000).toISOString() : value.publish_start_date;
      }
      if (typeof value.publish_end_date !== 'undefined') {
        value.publishEndDate = value.publish_end_date !== CONST_HELPER.ZERO ? new Date(value.publish_end_date * 1000).toISOString() : value.publish_end_date;
      }
      if (value.package_slots) {
        value.package_slots.map(function (obj) {
          if (!obj.expired) {
            availableSeats = availableSeats + parseInt(obj.available_seat);
            maxCapacity = maxCapacity + parseInt(obj.max_capacity);
          }
        });
        value.totalAvailableSeats = availableSeats;
        value.totalMaxCapacity = maxCapacity;
      }
      if (typeof value.operator_details[CONST_HELPER.ZERO] === 'string') {
        value.operator_details[CONST_HELPER.ZERO] = JSON.parse(value.operator_details[CONST_HELPER.ZERO]);
      }
    });
  }

  function couldLoadMore () {
    if ($scope.packageList.length < $scope.resultFound) { return true; }
    return false;
  }
  var slotParams = {};
  if ($scope.user) {
    slotParams.token = $scope.user.token;
  }

  $scope.viewAllSlots = function (pId) { // display slots of package on modal
    slotParams.package_id = pId;
    $scope.showLoader();
    operatorPackageListService.getOperatorPackagesSlots(slotParams).then(function (response) { // fetch from the service
      if (response) {
        $scope.packageSlots = response.data.package;// add to scope
        $scope.checkExpiredSlots([$scope.packageSlots]);
        packageId = response.data.package.id;
        $scope.showPopup('#slot');
        if (!$('#navigation-scroll').hasClass('modal-open')) {
          $('#navigation-scroll').addClass('modal-open');
        }
        $scope.hideLoader();
      }
    }, function () { // check for error
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };

  $scope.clonePackage = function (id) { // clone package
    var requestObject = {
      package_id: id,
      token: $scope.user.token
    };
    $scope.showLoader();
    operatorPackageListService.clonePackage(requestObject).then(function (response) {
      if (response) {
        $state.go('operatorCreateAdventure', ({ package_id: response.data.new_package_id }));
        $scope.hideLoader();
      }
    }, function () { // check for error
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };

  $scope.singlePackageActive = function (packageData, value) { // set single package activate/deactivate on click activate/deactivate button
    var pId = packageData.package_id; // set package id in variable
    $scope.package_slots = packageData.package_slots; // set existing package slot in array
    var requestObject = {
      'status': value,
      'package_id': pId,
      'solr_id': packageData.id,
      'token': $scope.user.token
    };
    $scope.showLoader();
    operatorPackageListService.changePackageStatus(requestObject).then(function (response) {
      if (response) {
        if (response.data.code === '200') {
          $scope.checkedPackage.confirmed = {};
          $scope.getPackages(1); // call get package list function
          $scope.hideLoader();
        } else if (response.data.code === '400') { // if someone has booked any slot of this adventure
          $scope.showPopup('#packageAlert'); // show package deactivation alert popup
          $scope.hideLoader();
        } else {
          $scope.showPopup('#addPublishModal'); // show package publish date popup
          $scope.hideLoader();
        }
      }
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };

  $scope.bulkPackageListSave = function (type, status) { // set bulk list of package deactivate on click deactivate button
    var requestObject = {
      'status': status,
      'package_data': $scope.bulkPackageList,
      'token': $scope.user.token
    };
    $scope.showLoader();
    operatorPackageListService.changePackageStatusBulk(requestObject).then(function (response) {
      if (response) {
        if (response.data.code === '200') {
          $scope.getPackages(1); // call get package list function
          $scope.checkedPackage.confirmed = {};
          $scope.hideLoader();
        } else if (response.data.code === '422') {
          $scope.getPackages(1); // call get package list function
          $scope.checkedPackage.confirmed = {};
          $scope.hideLoader();
          $scope.showPopup('#AlertModal');
          $rootScope.errorAlert = 'Some packges can\'t be deactivated due to an existing booking.';
        } else {
          $scope.getPackages(1); // call get package list function
          $scope.hideLoader();
        }
      }
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };

  $scope.packageListStatus = function (checked, pId, solrId) { // create package list for deactivate bulk data
    if (checked === true) { // if check box value true then
      $scope.bulkPackageList.push({ 'package_id': pId, 'solr_id': solrId }); // push in array
    } else if (checked === false) { // if check box value false then
      angular.forEach($scope.bulkPackageList, function (list, index) {
        if (list.package_id === pId) {
          $scope.bulkPackageList.splice(index, 1); // remove from array
        }
      });
    }
  };

  $scope.clearBulkList = function () { // clear all selected package on click cross button
    $('input[type="checkbox"]').prop('checked', false); // Set all checkbox false
    $scope.checkedPackage.confirmed = {}; // Set model value false
    $scope.bulkPackageList = []; // set array empty
  };

  $scope.closePublishPopup = function () { // close publish date popup
    $scope.publishStartDateErr = false;
    $scope.publishEndDateErr = false;
    $scope.endDateCheckErr = false;
    $scope.packageDates.publish_start_date = '';
    $scope.packageDates.publish_end_date = '';
    $scope.hidePopup('#addPublishModal');// close publish modal
  };

  $scope.publishStartDate = function (startDate) { /* Set publish start date */
    $scope.publishStartDateErr = false;
    $scope.endDateCheckErr = false;
    $scope.getMaxAndMinDate();
    var date1 = new Date(startDate);
    var date2 = new Date(minDate);
    var diffDays = Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) / (1000 * 60 * 60 * 24));
    if (diffDays < 30) {
      $scope.publishStartDateErr = true;
      return false;
    }
    $scope.DateCheck();
  };

  $scope.publishEndDate = function (endDate) { /* Set publish end date */
    $scope.publishEndDateErr = false;
    $scope.endDateCheckErr = false;
    $scope.getMaxAndMinDate();
    var date1 = new Date(endDate);
    var date2 = new Date(maxDate);
    var diffDays = Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) / (1000 * 60 * 60 * 24));
    if (diffDays < 10) {
      $scope.publishEndDateErr = true;
      return false;
    }
    $scope.DateCheck();
  };

  $scope.DateCheck = function () { // function for end date not greater then start end
    var StartDate = document.getElementById('publishStart').value;
    var EndDate = document.getElementById('publishEnd').value;
    var eDate = new Date(EndDate);
    var sDate = new Date(StartDate);
    if (StartDate !== '' && sDate > eDate) {
      $scope.endDateCheckErr = true;
      return false;
    }
  };

  $scope.getMaxAndMinDate = function () { /* To get Max and min date from package_slots array */
    var publishStartDate = []; var publishEndDate = [];
    angular.forEach($scope.package_slots, function (date) {
      publishEndDate.push(new Date(date.to_date));
      publishStartDate.push(new Date(date.from_date));
    });
    maxDate = $filter('date')(new Date(Math.max.apply(null, publishEndDate)), 'MM/dd/yyyy');
    minDate = $filter('date')(new Date(Math.min.apply(null, publishStartDate)), 'MM/dd/yyyy');
  };

  $scope.publishPackage = function () { // update publish date for package
    if ($scope.publishStartDateErr || $scope.publishEndDateErr || $scope.endDateCheckErr) { // check for if invalid date error msg shown
      return false;
    }
    var requestObject = {
      'package_id': package_id,
      'publish_end_date': $scope.packageDates.publish_end_date,
      'publish_start_date': $scope.packageDates.publish_start_date,
      'token': $scope.user.token
    };
    $scope.showLoader();
    operatorPackageListService.updatePackagePublishDate(requestObject).then(function (response) {
      if (response) {
        if (response.data.code === '200') {
          $scope.hidePopup('#addPublishModal');// close publish modal
          $scope.getPackages(1);
          $scope.hideLoader();
        } else {
          $scope.hideLoader();
        }
      }
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };

  $scope.editViewSlot = function (data, index) { // function for edit view slot
    data.from_date = $filter('changeDateObject')(data.from_date);
    data.to_date = $filter('changeDateObject')(data.to_date);
    data.from_date = $filter('date')(new Date(data.from_date), 'MM/dd/yyyy'); // change date format in mm/dd/yyy
    data.to_date = $filter('date')(new Date(data.to_date), 'MM/dd/yyyy'); // change date format in mm/dd/yyy
    $scope.hidePopup('#slot'); // hide view slot popup
    // $scope.addSlot  =  $scope.packageSlots.package_slots[index];
    $scope.package_case = $scope.packageSlots.package_case;
    $scope.lodging_status = $scope.packageSlots.lodging_status;
    $scope.addSlot = data;
    getTotalBedsAndRoom();
    $timeout(function () { // for custom plugin
      $scope.showPopup('#editSlotModal');
    }, 400);
  };

  // set is_private key with lodging in case of private price changes
  $scope.setIsprivateKey = function (lodging) {
    lodging.is_private = true;
  };
  // update the to_date if from date changed...
  $scope.slotDateChange = function (value) { // change date function for slot
    var myDate = new Date(value); // get start date value
    var newDate = setEndDate(myDate, $scope.packageSlots.duration - 1); // call enddate function
    $scope.addSlot.to_date = $filter('date')(new Date(newDate), 'MM/dd/yyyy'); // convert end date in mm-dd-yyyy format
    $('.datepicker').datepicker('destroy'); // destroy datepicker
  };
  function setEndDate (myDate, days) { // calculate end date for package slot
    return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  // update lodging available seats...
  $scope.updateTotalBedsSeats = function (lodge, type) {
    if (type === 'manual') {
      // eslint-disable-next-line no-self-assign
      lodge.total_beds = lodge.total_beds;
      if (lodge.total_beds > (lodge.available_units ? lodge.available_units : CONST_HELPER.ZERO) * lodge.lodging.max_guests) {
        lodge.total_beds = (lodge.available_units ? lodge.available_units : CONST_HELPER.ZERO) * lodge.lodging.max_guests;
      }
    } else {
      lodge.total_beds = (lodge.available_units ? lodge.available_units : CONST_HELPER.ZERO) * lodge.lodging.max_guests;
    }

    getTotalBedsAndRoom();
    $scope.checkAvailableSeats();
  };

  function getTotalBedsAndRoom () {
    // update the available seats...
    $scope.total_beds = CONST_HELPER.ZERO;
    $scope.total_available_rooms = CONST_HELPER.ZERO;
    $scope.addSlot.slot_lodgings.map(function (val) {
      $scope.total_beds += parseInt(val.total_beds || CONST_HELPER.ZERO);
      $scope.total_available_rooms += parseInt(val.available_units);
    });
  }

  // check if available seats is less than the total beds
  $scope.validation = {};
  $scope.checkAvailableSeats = function () {
    // if(!$scope.total_beds)
    //     return;
    $scope.total_beds = $scope.total_beds || CONST_HELPER.ZERO;
    if ($scope.addSlot.available_seat > $scope.total_beds) {
      $scope.maxAvailableSeatErr = true;
    } else {
      $scope.maxAvailableSeatErr = false;
    }
    if ($scope.total_available_rooms) { $scope.validation.available_rooms = false; }
  };

  $scope.updateSlot = function () { // Update slot
    if (!$scope.addSlot.max_capacity) { // Check for safari browser
      $scope.maxCapacityErr = true;
    } else {
      if ($scope.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) { $scope.addSlot.available_seat = $scope.addSlot.max_capacity; }
      $scope.maxCapacityErr = false;
    }
    if (!$scope.addSlot.available_seat) { // Check for safari browser
      $scope.availableSeatErr = true;
    } else {
      $scope.availableSeatErr = false;
    }

    if ($scope.addSlotForm.$invalid) { // check form is valid or not
      var invalidFields = $scope.addSlotForm.$error.required;
      for (var i = CONST_HELPER.ZERO; i < invalidFields.length; i++) { // add flag for invalid field's and show error
        $scope.validation[invalidFields[i].$name] = true; // if field is required set them required true
      }
      angular.element('input.ng-invalid').first().focus();
      return false;
    }
    // if($scope.addSlot.max_capacity < 1)  { // check for addSlot max_capacity value not CONST_HELPER.ZERO
    //     $scope.validation.max_capacity = true;
    //     return false;
    // } else {
    //     $scope.validation.max_capacity = false;
    // }
    // if($scope.addSlot.available_seat < 1){  // check for addSlot available_seat value not CONST_HELPER.ZERO
    //      $scope.validation.available_seat = true;
    //      return false;
    // } else {
    //     $scope.validation.available_seat = false;
    // }

    // var requestObject = {
    //   "token"           : $scope.user.token,
    //   "package_id"      : $scope.addSlot.package_id,
    //   "package_slot_id" : $scope.addSlot.id,
    //   "adult_price"     : $scope.addSlot.adult_price,
    //   "child_price"     : $scope.addSlot.child_price,
    //   "max_capacity"    : $scope.addSlot.max_capacity,
    //   "available_seat"  : $scope.addSlot.available_seat
    // }

    var requestObject = {
      'token': $scope.user.token,
      'package_id': $scope.addSlot.package_id,
      'package_slot_id': $scope.addSlot.id,
      'adult_price': $scope.addSlot.adult_price,
      'available_seat': returnAvailableSeats(),
      'max_capacity': returnMaxSeats(),
      'from_date': moment($scope.addSlot.from_date).format('YYYY-MM-DD'),
      'to_date': moment($scope.addSlot.to_date).format('YYYY-MM-DD'),
      'slot_lodgings': returnSlotLodging()
    };

    $scope.showLoader(); // show loader
    operatorPackageListService.updatePackageSlot(requestObject).then(function (response) {
      if (response) {
        if (response.data.code === '200') {
          $scope.addSlot = {}; // Set addslot object blank
          $scope.hidePopup('#editSlotModal'); // Hide add slot popup
          $scope.viewAllSlots(packageId);// call viewAllSlots function for show view detail popup
          $scope.getPackages(1); // call get package list function
        } else {
          $scope.hideLoader(); // hide loader
        }
      }
    }, function () {
      $scope.hideLoader(); // hide loader
      $scope.showPopup('#serverErrModal');
    });
  };

  // return the slot loding if package of case A and case B
  function returnSlotLodging () {
    var slotLodgings = '';
    if ($scope.package_case !== CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && $scope.lodging_status === CONST_HELPER.EVENT_LODGING.YES) {
      slotLodgings = angular.copy($scope.addSlot.slot_lodgings);
      slotLodgings.map(function (slot) {
        delete slot.lodging;
        delete slot.created;
        delete slot.modified;
        delete slot.is_private;
      });
    }
    return slotLodgings;
  }
  // return available seats basis of package case
  function returnAvailableSeats () {
    if ($scope.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
      $scope.addSlot.available_seat = $scope.total_available_rooms;
    } else if ($scope.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
      $scope.addSlot.available_seat = $scope.addSlot.max_capacity;
    }
    return $scope.addSlot.available_seat;
  }
  // return max seats basis of package case
  function returnMaxSeats () {
    if ($scope.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
      $scope.addSlot.max_capacity = $scope.total_available_rooms;
    } else if ($scope.package_case === CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.lodging_status === CONST_HELPER.EVENT_LODGING.YES) {
      // $scope.addSlot.max_capacity=$scope.addSlot.available_seat;
    }
    return $scope.addSlot.max_capacity;
  }

  $scope.closePopup = function () { /* Close Add Slot Popup */
    $scope.maxCapacityErr = false;
    $scope.availableSeatErr = false;
    $scope.hidePopup('#editSlotModal'); // hide edit slot modal
    $scope.addSlot = {}; // Set addslot object blank
    $('#editSlotModal').on('hidden.bs.modal', function () {
      var invalidFields = $scope.addSlotForm.$error.required;
      if (!invalidFields) { return; }
      for (var i = CONST_HELPER.ZERO; i < invalidFields.length; i++) { // add flag for invalid field's and show error
        $scope.validation[invalidFields[i].$name] = false; // if field is required set them required true
      }
    });
    $scope.viewAllSlots(packageId); // call viewAllSlots function for show view detail popup
  };

  $scope.viewBookingDetail = function (packageDetail) {
    $state.go('operator-view-booking');
  };

  $scope.initializeOperatorPackageShare = function (packageData) {
    $scope.contentOperatorPackageShare = { // initialize the content which is to be share on different sharing options
      description: packageData.description[CONST_HELPER.ZERO],
      short_url: (packageData.short_url) ? packageData.short_url : '',
      profile_pic: ($scope.user.profile_pic) ? $scope.user.profile_pic : '',
      package_name: (packageData.name) ? packageData.name : '',
      package_id: packageData.package_id,
      location_name: packageData.location_name[CONST_HELPER.ZERO],
      duration: packageData.duration[CONST_HELPER.ZERO],
      id: packageData.package_id,
      media: packageData.medias[CONST_HELPER.ZERO],
      min_price: packageData.min_price,
      currency_code: packageData.currency_code[0],
      slot: {},
      package_type: packageData.package_type[0],
      status: packageData.status
    };
  };

  // SELECT TYPE OF PACKAGE TO CREATE
  $scope.typeSelection = function (type) {
    $rootScope.packageType = type;
    $cookies.put('package_type', type);
  };
  $scope.setCookies = function (packg) {
    $rootScope.packageType = packg.package_type ? packg.package_type[CONST_HELPER.ZERO] : '';
    $rootScope.packageCase = packg.package_case ? packg.package_case[CONST_HELPER.ZERO] : '';
    $cookies.put('package_type', packg.package_type ? packg.package_type[CONST_HELPER.ZERO] : '');
    $cookies.put('package_case', packg.package_case ? packg.package_case[CONST_HELPER.ZERO] : '');
  };

  // function to initialize the package delete
  $scope.initializePackageDelete = function (packageData) {
    $scope.selectedPackage = packageData; // store selected package object...
  };

  // function to delete the package if not booking is done
  $scope.proceedDeletePackage = function () {
    $scope.showLoader();
    var obj = {
      'package_id': $scope.selectedPackage.package_id,
      'token': $scope.user.token
    };
    operatorPackageListService.deletePackage(obj).then(function (res) {
      switch (res.data.code) {
        case CONST_HELPER.API_RESPONSE.OK: // if response is 200
          $scope.modalTitle = 'SUCCESS';
          $scope.modalDesc = res.data.message;
          updatePackageList();
          $scope.showPopup('#delete-success-error');
          break;
        case CONST_HELPER.API_RESPONSE.UNPROCESSABLE: // if response is 422
          $scope.modalTitle = 'ERROR';
          $scope.modalDesc = res.data.message;
          $scope.showPopup('#delete-success-error');
          break;
      }
      $scope.hideLoader();
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };

  // function to remove the package from package list array after package has been deleted...
  function updatePackageList () {
    var index = $scope.packageList.indexOf($scope.selectedPackage);
    $scope.packageList.splice(index, 1);
  }
}]);
