import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { CookieService } from 'ngx-cookie-service';
import { WebService } from './webService';
/**
 * @ngdoc service
 * @name SearchService
 * @description
 * # searchService
 * Service in the heliApp.
 */

@Injectable()

export class SearchService {

  constructor(
    @Inject(WebService) private webService: WebService,
    @Inject(CookieService) private cookies: CookieService
    ) { }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })/* asynchronously complete request */
  }

  getActivitiesList(mainCategoryId = null) {
    return this.webService.getActivitiesList(mainCategoryId);
  }

  getAvailableAdventures(keys) { // service to get all sports,activities,packages list
    return this.sendReq(this.webService.getAvailableAdventures(keys));
  }

  searchAdventures(searchAdventures) { // fetch search results
    return this.sendReq(this.webService.searchAdventures(searchAdventures));
  }

  sortData(sortCriteria) { // fetch search results
    return this.sendReq(this.webService.sortAdventures(sortCriteria));
  }

  getAvailableSports() { // fetch search results
    return this.sendReq(this.webService.getSports());
  }

  filterAdventures(filters) {
    return this.webService.getfilteredAdventures(filters);
  }

  getLocation(query) {
    return this.sendReq(this.webService.getLocation(query));
  }

  getAdventureSuggestion(searchAdventures) {
    return this.sendReq(this.webService.getAdventureSuggestion(searchAdventures));
  }

  getfeaturedAdventures() {
    return this.sendReq(this.webService.getfeaturedAdventures());
  }

  getfeaturedActivity() {
    return this.sendReq(this.webService.getfeaturedActivity());
  }

  getHomePagelogo() {
    return this.sendReq(this.webService.getHomePagelogo());
  }
}

angular.module('heliApp').service('searchService', downgradeInjectable(SearchService));
