import { NgModule } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CommonModule } from '@angular/common';

import { AdditionalFeaturesSliderComponent } from './additional-features-slider.component';

@NgModule({
  imports: [
    CommonModule,
    SlickCarouselModule
  ],
  declarations: [
    AdditionalFeaturesSliderComponent
  ],
  providers: [
  ],
  exports: [
    AdditionalFeaturesSliderComponent
  ]
  
})
export class AdditionalFeaturesSliderModule {
}