/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorGuestFormCtrl
 * @description
 * # operatorGuestFormCtrl for opeartor manual payment.
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('operatorGuestFormCtrl', ['$scope', '$location', '$rootScope', 'operatorProfileService', 'operatorCalendarService', '$state', 'CONST_HELPER', '$timeout', function ($scope, $location, $rootScope, operatorProfileService, operatorCalendarService, $state, CONST_HELPER, $timeout) {
    $scope.operatorGuestForms = [];
    $scope.showResp = false;
    $scope.isAllSelected = false;
    $scope.packageList = [];
    $scope.operatorResponseForms = [];
    $scope.deleteFormId = null;
    $scope.sortValue = 'guest.name';
    $scope.reverse = false;
    $scope.respSource = 0;
    var packageIds = [];
    $scope.showAddForm = true;
    $scope.displaySpecificTo = $state.params.date_to ? moment($state.params.date_to).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY) : moment($scope.toDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
    $scope.displaySpecificFrom = $state.params.date_from ? moment($state.params.date_from).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY) : moment($scope.fromDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
    $scope.filtersApplyed = false;
    $scope.filter = {
      date_from: $state.params.date_from ? moment($state.params.date_from).format('YYYY-MM-DD') : '',
      date_to: $state.params.date_to ? moment($state.params.date_to).format('YYYY-MM-DD') : '',
      package_id: $state.params.package_id ? parseInt($state.params.package_id) : '',
      page: $state.params.page ? parseInt($state.params.page) : '',
      guest_form_title: $state.params.guest_form_title ? $state.params.guest_form_title : '',
      order_direction: $state.params.order_direction ? $state.params.order_direction : 'DESC',
      order_by: $state.params.order_by ? $state.params.order_by : 'arrival_date'
    };

    if ($state.params.date_from || $state.params.date_to || $state.params.package_id || $state.params.guest_form_title) {
      $scope.filtersApplyed = true;
    }

    $scope.currentOpenedTab = $location.hash() ? $location.hash() : 'guest';
    $scope.responceFilter = [{
      id: 0,
      name: "All Responses"
    },{
      id: 1,
      name: "Main Guest Form"
    },{
      id: 2,
      name: "Package Guest Form"
    }];
    $scope.setFilter = function (locationSource, $select) {
      $scope.respSource = locationSource;
      $scope.getOperatorResponse();
    };

    $scope.firstLoadData = function () {
      if ($scope.currentOpenedTab == 'guest') {
        $scope.getOperatorForms();
        $scope.showResp=false;
        $scope.showAddForm=true;
      } else if($scope.currentOpenedTab == 'response') {
        $scope.getOperatorPackages();
        $scope.getOperatorResponse($scope.filter);
        $scope.getOperatorForms();
        $scope.showAddForm=false;
        $scope.showResp=true;
      } else if ($scope.currentOpenedTab == 'waiver') {
        $scope.getListWaiverDocuments();
        $scope.showResp=false;
        $scope.showAddForm=false;
      }
    }

    $scope.clearGuestsSelectFilters = function () {
      $scope.filter = {
        date_from: '',
        date_to: '',
        package_id: '',
        page: 1,
        guest_form_title: '',
        order_direction: 'DESC',
        order_by: 'arrival_date'
      };
      $scope.displaySpecificTo = moment($scope.toDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.displaySpecificFrom = moment($scope.fromDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
    }

    $scope.getOperatorForms = function () {
      $scope.showLoader();
      /* get all the sports list along with categories */
      operatorProfileService.getOperatorForms($scope.user.token).then(function (response) { // fetch from the service
        $scope.hideLoader();
        if (response && (response.data.code == CONST_HELPER.API_RESPONSE.OK || response.data.code == CONST_HELPER.API_RESPONSE.NO_CONTENT)) {
          var active_guest_forms = response.data.data.filter(function(el) {
            return el.deleted === null;
          });
          $scope.operatorGuestForms = active_guest_forms ? active_guest_forms : [];
          if($location.hash() == 'response') {
            $scope.formNameList = [{
              id: '',
              title: "All Forms"
            }];
            var array_form_titles_unique = [];
            var guest_forms_list = [];
            response.data.data.map(function(el){
              if(array_form_titles_unique.indexOf(el.title) === -1) {
                array_form_titles_unique.push(el.title);
                guest_forms_list.push(el);
              }
            });
            $scope.formNameList = $scope.formNameList.concat(guest_forms_list);
          }
        }
      }, function () { // check for error
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    };
    $scope.deleteConfirmation = function (id) {
      $scope.showPopup('#deleteTypeForm');
      $scope.deleteFormId = id;
    };
    $scope.checkAllSelected = function () {
      $scope.isAllSelected = $scope.packageList.every(function (itm) { return itm.selected; });
    };
    function logoutCheck (code) {
      if (code === '401') {
        $scope.logout();
      }
    }
    $scope.resetFilter = function (event) {
      event.stopPropagation();
      $scope.packageSearch = '';
      $timeout(function () {
        $('#package-filter').focus();
      });
      $scope.checkAllSelected();
    };
    $scope.getOperatorPackages = function () {
      var data = {
        'status': 1,
        'token': $scope.user.token
      };
      operatorCalendarService.getOperatorPackageGuestForm(data).then(function (res) {
        if (res && res.data.data) {
          packageIds = [];
          $scope.packageList = res.data.data;
          var defaultAllAdventures = {
            id: '',
            name: "All Adventures",
            selected: false
          }
          $scope.packageList.unshift(defaultAllAdventures) ;
          $scope.packageList.map(function (pkgobj) {
            pkgobj.selected = false;
          });
        } else {
          logoutCheck(res.data.code);
        }
      });
    }

    $scope.getOperatorResponse = function (filterData, firstLoad) {
      $scope.showLoader();
      /* get all the sports list along with categories */
      operatorProfileService.getOperatorResponse($scope.filter, $scope.user.token).then(function (response) { // fetch from the service
        $scope.hideLoader();
        if (response && (response.data.code == CONST_HELPER.API_RESPONSE.OK || response.data.code == CONST_HELPER.API_RESPONSE.NO_CONTENT)) {
          $scope.operatorResponseForms = response.data.data ? response.data.data : [];
          $scope.totalFormResponses = response.data.count;
        }
      }, function () { // check for error
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    };

    $scope.openResponse = function (formId) {
      $state.go('open-response', { id: formId });
    }

    $scope.deleteOperatorForm = function () {
      if ($scope.deleteFormId) {
        // deleteOperatorTypeForm
        /* Delete a type form */
        $scope.showLoader();
        operatorProfileService.deleteOperatorTypeForm($scope.deleteFormId, $scope.user.token).then(function (response) {
          $scope.hideLoader();
          // fetch from the service
          $scope.deleteFormId = null;
          if (response && response.data.code == CONST_HELPER.API_RESPONSE.OK) {
            // $scope.operatorFormOptions = response.data.sports;// add to scope
            $scope.getOperatorForms();
          }
        }, function () { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }
    };

    $scope.editOperatorForm = function (id) {
      if (id !== undefined) { $state.go('add-guest-form', { formId: id }); }
    };

    $scope.getSortedResponse = function (form, prop, reverse) {
      prop = prop.split('.');
      var len = prop.length;
      form.responses && form.responses.sort(function (a, b) {
        var i = CONST_HELPER.ZERO;
        while (i < len) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        if (a < b) {
          return !reverse ? CONST_HELPER.MINUS_ONE : CONST_HELPER.ONE;
        } else if (a > b) {
          return !reverse ? CONST_HELPER.ONE : CONST_HELPER.MINUS_ONE;
        } else {
          return CONST_HELPER.ZERO;
        }
      });
      return form.responses;
    }

    //sortList
    $scope.sortingLog = [];

    $scope.sortableOptions = {
      update: function (e, ui) {
        var logEntry = allQuestions.map(function (i) {
          return i.value;
        }).join(', ');
        $scope.sortingLog.push(logEntry);
        $scope.allQuestions = $scope.sortingLog;
      }
    };

    $scope.$watch('allQuestions', function (allQuestions) {
      $scope.allQuestions = $scope.sortingLog;
    }, true);
    $(function () { // for custom plugin
      $('.selectpicker-packages').selectpicker();
      $('.selectpicker').selectpicker();
    }, 10);

    $scope.getListWaiverDocuments = function () {
      $scope.showLoader();
      operatorProfileService.getWaiverDocuments($scope.user.token).then(function (response) {
        if (response && response.data.code == CONST_HELPER.API_RESPONSE.OK) {
          $scope.waiverDocumentList = response.data.data;
          $scope.hideLoader();
        }
      }, function () { // check for error
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    }

    $scope.deleteWiverDocument = function () {
      $scope.showLoader();
      operatorProfileService.deleteWaiverDocuments($scope.user.token, $scope.waiverIdDelete).then(function (response) {
        if (response && response.data.code == CONST_HELPER.API_RESPONSE.OK) {
          $scope.getListWaiverDocuments();
          $scope.waiverIdDelete = '';
          $scope.hideLoader();
        }
      }, function () { // check for error
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    }

    $scope.deleteConfirmationWiver = function (id) {
      $scope.showPopup('#deleteWaiver');
      $scope.waiverIdDelete =  id;
    };

    $scope.waiverFileUpload = function() {
      if(!$scope.waiver_title || $scope.waiver_title.length < 3){
        $scope.waiverTitleEmpty = true;
        return
      }else{
        $scope.waiverTitleEmpty = false;
      }
      if(!$scope.waiver_file || $scope.waiver_file.type != 'application/pdf'){
        $scope.waiverFileEmpty = true;
        return
      }else{
        $scope.waiverFileEmpty = false;
      }
      $scope.showLoader();
      var reader = new FileReader();
      var fileOnloadend;
      reader.addEventListener("load", function () {
        fileOnloadend = reader.result
      });

      reader.readAsDataURL($scope.waiver_file);

      reader.onloadend = function () {
        var data = {
          'title': $scope.waiver_title,
          'document': fileOnloadend
        }

        if(!$scope.waiverFileEmpty && !$scope.waiverTitleEmpty){
          operatorProfileService.saveWaiverDocument(data, $scope.user.token).then(function (response) {
            if (response && response.data.code == CONST_HELPER.API_RESPONSE.OK) {
              $scope.waiver_title = '';
              $scope.waiver_file = '';
              $('#waiver_file').val('')
              $scope.getListWaiverDocuments();
              $scope.hideLoader();
            }
          }, function () { // check for error
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }
      }
    }
    $scope.resetWaiverField = function() {
      $('#waiver_file').val('')
    }

    $scope.copyLinkGuestForm = function (id) {
      var hrefInput = 'guest-form-link-' + id;
      var copyText = document.getElementById(hrefInput);
      copyText.select();
      document.execCommand('copy');
      showToast('Copied to clipboard.');
    };

    // function to show toast after link is coppied...
    var showToast = function (msg) {
      var x = document.getElementById('snackbar');
      // Add the "show" class to DIV
      $('#snackbar').html(msg);
      x.className = 'show';
      // After 3 seconds, remove the show class from DIV
      setTimeout(function () {
        x.className = x.className.replace('show', '');
      }, 2000);
    };

    $scope.showCustomFilter = function (isShow) {
      if (isShow) {
        angular.element(document.querySelector('.analytics-dropdown')).toggle();
      } else {
        angular.element(document.querySelector('.analytics-dropdown')).css('display', 'none');
      }
      $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
    };

    $timeout(function () {
      angular.element(document.querySelector('#response-datepicker div')).datepicker({
        maxViewMode: 0
      }).on('changeDate', function (e) {
        setSpecificFromToValues(e);
      });
      $scope.showCustomFilter(false); // call show custom filter
    }, 0);

    function setSpecificFromToValues (e) {
      var specificToDate = angular.element(document.querySelector('#specific-to-date'));
      var specificFromDate = angular.element(document.querySelector('#specific-from-date'));
      if ($scope.isSpecificFromSelected) {
        // $scope.isSpecificFromSelected is true means From specific textbox value is been selected from the emebeded calendar
        $scope.toDate = moment(e.date).format('YYYY-MM-DD');
        // Specific from Date is lesser than Specific To Date
        if (moment($scope.fromDate, CONST_HELPER.analytics_filter.DATE_FORMAT_PAYOUT).isBefore(moment($scope.toDate))) {
          specificToDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
        } else {
          // Selected Specific To Date is lesser than From.So, Selected date will become Specific From date and already Selected From date will be To date
          $scope.toDate = $scope.fromDate;
          specificToDate.val(specificFromDate.val());
          specificFromDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
          $scope.fromDate = moment(e.date).format('YYYY-MM-DD');
        }
        $scope.displaySpecificTo = moment($scope.toDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
        $scope.filter.date_to = moment($scope.toDate).format('YYYY-MM-DD');
        $scope.displaySpecificFrom = moment($scope.fromDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
        $scope.filter.date_from = moment($scope.fromDate).format('YYYY-MM-DD');
        $scope.isSpecificFromSelected = false; // now again if calendar will be clicked then Specific From date textbox will be selected
        $scope.showCustomFilter(false);
        $scope.$apply();
      } else {
        specificFromDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
        $scope.fromDate = moment(e.date).format('YYYY-MM-DD');
        specificToDate.val('');
        $scope.isSpecificFromSelected = true;
      }
    }

    $scope.applyResponseFilter = function (page) {
      $state.go('guest-form', {
        'page' : page ? page : 1,
        'guest_form_title' : $scope.filter.guest_form_title ? $scope.filter.guest_form_title : '',
        'package_id' : $scope.filter.package_id ? $scope.filter.package_id : '',
        'date_from' : $scope.filter.date_from ? $scope.filter.date_from : '',
        'date_to' : $scope.filter.date_to ? $scope.filter.date_to : '',
        'order_direction': $scope.order_direction ? $scope.order_direction : 'DESC',
        'sort': $scope.order_by ? $scope.order_by : 'arrival_date',
        '#': 'response',
      });
      $scope.filtersApplyed = true;
      $scope.filter.page = page ? page : 1;
      $scope.getOperatorResponse($scope.filter);
    }

    $scope.clearResponsesFilter = function () {
      $state.go('guest-form', {
        'page' : 1,
        'guest_form_title' : '',
        'package_id' : '',
        'date_from' : '',
        'date_to' : '',
        'order_direction': 'DESC',
        'sort': 'arrival_date',
        '#': 'response',
      });
      $scope.clearGuestsSelectFilters();
      $scope.filtersApplyed = false;
      $scope.getOperatorResponse($scope.filter);
    }

    $scope.changeSortTableResponse = function (newSortValue) {
      if (newSortValue == $scope.filter.order_by) {
        $scope.filter.order_direction = $scope.filter.order_direction == 'ASC' ? 'DESC' : 'ASC';
      } else {
        $scope.filter.order_direction = 'ASC';
        $scope.filter.order_by = newSortValue;
      }
      $scope.getOperatorResponse();
    }

    $scope.resendGuestForm = function (form) {
      if (form && form.guest_email != '') {
        $scope.showLoader();
        var formData = {
          'guest_id': form.guest_id,
          'guest_name': form.guest_name,
          'guest_email': form.guest_email,
          'guest_forms': [{
            'title': form.guest_form_name,
            'form_url': form.guest_form_url
          }],
          'token': $scope.user.token
        };
        operatorProfileService.sendFormsGuest(formData).then(function (res) {
          $scope.hideLoader();
          if (res.data.code === CONST_HELPER.API_RESPONSE.OK || res.data.code === CONST_HELPER.API_RESPONSE.NO_CONTENT) {
             $scope.showPopup('#emailSent');
          }
        }).catch(function (err) {
          $scope.hideLoader();
        });
      } else {
        $scope.showPopup('#emptyEmailError');
      }
    }
  }]);
