import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-destinations-list',
  templateUrl: '/ng-app/src/app/components/header/navbar/destinations-list/destinations-list.component.html',
  styleUrls: ['../../../../../../../ng-app/src/app/components/header/navbar/destinations-list/destinations-list.component.css']
})
export class DestinationsListComponent implements OnInit {

  @Input() destinations: any = [];
  @Input() goToSelectedCategory;

  constructor() { }

  ngOnInit(): void {
  }

}
