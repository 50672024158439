import { Component, OnInit } from "@angular/core";
import { UIRouter } from "@uirouter/core";

@Component({
  selector: "app-credits-promo",
  templateUrl:
    "/ng-app/src/app/components/credits-promo/credits-promo.component.html",
  styleUrls: [
    "../../../../../../ng-app/src/app/components/credits-promo/credits-promo.component.css",
  ],
})
export class CreditsPromoComponent implements OnInit {
  constructor(public router: UIRouter) {}

  ngOnInit(): void {}
}
