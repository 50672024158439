import { Component, Input, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { LoadAdventuresService } from '../../services/load-adventures/load-adventures.service';

@Component({
  selector: 'app-sort-filter',
  templateUrl: '/ng-app/src/app/components/sort-filter/sort-filter.component.html',
  styleUrls: ['../../../../../ng-app/src/app/components/sort-filter/sort-filter.component.css']
})
export class SortFilterComponent implements OnInit, AfterViewInit {

  @Input() page: string;
  modalElem;
  listWrapperElem;

  constructor(
    public renderer: Renderer2,
    public loadAdventures: LoadAdventuresService
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.modalElem = document.getElementById('filter-modal');
    this.listWrapperElem = document.getElementById('filter-head');
  }

  sortAdventure(sortby) {
    this.loadAdventures.filter.sort = sortby;
    this.loadAdventures.filter.page = 1;
    this.loadAdventures.applyFilters();
  };

  handleAccordionExpand() {
    if (!this.listWrapperElem.classList.contains('toggled')) {
      this.renderer.setStyle(this.listWrapperElem, 'min-height', 'unset');
    } else {
      this.renderer.setStyle(this.listWrapperElem, 'min-height', this.modalElem.offsetHeight + 40 + 'px');
    } 
  }

}