import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadAdventuresService } from '../../services/load-adventures/load-adventures.service';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import * as moment from 'moment';

@Component({
  selector: 'app-activity-date-picker',
  templateUrl: '/ng-app/src/app/components/activity-date-picker/activity-date-picker.component.html',
  styleUrls: ['../../../../../ng-app/src/app/components/activity-date-picker/activity-date-picker.component.css']
})
export class ActivityDatePickerComponent implements OnInit {

  selectedDate: any = {startDate: moment(), endDate: moment()};
  currentDate: any;
  locationVal: any;

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  constructor(
    public loadAdventures: LoadAdventuresService,
    public routerGlobals: UIRouterGlobals
  ) { }

  ngOnInit(): void {
    this.currentDate = moment().subtract(0, 'days');
    this.setExistedFilters();
  }

  changeDate(selectedDate) {
    selectedDate.startDate && (this.loadAdventures.filter.mindate = selectedDate.startDate.format('MM/DD/YYYY'));
    selectedDate.endDate && (this.loadAdventures.filter.maxdate = selectedDate.endDate.format('MM/DD/YYYY'));
  }

  searchAdventures() {
    !document.getElementById('select-speceific-location')['value'] && this.handleAddressChange(null);
    this.loadAdventures.applyFilters();
    if (this.routerGlobals.current.name == 'filter') {
      this.changeUrlFilters();
    }
  }

  changeUrlFilters() {
    let currentUrl = window.location.href;
    ['mindate','maxdate','location','lang','lat'].forEach(filterCategory => {
      if (currentUrl.includes(filterCategory) && this.loadAdventures.filter[filterCategory]) {
        let indexSubString = currentUrl.indexOf(filterCategory);
        let lengtSubString = currentUrl.slice(indexSubString, currentUrl.length-1).indexOf('&');
        let searchSubString = currentUrl.substr(indexSubString, lengtSubString > 0 ? lengtSubString : currentUrl.length);
        currentUrl = currentUrl.replace(searchSubString , `${filterCategory}=${this.loadAdventures.filter[filterCategory]}`)
      } else if (this.loadAdventures.filter[filterCategory]) {
        currentUrl += `&${filterCategory}=${this.loadAdventures.filter[filterCategory]}`
      }
    });
    window.history.pushState('', '', currentUrl);
  }

  setExistedFilters() {
    if (this.routerGlobals.current.name == 'filter') {
      setTimeout(() => {
        this.loadAdventures.filter.mindate && this.loadAdventures.filter.maxdate && ((<HTMLInputElement>document.getElementById('config-demo')).value = `${this.loadAdventures.filter.mindate} - ${this.loadAdventures.filter.maxdate}`);
        this.loadAdventures.filter.location && ((<HTMLInputElement>document.getElementById('select-speceific-location')).value = this.loadAdventures.filter.location);
      }, 0);
    }
  }

  public handleAddressChange(address) {
    this.loadAdventures.filter.location = address ? address.formatted_address : '';
    this.loadAdventures.filter.lang = address ? address.geometry.location.lng() : '';
    this.loadAdventures.filter.lat = address ? address.geometry.location.lat() : '';
  }
}