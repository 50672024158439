import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browse-adventures',
  templateUrl: '/ng-app/src/app/components/header/navbar/browse-adventures/browse-adventures.component.html',
  styleUrls: ['../../../../../../../ng-app/src/app/components/header/navbar/browse-adventures/browse-adventures.component.css']
})
export class BrowseAdventuresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
