import { Component, Input, OnInit, Inject } from '@angular/core';
import { LoadAdventuresService } from '../../../services/load-adventures/load-adventures.service';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';

@Component({
  selector: 'app-destination-card',
  templateUrl: '/ng-app/src/app/components/activity-categories/destination-card/destination-card.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/activity-categories/destination-card/destination-card.component.css']
})
export class DestinationCardComponent implements OnInit {

  adventure: any;
  @Input() card: any;

  constructor(
    @Inject("$scope") private scopeJS: any,
    @Inject(UIRouter) public router: UIRouter,
    public routerGlobals: UIRouterGlobals,
    public loadAdventures: LoadAdventuresService
  ) {
  }

  ngOnInit(): void {}

  viewAllSlots(event, adventure) {
    this.adventure = adventure;
    this.scopeJS.showPopup('#slot');
    event.stopPropagation();
  };


  getSeoFriendlyUrl(str) {
    return str?.replace(/\W+(?!$)/g, '-').toLowerCase();
  }

  setLocationLen(locationName) {
    let location = Array.isArray(locationName) ? locationName[0].split(',') : locationName.split(',');
    return location[location.length - 1];
  };

  redirectDestinationLocation(card) {
    this.router.stateService.go('destination-location', {
      destinationCategory: this.routerGlobals.params.destinationCategory,
      destinationLocation: this.loadAdventures.removeRouteDash(card.name)
    });
  }

}
