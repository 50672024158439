import { Component, OnInit, AfterViewInit, Input, Renderer2 } from '@angular/core';
import { LoadAdventuresService } from '../../services/load-adventures/load-adventures.service';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';

@Component({
  selector: 'app-sidebar-filter',
  templateUrl: '/ng-app/src/app/components/sidebar-filter/sidebar-filter.component.html',
  styleUrls: ['../../../../../ng-app/src/app/components/sidebar-filter/sidebar-filter.component.css']
})
export class SidebarFilterComponent implements OnInit, AfterViewInit {

  selectedAbility: any = [];
  selectedActivities: any = [];
  modalElem;
  listWrapperElem;
  
  @Input('page') page: string = null;

  constructor(
    public loadAdventures: LoadAdventuresService,
    public router: UIRouter,
    public routerGlobals: UIRouterGlobals,
    public renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.loadAdventures.setDefaultFilter(this.page);
  }

  ngAfterViewInit(): void {
    this.modalElem = document.getElementById('filter-modal');
    this.listWrapperElem = document.getElementById('filter-head');
  }

  handleAccordionExpand() {
    let time = 0;
    this.renderer.setStyle(this.listWrapperElem, 'min-height', this.modalElem.offsetHeight + 40 + 'px');
    const elementHeightInterval = setInterval(() => {
      time = time + 10;
      this.renderer.setStyle(this.listWrapperElem, 'min-height', this.modalElem.offsetHeight + 40 + 'px');
      time === 360 && clearInterval(elementHeightInterval);
    }, 10);  
  }

  changeCount(key, count) {
    if (this.loadAdventures.filter[key] === 0 && count < 0) { return; }
    this.loadAdventures.filter[key] = Number(this.loadAdventures.filter[key]) + Number(count);
  }

  removeFilterTag(tag, tagIndex, ifClearAll = null) {
    let index;
    switch (tag.type) {
      case 'activity':
        index = this.getIndex(this.loadAdventures.filter.activities, tag.id);
        this.loadAdventures.filter.activities[index].isChecked = false;
        this.removeElementFromArray(this.loadAdventures.filter.activity_id, tag);
        if (this.routerGlobals.params.activity_id) {
          const idsArray = this.routerGlobals.params.activity_id.split(',');
          this.removeElementFromArray(idsArray, tag);
          this.routerGlobals.params.activity_id = idsArray.join(',');
        }
        break;
      case 'ability':
        index = this.getIndex(this.loadAdventures.filter.abilities, tag.id);
        this.loadAdventures.filter.abilities[index].isChecked = false;
        this.removeElementFromArray(this.loadAdventures.filter.ability_id, tag);
        if (this.routerGlobals.params.ability_id) {
          const idsArray = this.routerGlobals.params.ability_id.split(',');
          this.removeElementFromArray(idsArray, tag);
          this.routerGlobals.params.ability_id = idsArray.join(',');
        }
        break;
      case 'days':
        this.loadAdventures.filter.days = 0;
        tag.name = '';
        break;
      case 'people':
        this.loadAdventures.filter.people = 0;
        tag.name = '';
        break;
      case 'price':
        this.loadAdventures.filter.min_price = '';
        this.loadAdventures.filter.max_price = '';
        tag.name = '';
        break;
      default :
        break;
    }
    if (!ifClearAll) {
      this.loadAdventures.filtersApplied.splice(tagIndex, 1);
      this.applyChangesAfterDeletingFilter();
    }
  };

  clearAllFilters() {
    this.loadAdventures.appliedFilters.forEach((filter, index) => this.removeFilterTag(filter, index, true));
    this.loadAdventures.filtersApplied = [];
    this.applyChangesAfterDeletingFilter();
  };

  applyChangesAfterDeletingFilter() {
    this.loadAdventures.appliedFilters = this.loadAdventures.cloneObject(this.loadAdventures.filtersApplied);
    this.loadAdventures.applyFilters();
    this.loadAdventures.scrollToListTop();
  }

  checkAbilityChange(ability) {
    if (ability.isChecked) {
      this.loadAdventures.filtersApplied.push({name: ability.name, type: 'ability', id: ability.id});
      this.loadAdventures.filter.ability_id.push(ability.id);
    } else {
      this.removeElementFromArray(this.loadAdventures.filtersApplied, ability);
      this.removeElementFromArray(this.loadAdventures.filter.ability_id, ability);
    }
  };

  checkActivityChange(activity) {
    if (activity.isChecked) {
      this.loadAdventures.filtersApplied.push({name: activity.category_name, type: 'activity', id: activity.id});
      this.loadAdventures.filter.activity_id.push(activity.id);
    } else {
      this.removeElementFromArray(this.loadAdventures.filtersApplied, activity);
      this.removeElementFromArray(this.loadAdventures.filter.activity_id, activity);
    }
  };

  removeElementFromArray(array, element) {
    const index = this.getIndex(array, element.id);
    index !== -1 && array.splice(index, 1);
  }

  getIndex(arr, id) {
    return arr.findIndex(elem => elem === id || elem.id === id);
  }
}