import { Component, Input, OnInit, Inject } from '@angular/core';
import { LoadAdventuresService } from '../../../services/load-adventures/load-adventures.service';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';

@Component({
  selector: 'app-advertisement-card',
  templateUrl: '/ng-app/src/app/components/activity-categories/advertisement-card/advertisement-card.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/activity-categories/advertisement-card/advertisement-card.component.css']
})
export class AdvertisementCardComponent implements OnInit {

  @Input() advertisement: any;

  currentPage: number = 1;

  constructor() {
  }

  ngOnInit(): void {
  }

}
