/* eslint-disable no-useless-escape */
/* eslint-disable handle-callback-err */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controllerImpersonateCtrl
 * @description
 * # impersonateCtrl
 * Controller of the heliApp
 */
angular.module('heliApp').controller(
    'impersonateCtrl', ['$scope', '$rootScope', 'userLoginService', '$window', '$state', '$location', '$cookies',
    function ($scope, $rootScope, userLoginService, $window, $state, $location, $cookies) {
        var impersonateToken = $location.search()?.token;
        if (!impersonateToken) {
            $state.go('home');
        }
        var data = { 'email': 'none', 'password': 'none', 'impersonate_token': impersonateToken };// set data field
        $scope.showLoader();// show loader
        userLoginService.loginUser(data).then(function (response) { // post to the server the form data
            if (response.data) {
                if (response.data.code !== '200') { // some error exits
                    $scope.hideLoader();// hide loader
                    $state.go('home');
                } else { // if  registeration is successful
                    $rootScope.manualLogout();
                    showToast(`On impersonation Mode for user: ${response?.data?.data?.email}`)
                    $scope.setUserDetails(response.data.data, 'second');// set user details in the rootScope;
                    $scope.user = response.data.data;// set user data into local scope;
                    $scope.hideLoader();// hide loader
                    $rootScope.userLocalCountry = response.data.data.country_id;
                    $cookies.put('user_country_id', JSON.stringify(response.data.data.country_id));
                    $state.go('home');  
                }
            }
        }, function (error) { // check for error
            $scope.hideLoader();// hide loader
            $scope.showPopup('#serverErrModal');
        });

        var showToast = function (msg) {
            var x = document.getElementById('snackbar');
            // Add the "show" class to DIV
            $('#snackbar').html(msg);
            x.className = 'show';
            // After 3 seconds, remove the show class from DIV
            setTimeout(function () {
              x.className = x.className.replace('show', '');
            }, 4000);
          };
    }]);
