import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-custom-trip',
  templateUrl: '/ng-app/src/app/components/activity-categories/create-custom-trip/create-custom-trip.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/activity-categories/create-custom-trip/create-custom-trip.component.css']
})
export class CreateCustomTripComponent implements OnInit {

  @Input() activityData: any = {};
  @Input() selectedActivity: any = {};

  constructor() { }

  ngOnInit(): void { }

}
