/* eslint-disable camelcase */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:EmailVerificationCtrl
 * @description
 * # EmailVerificationCtrl
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('operatorAnalyticsCtrl', ['$scope', 'operatorAnalyticsService', 'operatorAgentService', '$filter', '$state', '$rootScope', 'CONST_HELPER', '$timeout', 'operatorCalendarService', 'agentService', '$location', '$stateParams', 'operatorViewBookingService', function ($scope, operatorAnalyticsService, operatorAgentService, $filter, $state, $rootScope, CONST_HELPER, $timeout, operatorCalendarService, agentService, $location, $stateParams, operatorViewBookingService) {
    $scope.isCustomCalendarFilter = false; // whether to show Complete custom filter dropdown
    $scope.isFromToCalendar = false; // whether to show specific From and To
    $scope.displaySpecificFrom = ''; // text dispkay onto Specific From textbox
    $scope.displaySpecificTo = ''; // text display onto specific To Textbox
    $scope.isSpecificFromSelected = false; // to check the first select on calendar should be for specific From , then second click on calendar will be for Specific To
    $scope.chartOption = 'sales';// set default option for chart and graph
    $scope.barOption = 'sales';// set default option for bar graph
    $scope.hasBarData = true;// show bar canvas
    $scope.activateAgentFlag = 0;
    // Booking Source filter
    $scope.bookingSource = CONST_HELPER.ZERO;
    $scope.bookingFilter = ['All Booking Sources', 'Heli', 'My Site', '', 'Manual'];

    $scope.getFullCsvList = [];

    var op_id = $stateParams.op_id || 0;

    $scope.$on('setSelectedOperator', function () {
      var selectedOperator = $scope.agentAllOperators.filter(function (operator) { return (operator.operator_profile && operator.operator_profile.user_id == op_id); });
      if (selectedOperator.length) {
        $scope.agentOperator = selectedOperator[0];
      } else {
        $scope.agentOperator = { name: 'All Operators', id: '' };
      }
    });

    angular.element(document.querySelector('#sandbox-container div')).datepicker({
      endDate: 'now',
      maxViewMode: 0
    })
      .on('changeDate', function (e) {
        setSpecificFromToValues(e);
      });

    function refrestSelectPicker () {
      $scope.chartOption = 'sales'; // for mobile view
      $scope.barOption = 'sales';// set default option for bar graph
      // to select the sales tab as active on both the graph
      angular.element('.to-set-sales').find('li').removeClass('active');
      angular.element('.to-set-sales').find('li:first').addClass('active');
      $timeout(function () {
        angular.element('.line-chart-dropdown').selectpicker('refresh');// initiate dropdowns
        angular.element('.bar-chart-dropdown').selectpicker('refresh');// initiate dropdowns
      }, 0);
    }

    refrestSelectPicker();

    function setSpecificFromToValues (e) {
      var specific_to_date = angular.element(document.querySelector('#specific-to-date'));
      var specific_from_date = angular.element(document.querySelector('#specific-from-date'));
      if ($scope.isSpecificFromSelected) {
        // $scope.isSpecificFromSelected is true means From specific textbox value is been selected from the emebeded calendar
        $scope.toDate = moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT);
        $scope.fromDate = $scope.fromDateTemp; // update the tempororay value into real fromDate variable
        // Specific from Date is lesser than Specific To Date
        if (moment($scope.fromDate, CONST_HELPER.analytics_filter.DATE_FORMAT).isBefore(moment($scope.toDate))) {
          specific_to_date.val(moment(e.date).format('MMM D YYYY'));
        } else {
          // Selected Specific To Date is lesser than From.So, Selected date will become Specific From date and already Selected From date will be To date
          $scope.toDate = $scope.fromDate;
          specific_to_date.val(specific_from_date.val());
          specific_from_date.val(moment(e.date).format('MMM D YYYY'));
          $scope.fromDate = moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT);
        }
        $scope.isSpecificFromSelected = false; // now again if calendar will be clicked then Specific From date textbox will be selected
        if ($state.params.agent_id) {
          $scope.getAnalyticsDataByAgentId('custom');
          $scope.getViewBookingDetail();
        } else {
          $scope.getAnalyticsData('custom');// get data for specified range
        }
        $scope.getAllAgentAnalytics('custom');
        $scope.setisCustomFileShowHide(false); // hide the popup when To date is selected

        $scope.displayFromdate = moment($scope.fromDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
        $scope.displayTodate = moment($scope.toDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
        refrestSelectPicker();
      } else {
        specific_from_date.val(moment(e.date).format('MMM D YYYY'));
        // fromDateTemp is used so in case if user only clicks for From date then actual variable shouldn't get updated
        $scope.fromDateTemp = moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT);
        specific_to_date.val('');
        $scope.isSpecificFromSelected = true;
      }
    }
    // location filters array
    $scope.locationSource = '';
    $scope.locationSourceId = CONST_HELPER.ZERO;
    $scope.locationFilter = [];
    $scope.tempId = $scope.getTempId();
    $scope.getLocationNames = function () {
      var data = {
        token: $scope.user.token
      };
      operatorCalendarService.getLocationNames(data).then(function (response) {
        if (response.data) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK || response.data.code === CONST_HELPER.API_RESPONSE.NO_CONTENT) {
            $scope.locationFilter = response.data.data;
            $scope.locationFilter.push({ id: $scope.tempId });
            $scope.locationFilter.unshift({ id: CONST_HELPER.ZERO, name: 'All locations' });
            $scope.locationSource = $scope.locationFilter[0];
          }
        }
      }, function () {

      });
    };
    $scope.gotoAgentInventory = function (id, agent_id) {
      $state.go('operator-agent-inventory', { id: id, agent_id: agent_id });
    };
    $scope.addLocation = function () {
      $rootScope.redirectToAddLocation = true;
      $state.go('operator-edit-profile');
    };
    $scope.getLocationNames();
    $scope.setLocationSource = function (location, select) {
      $scope.locationSource = location;
      $scope.locationSourceId = location.id;
      if ($scope.locationSourceId === $scope.tempId) {
        $scope.locationSource = $scope.locationFilter[0];
        select.selected = $scope.locationFilter[0]; // update select value.
      } else {
        $scope.getAnalyticsData();
      }
    };
    $scope.downLoadPDF = function () {
      $scope.showLoader();
      // eslint-disable-next-line new-cap
      var doc = new jsPDF();
      var pdfFormatList = [{ // for Analytics Text
        param: '',
        type: 'text',
        right: 10,
        top: 10,
        text: 'Analytics'
      }, { // for Horizontal line
        param: '',
        type: 'line',
        x1: 5,
        x2: 205,
        y1: 14,
        y2: 14
      }, { // for first filtered data
        param: 'row-first-pdf',
        type: 'image',
        right: 5,
        top: 20,
        width: 200,
        height: 15
      }, { // for second filtered data
        param: 'row-second-pdf',
        type: 'image',
        right: 5,
        top: 50, // difference of 10 between eache lement
        width: 200,
        height: 15
      }, { // for first chart
        param: 'row-third-pdf',
        type: 'image',
        right: 5,
        top: 80,
        width: 200,
        height: 130
      }, { // for second chart
        param: 'row-fourth-pdf',
        type: 'image',
        right: 5,
        top: 10,
        width: 200,
        height: 150
      }];
      generatePDF_file(0, doc, pdfFormatList, function (doc) {
        doc.save('Report.pdf');
      });
    };

    function generatePDF_file (index, doc, pdfFormatList, cb) {
      if (index === pdfFormatList.length) {
        $scope.hideLoader();
        cb(doc);
        return;
      }
      if (pdfFormatList[index].param === 'row-fourth-pdf') { // last chart will be on second page of PDF
        doc.addPage();
      }
      generatePDF(doc, pdfFormatList[index], function (doc) {
        generatePDF_file(index + 1, doc, pdfFormatList, cb);
      });
    }

    function generatePDF (doc, pdfFormat, cb) {
      switch (pdfFormat.type) {
        case 'line':
          doc.line(pdfFormat.x1, pdfFormat.y1, pdfFormat.x2, pdfFormat.y2); // x1 , y1, x2, y2  draw horizontal line
          cb(doc);
          break;
        case 'text':
          doc.text(pdfFormat.right, pdfFormat.top, pdfFormat.text);
          cb(doc);
          break;
        case 'image':
          getCanvasImage(pdfFormat.param, function (imageData) {
            doc.addImage(imageData, 'PNG', pdfFormat.right, pdfFormat.top, pdfFormat.width, pdfFormat.height); // data,format,right side , top side , width , height
            cb(doc);
          });
          break;
      }
    }

    function getCanvasImage (param, cb) {
      html2canvas(document.getElementsByClassName(param), {
        onrendered: function (canvas) {
          var data = canvas.toDataURL();
          cb(data);
        }
      });
    }

    $scope.clickEvntSpecificDates = function () { // prevent the close of dropdown on click of From and To textbox
      $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
    };

    $scope.setisCustomFileShowHide = function (isShow) {
      if (isShow) {
        angular.element(document.querySelector('.analytics-dropdown')).toggle();
      } else {
        angular.element(document.querySelector('.analytics-dropdown')).css('display', 'none');
      }
      $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
    };
    $scope.setisCustomFileShowHide(false);

    $scope.setFromToDate = function (param, event) {
      switch (param) {
        case '7days' :
          $scope.displayFromdate = moment().subtract(6, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
          $scope.fromDate = moment().subtract(6, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT);
          $scope.setisCustomFileShowHide(false);
          $scope.isFromToCalendar = false;
          break;
        case '30days' :
          $scope.displayFromdate = moment().subtract(29, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);

          $scope.fromDate = moment().subtract(29, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT);
          $scope.setisCustomFileShowHide(false);
          $scope.isFromToCalendar = false;
          break;
        case '12months' :
          $scope.displayFromdate = moment().subtract(11, 'months').format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
          $scope.fromDate = moment().subtract(11, 'months').format(CONST_HELPER.analytics_filter.DATE_FORMAT);
          $scope.setisCustomFileShowHide(false);
          $scope.isFromToCalendar = false;
          break;
      }
      angular.element(document.querySelector('.fa')).removeClass('fa'); // remove the class from all the elements
      angular.element(document.querySelector('.fa-check')).removeClass('fa-check'); // remove the class from all the elements
      event.target.getElementsByTagName('i')[0].classList.add('fa'); // add the class on the triggered element
      event.target.getElementsByTagName('i')[0].classList.add('fa-check'); // add the class on the triggered element
      $scope.displayTodate = moment().format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.toDate = moment().format(CONST_HELPER.analytics_filter.DATE_FORMAT); // this variable
      if ($state.params.agent_id) {
        $scope.getAnalyticsDataByAgentId('custom');
        $scope.getViewBookingDetail();
      } else {
        $scope.getAnalyticsData('custom');
      }
      $scope.getAllAgentAnalytics('custom');
      refrestSelectPicker();
    };

    $scope.setSpecifciDates = function (event) {
      $scope.isFromToCalendar = true;
      $scope.displaySpecificFrom = moment().format('MMM D YYYY');
      $scope.displaySpecificTo = moment().format('MMM D YYYY');
      $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
      angular.element(document.querySelector('.fa')).removeClass('fa'); // remove the class from all the elements
      angular.element(document.querySelector('.fa-check')).removeClass('fa-check'); // remove the class from all the elements
      event.target.getElementsByTagName('i')[0].classList.add('fa'); // add the class on the triggered element
      event.target.getElementsByTagName('i')[0].classList.add('fa-check'); // add the class on the triggered element
    };

    $scope.lineChart = [];// set modal for line chart
    $scope.barChart = []; // set modal for bar chart
    $scope.pieChart = []; // set modal for bar chart
    initializeLineChart(); // intialize chart options
    initializeBarChart();// intialize adventure graph option
    initializePieChart(); // initialize google analytics chart

    function initializeLineChart () { // intialize chart options
      $scope.lineChart.lineOptions = {
        elements: {
          line: {
            tension: 0
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              labelOffset: 0,
              padding: 20,
              autoSkip: false,
              min: 0
            },
            gridLines: {
              // drawTicks : false
            }
          }],
          yAxes: [{
            ticks: {
              labelOffset: 20,
              padding: 10,
              autoSkip: false,
              min: 0
            },
            gridLines: {
              drawTicks: false
            }
            // ,
            // scaleLabel: {
            //     display: true,
            //     labelString: '1k = 1000'
            // }
          }]
        }
      };
      $scope.lineChart.datasetOverride = [{ backgroundColor: [] }];// set color options for graph and points on line
      $scope.lineChart.datasetOverride[0].backgroundColor = 'rgba(67, 133, 245,0.2)';
      $scope.lineChart.datasetOverride[0].borderColor = 'rgba(67, 133, 245, 0.7)';
      $scope.lineChart.datasetOverride[0].pointBorderColor = 'rgba(67, 133, 245, 0.7)';
      $scope.lineChart.datasetOverride[0].pointBackgroundColor = 'rgb(255,255,255)';
      $scope.lineChart.datasetOverride[0].pointRadius = 4;
    }

    function initializeBarChart () { // initialize bar graph for adventures listing
      var colors = ['rgba(63,186,250,0.5)', 'rgba(255,99,132,0.5)', 'rgba(255,159,64,0.5)', 'rgba(184, 147, 255, 0.4)', 'rgba(109, 235, 235, 0.4)', 'rgba(255,64,163,0.5)', 'rgba(64,223,86,0.5)', 'rgba(240,219,44,0.5)', 'rgba(199,111,241,0.5)', 'rgba(49,105,217,0.5)'];
      var borderColors = ['rgba(63,186,250,0.7)', 'rgba(255,99,132,0.7)', 'rgba(255,159,64,0.7)', 'rgba(184,147,255,0.7)', 'rgba(109,235,235,0.7)', 'rgba(255,64,163,0.7)', 'rgba(64,223,86,0.7)', 'rgba(240,219,44,0.7)', 'rgba(199,111,241,0.7)', 'rgba(49,105,217,0.7)'];
      // Create a place to hold the background colours.
      $scope.barChart.datasetOverride = [{ backgroundColor: [] }];
      // Assign the colours to the pre-populated array.
      $scope.barChart.datasetOverride[0].backgroundColor = colors;
      $scope.barChart.datasetOverride[0].borderColor = borderColors;
      $scope.barChart.options = {
        scales: {
          xAxes: [{
            ticks: {
              min: 0,
              autoSkip: false
              // callback : function(label, index, labels) {
              //     return parseFloat(label).toFixed(2);
              // }
            }
          }],
          yAxes: [{
            ticks: {
              padding: 10
            },
            barPercentage: 0.6,
            gridLines: {
              drawTicks: false
            }
          }]
        }
      };
    }

    function initializePieChart () { // initialize pie chart options
      $scope.pieChart.colors = ['rgba(63,186,250,0.7)', 'rgba(255,99,132,0.7)', 'rgba(255,159,64,0.7)', 'rgba(184, 147, 255, 0.7)', 'rgba(109, 235, 235, 0.7)', 'rgba(255,64,163,0.7)', 'rgba(64,223,86,0.5)', 'rgba(240,219,44,0.5)', 'rgba(199,111,241,0.5)', 'rgba(49,105,217,0.5)'];
      $scope.pieChart.datasetOverride = [{ backgroundColor: [] }];
      // Assign the colours to the pre-populated array.
      $scope.pieChart.datasetOverride[0].backgroundColor = $scope.pieChart.colors;
      $scope.pieChart.datasetOverride[0].borderColor = 'rgb(255,255,255)';
    }
    $scope.setBookingSource = function (bookingType) {
      $scope.bookingSource = bookingType;
      $scope.getAnalyticsData();
    };
    $scope.getAnalyticsData = function (from) { // fetch overall stats data
      $scope.homeRedirection();// check for authorised access
      if ((!$scope.user) || ($scope.user && !$scope.user.token)) { // check for user logged in status
        $state.go('signin');
        return false;
      }
      $scope.showLoader();
      if (from === 'firstLoad') {
        $scope.toDate = '';
        $scope.fromDate = '';
        getLastMonthDate();
      }
      if ($rootScope.isAgent) {
        var analyticReq = { location_id: $scope.locationSourceId, fromDate: $scope.fromDate, toDate: $scope.toDate, token: $scope.user.token, op_id: op_id };
        analyticReq.bookingSource = $scope.bookingSource;// Setting Booking Source analyticReq
        operatorAnalyticsService.getAgentAnalyticsResult(analyticReq).then(function (response) { // fetch result from the service
          if (response && response.data.code === '200') { // success response
            $scope.grid = response.data.grid;// grid element
            $scope.categoriesData = response.data.sales_graph;
            setLineChart($scope.categoriesData, 'sales');
            $scope.adventuresData = response.data.popular_adventures;
            setBarChart($scope.adventuresData, 'sales');
            $scope.hideLoader();
          } else {
            errScenario(response.data.code);
          }
        }, function () {
          errScenario();
        });
      } else {
        var data = { location_id: $scope.locationSourceId, fromDate: $scope.fromDate, toDate: $scope.toDate, token: $scope.user.token };
        data.bookingSource = $scope.bookingSource;// Setting Booking Source Data
        operatorAnalyticsService.getAnalyticsResult(data).then(function (response) { // fetch result from the service
          if (response && response.data.code === '200') { // success response
            $scope.grid = response.data.grid;// grid element
            $scope.categoriesData = response.data.sales_graph;
            setLineChart($scope.categoriesData, 'sales');
            $scope.adventuresData = response.data.popular_adventures;
            setBarChart($scope.adventuresData, 'sales');
            $scope.hideLoader();
          } else {
            errScenario(response.data.code);
          }
        }, function () {
          errScenario();
        });
        operatorAnalyticsService.getTrafficAnalytics(data).then(function (response) { // fetch result from the service
          if (response && response.data.code === '200') { // success response
            setPieChart(response.data.data);
            $scope.trafficPieChartData = response.data.data;
            if (response.data.data && response.data.data.referral_value.length > 0) {
              // eslint-disable-next-line no-eval
              $scope.totalTraficCount = eval(response.data.data.referral_value.join('+'));
            }
            setCSVAnalyticsList();
          } else {
            errScenario(response.data.code);
          }
        }, function () {
          errScenario();
        });
      }
    };

    // filter agent analytics via operator
    $scope.changeOperatorBooking = function (operator) {
      op_id = operator.operator_profile ? operator.operator_profile.user_id : 0;
      $state.go('agent-analytics', { op_id: op_id });
    };

    $scope.getAnalyticsDataByAgentId = function (from) { // fetch overall stats data
      $scope.homeRedirection();// check for authorised access
      if ((!$scope.user) || ($scope.user && !$scope.user.token)) { // check for user logged in status
        $state.go('signin');
        return false;
      }
      $scope.showLoader();
      if (from === 'firstLoad') {
        $scope.toDate = '';
        $scope.fromDate = '';
        getLastMonthDate();
      }
      var data = { fromDate: $scope.fromDate, toDate: $scope.toDate, token: $scope.user.token, agent_id: $state.params.agent_id };
      operatorAgentService.getAnalyticsByAgentId(data).then(function (response) { // fetch result from the service
        if (response && response.data.code === '200') { // success response
          $scope.grid = response.data.data;// grid element
          $scope.hideLoader();
        } else {
          errScenario(response.data.code);
        }
      }, function () {
        errScenario();
      });
    };

    function setCSVAnalyticsList () { // create csv file for operator analyticslist
      $scope.getFullCsvList = [];
      $scope.getFullCsvList.push(
        {
          analytics_report: 'Analytics Report',
          fromDate: $scope.fromDate,
          to: 'To',
          toDate: $scope.toDate
        },
        {},
        {
          stats: 'Stats'
        },
        {
          total_sales: 'Total Sales',
          total_orders: 'Total Orders',
          total_adventure: 'Total Adventures',
          total_views: 'Total Views',
          total_likes: 'Total Likes',
          total_shares: 'Total Shares',
          add_to_radar: 'Add To Radar'
        },
        {
          total_sales: Number($scope.grid.total_sales).toFixed(2),
          total_orders: $scope.grid.total_orders,
          total_adventure: $scope.grid.total_adventures,
          total_views: $scope.grid.total_views,
          total_likes: $scope.grid.total_likes,
          total_shares: $scope.grid.total_shares,
          add_to_radar: $scope.grid.add_to_radar
        },
        {},
        {
          line_graph: 'Line Graph:'
        },
        {
          date: 'Date',
          sale: 'Sales',
          order: 'Orders',
          views: 'Views',
          likes: 'Likes',
          share: 'Shares',
          add_to_radar: 'Add To Radar'
        }
      );

      for (var i = 0; i < $scope.categoriesData.date.length; i++) {
        $scope.getFullCsvList.push(
          {
            date: $scope.categoriesData.date[i],
            sale: Number($scope.categoriesData.sales[i]).toFixed(2),
            order: $scope.categoriesData.orders[i],
            views: $scope.categoriesData.views[i],
            likes: $scope.categoriesData.likes[i],
            share: $scope.categoriesData.shares[i],
            add_to_radar: $scope.categoriesData.add_to_radar[i]
          });
      }

      $scope.getFullCsvList.push({}, {
        most_popular_adventures: 'Most Popular Adventures:'
      });

      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.sale_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.sales, 'sale', 'Sales:'));
      $scope.getFullCsvList.push({}, genericFunForAnalyticsCsv($scope.adventuresData.order_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.orders, 'order', 'Orders:'));
      $scope.getFullCsvList.push({}, genericFunForAnalyticsCsv($scope.adventuresData.view_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.views, 'view', 'Views:'));
      $scope.getFullCsvList.push({}, genericFunForAnalyticsCsv($scope.adventuresData.like_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.likes, 'like', 'Likes:'));
      $scope.getFullCsvList.push({}, genericFunForAnalyticsCsv($scope.adventuresData.share_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.shares, 'share', 'Shares:'));
      $scope.getFullCsvList.push({}, genericFunForAnalyticsCsv($scope.adventuresData.radar_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.radars, 'radars', 'Add To Radar:'));

      $scope.getFullCsvList.push({}, {
        referrers: 'Referrers:'
      });
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.trafficPieChartData.referral_type, 'referral_type', 'Referral Type:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.trafficPieChartData.referral_value, 'referral_value', 'Value:'));
    }

    function genericFunForAnalyticsCsv (list, base_key, head_Text) { // generic function for create csv file in case of most populer adventures
      var obj = {};
      obj[base_key + 0] = head_Text;
      for (var i = 0; i < list.length; i++) {
        obj[base_key + (i + 1)] = list[i];
      }
      return obj;
    }

    function errScenario (error_code) { // err case
      if (error_code && error_code === '401') {
        $scope.logout();
      } else {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      }
    }

    function setLineChart (data, option) { // set line chart for option
      $scope.lineChart.labels = data.date;
      $scope.setLineChartData(option, data);// set data for y axis according to option selected
    }

    $scope.setLineChartData = function (option, data) { // populate graph according to selected option
      $scope.chartOption = option;
      if (option !== 'sales') {
        $scope.lineChart.lineOptions.scales.yAxes[0].ticks.callback = function (label, index, labels) {
          if (label % 1 === 0) { return label; }
        };
      }
      switch (option) { // change the  graph view according to selected option
        case 'sales' :
          $scope.lineChart.lineOptions.scales.yAxes[0].ticks.callback = function (label, index, labels) {
            return '$' + $filter('number')(label, 2);
          };
          lineAxesData(data.sales, 'Sales');
          break;
        case 'orders' :
          lineAxesData(data.orders, 'Orders');
          break;
        case 'views' :
          lineAxesData(data.views, 'Views');
          break;
        case 'likes' :
          lineAxesData(data.likes, 'Likes');
          break;
        case 'shares' :
          lineAxesData(data.shares, 'Shares');
          break;
        case 'radar' :
          lineAxesData(data.add_to_radar, 'Add to Radar');
          break;
        case 'manual_booking' :
          lineAxesData(data.manual_booking, 'Manual Booking');
          break;
        case 'shared_booking' :
          lineAxesData(data.shared_booking, 'Shared Booking');
          break;
      }
    };

    $scope.setBarChartData = function (option, data) {
      $scope.barOption = option;
      $scope.barChart.options.scales.xAxes[0].ticks.callback = function (label, index, labels) {
        if ($scope.barOption === 'sales') {
          return '$' + $filter('number')(label, 2);
        } else {
          if (label % 1 === 0) {
            return label;
          }
        }
      };
      $scope.barChart.options.scales.xAxes[0].ticks.scaleLabel = {
        display: true
      };
      switch (option) { // change the  graph view according to selected option
        case 'sales' :
          barPercentageSet(data.sale_package);
          barAxesData(data.sale_package, data.sales);
          break;
        case 'orders' :
          barPercentageSet(data.order_package);
          barAxesData(data.order_package, data.orders);
          break;
        case 'views' :
          barPercentageSet(data.view_package);
          barAxesData(data.view_package, data.views);
          break;
        case 'likes' :
          barPercentageSet(data.like_package);
          barAxesData(data.like_package, data.likes);
          break;
        case 'shares' :
          barPercentageSet(data.share_package);
          barAxesData(data.share_package, data.shares);
          break;
        case 'radar' :
          barPercentageSet(data.radar_package);
          barAxesData(data.radar_package, data.radars);
          break;
        case 'manual_booking' :
          barPercentageSet(data.manual_booking_package);
          barAxesData(data.manual_booking_package, data.manual_booking);
          break;
        case 'shared_booking' :
          barPercentageSet(data.shared_booking_package);
          barAxesData(data.shared_booking_package, data.shared_booking);
          break;
      }
    };

    function setBarChart (data, option) { // set bar chart for option
      $scope.barChart.labels = data.date;
      $scope.setBarChartData(option, data);// set data for y axis according to option selected
    }

    function getLastMonthDate () { // get last month date
      $scope.displayFromdate = moment().subtract(29, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.fromDate = moment().subtract(29, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT);
      $scope.displayTodate = moment().format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.toDate = moment().format(CONST_HELPER.analytics_filter.DATE_FORMAT); // this variable
    }

    function barPercentageSet (arr) {
      if (arr.length > 3) {
        $scope.hasBarData = true;
        $scope.barChart.options.scales.yAxes[0].barPercentage = 0.6;
      } else {
        $scope.hasBarData = true;
        $scope.barChart.options.scales.yAxes[0].barPercentage = 0.2;
        if (arr.length === 0) {
          $scope.hasBarData = false;
        }
      }
    }

    function lineAxesData (data, series) { // set data on line axes
      $scope.lineChart.data = [data];
      $scope.lineChart.series = [series];
    }

    function barAxesData (packageData, value) { // set data on line axes
      var i = 0;
      $scope.barChart.labels = [];
      while (i < packageData.length) { // set length limit for ellipses
        $scope.barChart.labels[i] = (packageData[i].length > 25) ? packageData[i].substr(0, 25) + '...' : packageData[i];
        i++;
      }

      $scope.barChart.data = [value];
      $scope.barChart.options.tooltips = {// set custom tootlips values
        enabled: true,
        mode: 'single',
        callbacks: {
          label: function (tooltipItem, data) {
            var label = packageData[tooltipItem.index];
            var datasetLabel = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return label + ': ' + datasetLabel;
          },
          title: function (tooltipItem, data) {
            return packageData[tooltipItem.index];
          }
        }
      };
    }

    function setPieChart (data) {
      if (data && data.referral_type && data.referral_type.length > 0) {
        data.referral_type[0] = 'Direct';
        $scope.pieChart.labels = data.referral_type;
        $scope.pieChart.data = [data.referral_value];
      }
    }

    $scope.agentRequests = [];
    $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
    $scope.modalData = {};
    $scope.activeAgentTab = '1';
    $scope.agents = [];
    $scope.hostName = $location.host();
    $scope.agentTabPage = 1;
    $scope.agentSortType = '';
    $scope.agentSortKey = '';
    $scope.agentRequestTab = 1;
    if ($scope.hostName != 'localhost') {
      $scope.hostName = $scope.hostName.split('.');
      $scope.hostName = '.' + $scope.hostName[$scope.hostName.length - 3] + '.' + $scope.hostName[$scope.hostName.length - 2] + '.' + $scope.hostName[$scope.hostName.length - 1];
    }
    getOperatorAgentRequests();
    getOperatorAgents($scope.activeAgentTab);
    $scope.changeTab = function (tabNumber) {
      $scope.activeAgentTab = tabNumber;
      $scope.agentSortType = '';
      $scope.agentSortKey = '';
      $scope.agentTabPage = 1;
      $scope.agents = [];
      getOperatorAgents($scope.activeAgentTab);
    };
    $scope.toggleAgentRequestTab = function (tabNumber) {
      $scope.agentRequestTab = tabNumber;
      $scope.agentSortType = '';
      $scope.agentSortKey = '';
      $scope.agentTabPage = 1;
      if (tabNumber == 1) {
        $scope.agents = [];
        getOperatorAgents($scope.activeAgentTab);
      } else {
        $scope.agentRequests = [];
        getOperatorAgentRequests();
      }
    };
    $scope.acceptAgentRequestFunc = function (agentRequest) {
      $scope.modalData = agentRequest;
      $scope.showPopup('#acceptAgentRequest');
    };
    $scope.openCheckAgentRequestPopup = function (agentRequest) {
      $scope.modalData = agentRequest;
      $scope.showPopup('#checkAgentRequest');
    };
    $scope.closecheckAgentRequestPopup = function () {
      $scope.hidePopup('#checkAgentRequest');
    };
    $scope.rejectAgentRequestFunc = function (agentRequest) {
      $scope.modalData = agentRequest;
      $scope.showPopup('#reject-request-modal');
    };
    $scope.closeAgentRequestRejectPopup = function () {
      $scope.hidePopup('#reject-request-modal');
    };
    $scope.closeAgentRequestPopup = function () {
      $scope.hidePopup('#acceptAgentRequest');
    };
    $scope.inviteAgent = function () {
      $scope.showPopup('#inviteAgent');
    };
    $scope.closeInviteAgentPopup = function () {
      $scope.hidePopup('#inviteAgent');
    };
    $scope.closeEditCommissionPopup = function () {
      $scope.hidePopup('#editCommission');
    };
    $scope.copyLinkFunc = function (url) {
      if (url) {
        var copyText = url + $scope.hostName;
        document.execCommand('copy');
        alert('Copied the text: ' + copyText);
      }
    };
    $scope.agentRequestFormInvalid = false;
    $scope.accpetRequest = function () {
      if (typeof $scope.modalData.commission_value == 'undefined' || $scope.modalData.commission_value == '') {
        $scope.agentRequestFormInvalid = true;
      }
      var requestObject = {
        'id': $scope.modalData.id,
        'status_id': CONST_HELPER.AGENT_REQUEST_STATUS.ACCEPT,
        'commission_value': $scope.modalData.commission_value
      };
      operatorAgentService.acceptRejectAgents(requestObject, $scope.user.token).then(function (response) {
        $scope.agentRequestFormInvalid = false;
        if (response.data.code == CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
          $scope.modalData = {};
          $scope.hidePopup('#checkAgentRequest');
          $scope.showPopup('#serverErrModal');
          $scope.agentRequests = [];
          getOperatorAgentRequests();
        } else if (response.data.code == CONST_HELPER.API_RESPONSE.OK) {
          $scope.modalData = {};
          $scope.hidePopup('#checkAgentRequest');
          $scope.agentRequests = [];
          getOperatorAgentRequests();
        } else {
          $scope.agentRequests = [];
          getOperatorAgentRequests();
          $scope.hidePopup('#checkAgentRequest');
          $scope.showPopup('#serverErrModal');
        }
      });
    };
    $scope.rejectAgentRequest = function () {
      var requestObject = {
        'id': $scope.modalData.id,
        'status_id': CONST_HELPER.AGENT_REQUEST_STATUS.REJECT,
        'commission_value': $scope.modalData.commission_value
      };
      operatorAgentService.acceptRejectAgents(requestObject, $scope.user.token).then(function (response) {
        if (response.data.code == CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
          $scope.modalData = {};
          $scope.hidePopup('#checkAgentRequest');
          $scope.showPopup('#serverErrModal');
          $scope.agentRequests = [];
          getOperatorAgentRequests();
        } else if (response.data.code == CONST_HELPER.API_RESPONSE.OK) {
          $scope.modalData = {};
          $scope.hidePopup('#checkAgentRequest');
          $scope.agentRequests = [];
          getOperatorAgentRequests();
        } else {
          $scope.agentRequests = [];
          getOperatorAgentRequests();
          $scope.hidePopup('#checkAgentRequest');
          $scope.showPopup('#serverErrModal');
        }
      });
    };
    $scope.inviteAgentFunc = function () {
      if (typeof $scope.modalData.commission == 'undefined' || $scope.modalData.commission == '' || typeof $scope.modalData.email == 'undefined' || $scope.modalData.email == '') {
        $scope.agentRequestFormInvalid = true;
        return;
      }
      $scope.showLoader();
      var requestObject = {
        email: $scope.modalData.email,
        commission_value: $scope.modalData.commission,
        operator_ids: [$scope.user.id],
        status_id: CONST_HELPER.AGENT_REQUEST_STATUS.INVITE
      };
      $scope.hidePopup('#inviteAgent');
      $scope.agentRequestFormInvalid = false;
      agentService.agentSignUpRequest(requestObject, $scope.user.token).then(function (response) {
        if (response.data.code == '200') {
          $scope.agents = [];
          getOperatorAgents($scope.activeAgentTab);
          $scope.hideLoader();
        } else if (response.data.code == '409') {
          $scope.hideLoader();
          $rootScope.errorAlert = response.data.message.email.email;
          $scope.showPopup('#AlertModal');
        } else {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        }
      });
      $scope.modalData = {};
      getOperatorAgentRequests();
    };
    $scope.showPopupEditCommission = function (agent) {
      $scope.modalData = agent;
      $scope.showPopup('#editCommission');
    };
    $scope.editCommissionFunc = function () {
      if (typeof $scope.modalData.commission_value == 'undefined' || $scope.modalData.commission_value == '' || typeof $scope.modalData.email == 'undefined' || $scope.modalData.email == '') {
        $scope.agentRequestFormInvalid = true;
        return;
      }
      $scope.showLoader();
      var requestObject = {
        id: $scope.modalData.id,
        commission_value: $scope.modalData.commission_value
      };
      $scope.hidePopup('#editCommission');
      $scope.agentRequestFormInvalid = false;
      operatorAgentService.editAgentCommission(requestObject, $scope.user.token).then(function (response) {
        if (response.data.code == '200') {
          $scope.hideLoader();
        } else if (response.data.code == '409') {
          $scope.hideLoader();
          $rootScope.errorAlert = response.data.message.email.email;
          $scope.showPopup('#AlertModal');
        } else {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        }
        $scope.agents = [];
        getOperatorAgents($scope.activeAgentTab);
      });
      $scope.modalData = {};
    };

    $scope.hideDeactivateAgentModal = function () {
      $scope.hidePopup('#deactivateAgent');
    };

    $scope.showActivateAgentModal = function (val) {
      $scope.activateAgentFlag = val;
      $scope.showPopup('#deactivateAgent');
    };

    $scope.deActivateAgent = function (id) {
      var requestObject = {
        'id': id,
        'status_id': CONST_HELPER.AGENT_REQUEST_STATUS.DEACTIVATE
      };
      $scope.showLoader();
      operatorAgentService.acceptRejectAgents(requestObject, $scope.user.token).then(function (response) {
        $scope.hideLoader();
        if (response.data.code == CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
          $scope.agents = [];
          $scope.showPopup('#serverErrModal');
          getOperatorAgents($scope.activeAgentTab);
        } else {
          $scope.showActivateAgentModal(0);
          $scope.modalData = {};
          $scope.agents = [];          
          getOperatorAgents($scope.activeAgentTab);
        }
      });
    };
    $scope.activateAgent = function (id) {
      var requestObject = {
        'id': id,
        'status_id': CONST_HELPER.AGENT_REQUEST_STATUS.ACCEPT
      };
      $scope.showLoader();
      operatorAgentService.acceptRejectAgents(requestObject, $scope.user.token).then(function (response) {
        $scope.hideLoader();
        if (response.data.code == CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
          $scope.showPopup('#serverErrModal');
          $scope.agents = [];
          getOperatorAgents($scope.activeAgentTab);
        } else {
          $scope.showActivateAgentModal(1);
          $scope.modalData = {};
          $scope.agents = [];
          getOperatorAgents($scope.activeAgentTab);
        }
      });
    };
    $scope.resendAgentInvite = function (agent) {
      $scope.showLoader();
      var requestObject = {
        email: agent.email,
        operator_ids: [$scope.user.id],
        status_id: CONST_HELPER.AGENT_REQUEST_STATUS.INVITE,
        agent_id: agent.id
      };
      agentService.resendInvite(requestObject, $scope.user.token).then(function (response) {
        if (response.data.code == '200') {
          $scope.hideLoader();
          $scope.showActivateAgentModal(2);
        } else if (response.data.code == '409') {
          $scope.hideLoader();
          $rootScope.errorAlert = response.data.message.email.email;
          $scope.showPopup('#AlertModal');
        } else {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        }
      });
    };
    $scope.applySort = function (sortKey, sortOrder) {
      $scope.agentSortKey = sortKey;
      $scope.agentSortType = sortOrder;
      $scope.agentTabPage = 1;
      $scope.agents = [];
      getOperatorAgents($scope.activeAgentTab);
    };
    $scope.applySortAgentRequests = function (sortKey, sortOrder) {
      $scope.agentSortKey = sortKey;
      $scope.agentSortType = sortOrder;
      getOperatorAgentRequests('sort');
    };
    function getOperatorAgentRequests (isSorting) {
      // get requests from all agent api
      $scope.agentTabPage = isSorting ? 1 : $scope.agentTabPage;
      var requestObject = {
        'page': $scope.agentTabPage,
        'limit': 10,
        'sort': $scope.agentSortKey,
        'sort_type': $scope.agentSortType == 'false' ? 'ASC' : 'DESC'
      };
      operatorAgentService.getOperatorAgents(4, $scope.user.token, requestObject).then(function (response) {
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.agentRequests = isSorting ? response.data.data : $scope.agentRequests.concat(response.data.data);
          $scope.agentRequestCount = response.data.count;
          $scope.isRequestMoreButton = ((response.data.count - $scope.agentRequests.length) > 0);
        } else if (response.data.code === CONST_HELPER.API_RESPONSE.NOT_FOUND) {
          $scope.agentRequests = [];
          $scope.agentRequestCount = 0;
          // $rootScope.errorAlert = response.data.message;
          // $scope.showPopup('#AlertModal');
        } else if (response.data.code == CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
          $state.go('signin');
        } else {
          $scope.showPopup('#serverErrModal');
        }
      });
    }
    $scope.loadMoreAgentRequest = function () {
      $scope.agentTabPage = Number($scope.agentTabPage) + 1;
      getOperatorAgentRequests();
    };
    $scope.loadMore = function () {
      $scope.agentTabPage = Number($scope.agentTabPage) + 1;
      getOperatorAgents($scope.activeAgentTab);
    };

    /// ////////////////////////////// agent booking list
    $scope.bookList = {};
    $scope.bookList.currentPage = 1;
    $scope.pageChange = function (page, type) { // set pagination
      $scope.bookList.currentPage = page;
      $scope.getViewBookingDetail();
    };
    // $scope.applySort = function (sortKey, sortOrder) {
    //   $scope.sortValue = sortKey;
    //   $scope.reverse = sortOrder;
    //   $scope.getViewBookingDetail();
    // };
    $scope.sortValue = '';
    $scope.reverse = '';
    $scope.getViewBookingDetail = function (type) { // get all view booking detail
      $scope.homeRedirection();// check for authorised access
      if ((!$scope.user) || ($scope.user && !$scope.user.token)) { // check for user logged in status
        $state.go('signin');
        return false;
      }
      $scope.showLoader();

      if (type === 'firstLoad') {
        $scope.noBookingList = false; // Setting false for the booking source load
        $scope.bookList.currentPage = 1;
      }
      var requestObject = {
        token: $scope.user.token,
        'page': $scope.bookList.currentPage,
        from_date: $scope.fromDate,
        to_date: $scope.toDate,
        sort: $scope.sortValue,
        sort_type: $scope.reverse == 'false' ? 'ASC' : 'DESC',
        package_ids: []
      };
      requestObject['agent_id'] = $state.params.agent_id;
      operatorViewBookingService.getViewBookingDetail(requestObject).then(function (response) { // fetch from the service
        if (response.data.code === '200') {
          $scope.allPackageBookingList = response.data.booking_list;
          $scope.totalViewItems = response.data.count; // set pagination count
          $scope.noBookingList = false;
          $scope.hideLoader();
        } else if (response.data.code === '404') {
          $scope.noBookingList = true;
          $scope.allPackageBookingList = '';
          $scope.totalViewItems = ''; // set pagination count
          $scope.hideLoader();
        } else if (response.data.code === '401') {
          $scope.logout();
        }
      }, function () { // check for error
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    };
    // ////////////////////////////////////
    $scope.getAllAgentAnalytics = function (from) { // fetch overall stats data
      $scope.homeRedirection();// check for authorised access
      if ((!$scope.user) || ($scope.user && !$scope.user.token)) { // check for user logged in status
        $state.go('signin');
        return false;
      }
      $scope.showLoader();
      if (from === 'firstLoad') {
        $scope.toDate = '';
        $scope.fromDate = '';
        getLastMonthDate();
      }
      var data = { fromDate: $scope.fromDate, toDate: $scope.toDate, token: $scope.user.token };
      operatorAgentService.getAllAgentAnalytics(data).then(function (response) { // fetch result from the service
        if (response && response.data.code === '200') { // success response
          $scope.allAgentAnalyticsData = response.data.data;// grid element
          $scope.hideLoader();
        } else {
          errScenario(response.data.code);
        }
      }, function () {
        errScenario();
      });
    };
    $scope.getAllAgentAnalytics('firstLoad');

    // /////////////////////////////////////

    function getOperatorAgents (status) {
      var requestObject = {
        'page': $scope.agentTabPage,
        'limit': 10,
        'sort': $scope.agentSortKey,
        'sort_type': $scope.agentSortType == false ? 'ASC' : 'DESC'
      };
      operatorAgentService.getOperatorAgents(status, $scope.user.token, requestObject).then(function (response) {
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.agents = $scope.agents.concat(response.data.data);
          $scope.isLoadMoreButton = ((response.data.count - $scope.agents.length) > 0);
        } else if (response.data.code === CONST_HELPER.API_RESPONSE.NOT_FOUND) {
          $scope.agents = [];
          $scope.isLoadMoreButton = false;
          // $rootScope.errorAlert = response.data.message;
          // $scope.showPopup('#AlertModal');
        } else if (response.data.code == CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
          $state.go('signin');
        } else {
          $scope.showPopup('#serverErrModal');
        }
      });
    }
  }]);
