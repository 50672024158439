import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-advantage-heli-banner',
  templateUrl: '/ng-app/src/app/components/activity-categories/advantage-heli-banner/advantage-heli-banner.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/activity-categories/advantage-heli-banner/advantage-heli-banner.component.css']
})
export class AdvantageHeliBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
